import { Tabs } from "antd";
import { StyledTabs } from "./styles";
import { StyledContent } from "../styles";
import PageHeading from "../../../components/PageHeading";
import CurrentUsers from "./CurrentUsers";
import DeactivatedUsers from "./DeactivatedUsers";

const UsersList = () => {
	const { TabPane } = Tabs;

	return (
		<>
			<PageHeading>회원 목록</PageHeading>
			<StyledContent>
				<StyledTabs defaultActiveKey="1" type="card">
					<TabPane tab="회원목록" key="1">
						<CurrentUsers />
					</TabPane>
					<TabPane tab="탈퇴회원목록" key="2">
						<DeactivatedUsers />
					</TabPane>
				</StyledTabs>
			</StyledContent>
		</>
	);
};

export default UsersList;
