import {FormEvent, Key, ReactNode, useCallback, useEffect, useState} from "react";
import InnerTdButton from "../../../components/InnerTdButton";
import TableHeading from "../../../components/TableHeading";
import TableDefault from "../../../components/Tables/TableDefault";
import DefaultPopup from "../../../components/Popup/DefaultPopup";
import TableSort from "../../../components/Tables/TableSort";
import Input from "../../../components/Inputs/Input";
import ConfirmPopup from "../../../components/Popup/ConfirmPopup";
import { Section, InputWrapper, Button } from "./styles";

import TabInterface from "../TabInterface"
import { useApi } from "../../../contexts/ApiContext";
import { HospitalInventory, HospitalInventoryItem, RecommendedHospitalItem } from "../../../model";
import { TablePaginationConfig } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";

const popupText = {
	save:
		<>
			변경한 내용을 저장할까요? <br />
			확인을 누르면 앱에 바로 반영됩니다.
		</>
}

const RecHospital = (props: TabInterface) => {
	const api = useApi();

	const [popupContents, setPopupContents] = useState<string | ReactNode>();
	const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => { });
	const [popupHandleCancel, setPopupHandleCancel] = useState<VoidFunction | undefined>(() => () => { });
	const [isWithCancel, setIsWithCancel] = useState(false);

	const [hospital, setHospital] = useState<RecommendedHospitalItem>();
	const [hospitals, setHospitals] = useState<HospitalInventoryItem[]>([]);

	const [activeHospitals, setActiveHospitals] = useState<HospitalInventory>();
	const [hospitalPage, setHospitalPage] = useState<number>(0);
	const [pageSize, ] = useState<number>(25);

	const [keyword, setKeyword] = useState<string>('');
	const [searchKeyword, setSearchKeyword] = useState<string>();

	const [selectedHospital, setSelectedHospital] = useState<Key[]>([]);

	//추천 병원
	const columns = [
		{ title: "병원명", dataIndex: "hospitalName", key: "hospitalName" },
		{
			title: "",
			dataIndex: "edit",
			key: "edit",
			render: () => <InnerTdButton onClickDelete={onClickDelete} />,
		},
	];

	const popupColumns = [
		{
			title: "등록일",
			dataIndex: "registerDate",
			key: "registerDate",
			sorter: (a: HospitalInventoryItem, b: HospitalInventoryItem) => {
				const aDate = Date.parse(a.registerDate);
				const bDate = Date.parse(b.registerDate);
				return bDate - aDate;
			},
		},
		{
			title: "수정일",
			dataIndex: "updateDate",
			key: "updateDate",
			sorter: (a: HospitalInventoryItem, b: HospitalInventoryItem) => {
				const aDate = Date.parse(a.registerDate);
				const bDate = Date.parse(b.registerDate);
				return bDate - aDate;
			},
		},
		{ title: "진료과", dataIndex: "departmentName", key: "departmentName" },
		{ title: "병원명", dataIndex: "hospitalName", key: "hospitalName" },
	];

	const setPopupData = (contents: JSX.Element, isWithCancel: boolean, handleOk?: VoidFunction, handleCancel?: VoidFunction) => {
		setIsWithCancel(isWithCancel);
		setPopupContents(contents);
		setPopupHandleOk(handleOk);
		setPopupHandleCancel(handleCancel);
	};

	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [isSubmitCountPopupVisible, setIsSubmitCountPopupVisible] = useState(false);

	const showPopup = () => setIsPopupVisible(true);

	// 팝업에서 필수 입력 값을 넣지 않고 '저장'버튼을 누르는 경우 보여주는 팝업
	const [isConfirmVisible, setIsConfirmVisible] = useState(false);

	const [isDeleteVisible, setIsDeleteVisible] = useState(false);
	const onClickDelete = () => setIsDeleteVisible(true);

	//삭제 요청 시
	const handleOk = useCallback(async () => {
		const result = (await api.home.deleteRecommendedHospital(props.department?.id)).data;

		if (result.removed) {
			setHospital(undefined);
		}

		setIsDeleteVisible(false);
	}, [api.home, props.department?.id]);
	const handleCancel = () => setIsDeleteVisible(false);

	//병원 페이지네이션
	const pagination: TablePaginationConfig = { 
		position: ["bottomCenter"], 
		defaultPageSize: 10,
		showSizeChanger: false,
		total: activeHospitals?.totalElements, 
		current: hospitalPage + 1,
		onChange: (page: number, pageSize: number) => {
			setHospitalPage(page - 1);
		},
	};

	//병원 검색
	const onSearch = useCallback((e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setSearchKeyword(keyword)
	}, [keyword]);

	//병원 체크 박스
	const rowSelection: TableRowSelection<any> = {
		onChange: (keys) => {
			setSelectedHospital(keys);
		},
		getCheckboxProps: (record) => {
			if (selectedHospital.length > 0 && record.key !== selectedHospital[0]) {
				return {
					disabled: true,
				}
			} else {
				return {
					disabled: false,
				}
			}
		},
		
		hideSelectAll: true,
	};

	//병원 팝업 취소
	const onClickCancel = () => {
		setIsPopupVisible(false);
	};

	//병원 팝업 저장
	const onClickSave = useCallback(async () => {
		const hospitalKey = selectedHospital[0].toString();
		const data = (await api.home.addRecommendedHospital({hospitalId: hospitalKey}, props.department?.id)).data;

		setIsPopupVisible(false);
		setSelectedHospital([]);

		if (data.assigned) {
			setHospital({ key: data.hospitalId, hospitalId: data.hospitalId ?? '', hospitalName: data.hospitalName ?? '' });
		}
	}, [api.home, props.department?.id, selectedHospital]);

	const onConfirmSave = useCallback(async () => {
		if (selectedHospital.length <= 0) {
			setIsConfirmVisible(true);
		} else {
			setPopupData(popupText.save, true, () => () => onClickSave());
			setIsSubmitCountPopupVisible(true);
		}
	}, [onClickSave]);

	//추천 병원
	const getHospital = useCallback(async () => {
		const data = (await api.home.getRecommendedHospital(props.department?.id)).data;

		if (data.item) {
			setHospital({ key: data.item.hospitalId, ...data.item });
		}
	}, [api.home, props.department?.id]);

	// 병원 목록
	const getActiveHospitals = useCallback(async () => {
		const data = (await api.hospital.getSearchHospitals(hospitalPage, pageSize, searchKeyword)).data;
		setActiveHospitals(data);
		setHospitals(data.hospitals.map((row, index) => {return {key: row.hospitalId, ...row};}))
	}, [api.hospital, hospitalPage, pageSize, props.department?.id, searchKeyword]);

	// 추천 병원 호출
	useEffect(() => {
		getHospital();
	}, [getHospital]);

	//병원 목록 호출
	useEffect(() => {
		getActiveHospitals();
	}, [getActiveHospitals]);

	return (
		<>
			<Section>
				{/* 컨텐츠가 추가 된 경우, table heading에 있는 버튼에 'isButtonDisabled' props를 주면 해당 버튼이 disabled 상태가 됩니다 */}
				<TableHeading hasButton="컨텐츠 등록" isButtonDisabled={hospital !== undefined} onClick={showPopup}>
					추천 병원
				</TableHeading>
				<TableDefault columns={columns} dataSource={hospital ? [hospital] : []} />
			</Section>
			{/* 2-1.홈화면 관리 >2-1-3 병원 컨텐츠 추가 팝업 */}
			{isPopupVisible && (
				<DefaultPopup title="컨텐츠 등록"
											isPopupVisible={isPopupVisible}
											setIsPopupVisible={setIsPopupVisible}
											onClickCancel={onClickCancel}
											onClickOk={onConfirmSave}
											okWithoutClose isWithCancel isWithSave>
					<form onSubmit={onSearch}>
						<InputWrapper>
							<Input placeholder="검색어를 입력하세요." onChange={(e) => setKeyword(e.target.value)} value={keyword} />
							<Button isFilled isSmall>
								검색
							</Button>
						</InputWrapper>
					</form>
					<TableSort columns={popupColumns} data={hospitals} scroll={{ y: 275 }} pagination={pagination} rowSelection={rowSelection} />
				</DefaultPopup>
			)}
			{/* 팝업 4-1*/}
			{isConfirmVisible && (
				<ConfirmPopup isPopupVisible={isConfirmVisible} setIsPopupVisible={setIsConfirmVisible}>
					필수 항목을 다시 확인해 주세요.
				</ConfirmPopup>
			)}
			{/* 팝업 4-9 */}
			{isDeleteVisible && (
				<ConfirmPopup isPopupVisible={isDeleteVisible} setIsPopupVisible={setIsDeleteVisible} isWithCancel handleOk={handleOk} handleCancel={handleCancel} >
					삭제하시겠습니까?
				</ConfirmPopup>
			)}
			{/* 팝업 5-1 */}
			{isSubmitCountPopupVisible && (
				<ConfirmPopup isWithCancel={isWithCancel} handleOk={popupHandleOk} handleCancel={popupHandleCancel} isPopupVisible={isSubmitCountPopupVisible} setIsPopupVisible={setIsSubmitCountPopupVisible} >
					{popupContents}
				</ConfirmPopup>
			)}
		</>
	);
};

export default RecHospital;
