export enum SearchType {
  REG = "REG",
  MOD = "MOD",
}

export enum Gender {
  MALE = "Male",
  FEMALE = "Female",
}

export enum PaymentMethod {
  CREDIT = "CREDIT"
}

export interface ModifyResponse {
  modified: boolean;
  message?: string;
  errors?: Map<string, string>;
}

export interface AssignResponse {
  assigned: boolean;
  message?: string;
}

export interface CreateResponse {
  created: boolean;
  message?: string;
}

export interface RemoveResponse {
  removed: boolean;
  message?: string;
}

export interface MoveResponse {
  moved: boolean;
  message?: string;
}

export interface DeleteResponse {
  deleted: boolean;
  message?: string;
}

export interface ChangeResponse {
  changed: boolean;
  message?: string;
}

export interface ActivateResponse {
  activated: boolean;
  message?: string;
}

export interface DeactivateResponse {
  deactivated: boolean;
  message?: string;
}

export interface LoginResponse {
  success: boolean;
  accessToken: string;
  refreshToken: string;
}

export interface ValidResponse {
  valid: boolean;
  message: string;
}

export interface BeforeAndAfterTreatmentItem {
  beforeImageUrl: string;
  afterImageUrl: string;
  title: string;
  beforeFile?: File;
  afterFile?: File;
  key?: any;
}

export interface ModifyTreatmentRequest {
  hospitalId: string;
  treatmentName: string;
  departmentId: string;
  treatmentCategoryId: string;
  representative: string;
  price: number;
  priceOption?: string;
  introduction?: string;
  imageUrl?: string;
  textImageUrl?: string;
  enabled?: boolean;
  description: string[];
  treatmentAdvantagesAndEffects?: TreatmentAdvantageAndEffectItem[];
  treatmentProcedures?: TreatmentProcedureItem[];
  beforeAndAfterTreatment?: BeforeAndAfterTreatmentItem[];
  faq: TreatmentFaqItem[];
}

export interface TreatmentAdvantageAndEffectItem {
  imageUrl: string;
  content: string;
  file?: File;
  key?: any;
}

export interface TreatmentFaqItem {
  title: string;
  content: string;
  key?: any;
}

export interface TreatmentProcedureItem {
  imageUrl: string;
  title: string;
  file?: File;
  key?: any;
}

export interface ModifyPartnersRequest {
  hospitalName: string;
  hospitalNameEn?: string;
  hospitalNameZh?: string;
  representative?: string;
  managerName?: string;
  managerContact?: string;
  businessNumber?: string;
}

export interface PartnersModified {
  partnersId: string;
}

export interface ModifyPartnersAccountRequest {
  loginName: string;
  loginPassword: string;
}

export interface AssignPremiumCertificationRequest {
  totalPartnerHospitals: number;
  totalPartnerDoctors: number;
  displayRealData: boolean;
}

export interface ModifyPartnersHospitalResponse extends ModifyResponse {
  message: string;
}

export interface ModifyBannerRequest {
  thumbnailUrl: string;
  treatmentId: string;
}

export interface ModifyHospitalRequest {
  hospitalId: string;
  show: boolean;
  hospitalName?: string;
  tel: string;
  address: string;
  mapUrl: string;
  introductionSummary: string;
  introductionDescription: string;
  representativeImageUrl: string;
  treatmentMon: boolean;
  monOpensAt: string;
  monClosesAt: string;
  treatmentTue: boolean;
  tueOpensAt: string;
  tueClosesAt: string;
  treatmentWed: boolean;
  wedOpensAt: string;
  wedClosesAt: string;
  treatmentThu: boolean;
  thuOpensAt: string;
  thuClosesAt: string;
  treatmentFri: boolean;
  friOpensAt: string;
  friClosesAt: string;
  treatmentSat: boolean;
  satOpensAt: string;
  satClosesAt: string;
  treatmentSunAndOff: boolean;
  sunAndOffOpensAt: string;
  sunAndOffClosesAt: string;
  treatmentLunch: boolean;
  lunchStartsAt: string;
  lunchEndsAt: string;
  significant?: string;
}

export interface RequestDoctorModify {
  show: boolean;
  hospitalName: string;
  doctorName: string;
  position: string;
  introductionSummary: string;
  representativeImageUrl: string;
  introductionImages?: DoctorCreateImage[];
  treatments?: DoctorCreateTreatment[];
}

export interface CreateTreatmentRequest {
  hospitalId: string;
  treatmentName: string;
  departmentId: string;
  treatmentCategoryId: string;
  representative: string;
  price: number;
  priceOption?: string;
  introduction?: string;
  imageUrl?: string;
  textImageUrl?: string;
  enabled?: boolean;
  description: string[];
  treatmentAdvantagesAndEffects?: TreatmentAdvantageAndEffectItem[];
  treatmentProcedures?: TreatmentProcedureItem[];
  beforeAndAfterTreatment?: BeforeAndAfterTreatmentItem[];
  faq: TreatmentFaqItem[];
}

export interface CreateTreatmentReponse extends CreateResponse {
  treatmentId: string;
}

export interface CreatePartnersRequest {
  hospitalName: string;
  hospitalNameEn?: string;
  hospitalNameZh?: string;
  representative?: string;
  managerName?: string;
  managerContact?: string;
  businessNumber?: string;
  loginName: string;
  loginPassword: string;
}

export interface PartnersCreated extends CreateResponse {
  partnersId: string;
  partnersAccountId: string;
  errors: Map<string, string>;
}

export interface LogoutReqest {
  refreshToken: string;
}

export interface AssignRecommendedHospitalRequest {
  hospitalId: string;
}

export interface AssignRecommendedHospitalResponse extends AssignResponse {
  hospitalId?: string;
  hospitalName?: string;
}

export interface AssignRecommendedDoctorRequest {
  doctorId: string;
  imageUrl: string;
}

export interface AssignRecommendedDoctorResponse extends AssignResponse {
  doctorId: string;
}

export interface PartnersHospitalRequest {
  imageUrl: string;
  hospitalName: string;
  hospitalNameEn: string;
  hospitalNameZh: string;
}

export interface AppendPartnersHospitalResponse extends CreateResponse {
  itemId: string;
}

export interface AssignNewlyOpenedHospitalRequest {
  hospitalId: string;
}

export interface AssignNewlyOpenedHospitalResponse extends AssignResponse {
  message: string;
  hospitalId?: string;
  hospitalName?: string;
}

export interface BannerRequest {
  thumbnailUrl: string;
  treatmentId: string;
}

export interface CreateBannerResponse extends CreateResponse {
  bannerId?: string;
}

export interface LoginRequest {
  loginName: string;
  loginPassword: string;
}

export interface DoctorCreateImage {
  imageUrl: string;
  displayOrder: number;
}

export interface DoctorCreateTreatment {
  treatmentId: string;
  displayOrder: number;
}

export interface RequestDoctorCreate {
  show: boolean;
  hospitalId: string;
  hospitalName: string;
  doctorName: string;
  position: string;
  introductionSummary: string;
  representativeImageUrl: string;
  introductionImages?: DoctorCreateImage[];
  treatments?: DoctorCreateTreatment[];
}

export interface RecommendedTreatmentItem {
  title: string;
  treatmentItems: TreatmentItem[];
}

export interface TreatmentItem {
  key?: any;
  treatmentId: string;
  treatmentName: string;
  displayOrder: number;
}

export interface AssignSearchContentsRequest {
  searchTerms: string[];
  recommendedTreatment?: RecommendedTreatmentItem;
}

export interface SearchContentsReadResponse {
  searchTerms: string[];
  recommendedTreatment: RecommendedTreatmentItem;
}

export interface ItemResponse<T> {
  item: T;
}

export interface RecommendedHospitalItem {
  key?: any;
  hospitalId: string;
  hospitalName: string;
}

export interface RecommendedDoctorItem {
  key?: any;
  doctorId: string;
  doctorName: string;
  hospitalName: string;
  imageUrl: string;
}

export interface RecommendedResponse {
  recommended: boolean;
}

export interface CounterReponse<T> {
  counter: T;
}

export interface PremiumCertificationCounter {
  totalPartnerHospitals: number;
  totalPartnerDoctors: number;
  displayRealData: boolean;
}

export interface ItemsResponse<T> {
  totalCount?: number;
  items: T[];
}

export interface PartnersHospitalListItem {
  key?: any;
  partnersHospitalId: string;
  displayOrder: number;
  imageUrl: string;
  hospitalName: string;

  hospitalNameEn: string;

  hospitalNameZh: string;
}

export interface NewlyOpenedHospitalItem {
  key?: any;
  hospitalId: string;
  hospitalName: string;
}

export interface HomeDepartmentItem {
  id: string;
  name: string;
  displayOrder: number;
}

export interface BannerItem {
  key?: any;
  id: string;
  number: number;
  displayOrder: number;
  thumbnailUrl: string;
  hospitalId: string;
  hospitalName: string;
  treatmentId: string;
  treatmentName: string;
}

export interface BannerDetail {
  id: string;
  displayOrder: number;
  thumbnailUrl: string;
  treatmentId: string;
  hospitalId: string;
  createdAt: string;
  updatedAt: string;
}

export interface GetBannerResponse {
  banner: BannerDetail;
}

export interface IssuedSignedUrl {
  signedUrl: string;
  uploadTargetUrl: string;
}

export interface HospitalInventory {
  totalElements: number;
  hospitals: HospitalInventoryItem[];
}

export interface HospitalInventoryItem {
  key?: any;
  hospitalId: string;
  registerDate: string;
  updateDate: string;
  departmentName: string;
  hospitalName: string;
  registered: boolean;
  display: boolean;
}

export interface HospitalDetail {
  id: string;
  name: string;
  departments: string[];
  treatments: string[];
  treatmentCategories: string[];
  show: boolean;
  tel: string;
  address: string;
  mapUrl: string;
  introductionSummary: string;
  introductionDescription: string;
  representativeImageUrl: string;
  monTreatmentPeriod: TreatmentPeriodItem;
  tueTreatmentPeriod: TreatmentPeriodItem;
  wedTreatmentPeriod: TreatmentPeriodItem;
  thuTreatmentPeriod: TreatmentPeriodItem;
  friTreatmentPeriod: TreatmentPeriodItem;
  satTreatmentPeriod: TreatmentPeriodItem;
  sunDayOffTreatmentPeriod: TreatmentPeriodItem;
  lunchTreatmentPeriod: TreatmentPeriodItem;
  significant: string;
}

export interface TreatmentPeriodItem {
  treatment: boolean;
  dayOfWeek?: string;
  opensAt: string;
  closesAt: string;
}

export interface DoctorInventory {
  totalElements: number;
  doctors: DoctorItem[];
}

export interface DoctorItem {
  doctorId: string;
  registerDate: string;
  updateDate: string;
  departmentName: string;
  hospitalName: string;
  hospitalDisplay: boolean;
  doctorName: string;
  doctorDisplay: boolean;
}

export interface DetailItemImage {
  key?: any;
  imageUrl: string;
  displayOrder: number;
  file?: File;
}

export interface DetailItemTreatment {
  key?: any;
  treatmentId: string;
  treatmentName: string;
  displayOrder: number;
}

export interface DoctorDetailItem {
  doctorId: string;
  doctorDisplay: boolean;
  hospitalId: string;
  hospitalName: string;
  doctorName: string;
  position: string;
  introductionSummary: string;
  representativeImageUrl: string;
  introductionImageUrls: DetailItemImage[];
  treatments: DetailItemTreatment[];
}

export enum ShiftDirection {
  FORWARD = 'Forward',
  BACKWARD = 'Backward',
}

export interface ShiftRequest {
  direction: ShiftDirection;
}

export interface IssuedSignedUrl {
  signedUrl: string;
  uploadTargetUrl: string;
}

export interface LoginUser {
  userId: string;
  username: string;
}

export interface DataResponse<T> {
  status?: boolean;
  success?: boolean
  message?: string,
  data: T;
}

export interface PaymentListResponse<T> {
  totalElements: number;
  totalAmount: number;
  message: string;
  items: T[];
}

export interface PaymentHistoriesResponse<T> {
  totalElements: number;
  message: string;
  items: T[];
}

export interface ResultResponse<T> {
  status?: boolean;
  success?: boolean
  message?: string,
  result: T;
}

export interface TreatmentItemForAdmin {
  key?: any;
  treatmentId: string;
  number: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  departmentId: string;
  departmentName: string;
  treatmentCategoryId: string;
  treatmentCategoryName: string;
  hospitalId: string;
  hospitalName: string;
  enabled: boolean;
}

export enum SortOrder {
  DESC = "desc",
  ASC = "asc",
}

export enum SearchPeriodOption {
  CREATED = "created",
  UPDATED = "updated",
}

export enum SearchSortBy {
  CREATED = "created",
  UPDATED = "updated",
  HOSPITAL = "hospital",
}

export enum TreatmentSearchKeywordOption {
  ALL = "all",
  TREATMENT = "treatment",
  HOSPITAL = "hospital",
}

export enum DoctorSearchTermsOption {
  ALL = "ALL",
  HOSPITAL = "HOSPITAL",
  DOCTOR = "DOCTOR",
}

export interface DepartmentItem {
  id: string;
  name: string;
  nameEn: string;
  nameZh: string;
  enabled: boolean;
  displayOrder: number;
  cannotBeDeactivated: boolean;
}

export enum SearchPeriod {
  TODAY,
  WEEK,
  MONTH,
  MONTHS,
  ALL
}

export interface HospitalTreatmentsResponse {
  treatments: HospitalTreatmentsResponseItem[];
}

export interface HospitalTreatmentsResponseItem {
  key?: any;
  treatmentId: string;
  treatmentName: string;
  departmentName: string;
  departmentEnabled: boolean;
  treatmentCategoryName: string;
  treatmentCategoryEnabled: boolean;
}

export interface GetAllDepartmentsWithCategoriesResponse {
  status: boolean;
  message: string;
  data: DepartmentWithCategoryList[];
}

export interface DepartmentWithCategoryList {
  departmentId: string;
  departmentName: string;
  categories: CategoryItem[];
}

export interface CategoryItem {
  categoryId: string;
  categoryName: string;
}

export interface TreatmentCategoryItem {
  categoryId: string;
  departmentId: string;
  categoryName: string;
  categoryNameEn: string;
  categoryNameZh: string;
  enabled: boolean;
  displayOrder: number;
  cannotBeDeactivated: boolean;
}

export enum TreatmentRepresentative {
  NORMAL = "NORMAL",
  REPRESENTATIVE = "REPRESENTATIVE",
}

export interface TreatmentDetails {
  id: string;
  hospitalId: string;
  enabled: boolean;
  treatmentName: string;
  departmentId: string;
  treatmentCategoryId: string;
  representative: TreatmentRepresentative,
  price: number;
  priceOption: string;
  introduction: string;
  imageUrl: string;
  textImageUrl: string;
  description: string[];
  treatmentAdvantagesAndEffects: TreatmentAdvantageAndEffectItem[];
  treatmentProcedures: TreatmentProcedureItem[];
  beforeAndAfterTreatment?: BeforeAndAfterTreatmentItem[];
  faq: TreatmentFaqItem[];
}

export interface DepartmentRequest {
  departmentName: string;
  departmentNameEn: string;
  departmentNameZh: string;
  enabled: boolean;
}

export interface CreateDepartmentResponse extends CreateResponse {
  departmentId: string;
}

export interface TreatmentCategoryRequest {
  categoryName: string;
  categoryNameEn: string;
  categoryNameZh: string;
  enabled: boolean;
}

export interface CreateTreatmentCategoryResponse extends CreateResponse {
  categoryId: string;
}

export enum PartnersSearchOptions {
  ALL = "all",
  HOSPITAL_NAME = "hospitalName",
  ID = "id",
}

export interface PartnersInventoryItem {
  key?: any;
  partnersId: string;
  number: number;
  createdAt: string;
  hospitalName: string;
  accountId: string;
  loginName: string;
}

export interface PartnersDetail {
  hospitalName: string;
  hospitalNameEn: string;
  hospitalNameZh: string;
  representative?: string;
  managerName?: string;
  managerContact?: string;
  businessNumber?: string;
  loginName: string;
  createdAt: string;
}

export enum MemberSearchOptions {
  ALL = "all",
  EMAIL = "email",
  NAME = "name",
  CONTACT = "contact",
}

export enum PaymentSearchOptions {
  ALL = "all",
  HOSPITAL = "hospital",
  MEMBER = "member",
  PAYMENT = "payment"
}

export enum ResignedMemberSearchOptions {
  ALL = "all",
  EMAIL = "email",
  NAME = "name",
}

export interface MemberInventoryItem {
  number: number;
  memberId: string;
  email: string;
  name: string;
  birthday: string;
  contact: string;
  locale: string;
  gender: Gender;
  createdAt: string;
}

export interface MemberDetail {
  memberId: string;
  username: string;

  usernameEn: string;
  locale: string;
  createdAt: string;
  resignedAt?: string;
  birthday: string;
  gender: Gender;
  email: string;
  countryNumber: string;
  contact: string;
  memberType: string;
  signUpFunnel: string;
}

export interface DisabledResponse {
  canBeDisabled: boolean;
  message: string
}

export interface PaymentItem {
  paymentId: string;
  status: string;
  paymentNo: string;
  amount: number;
  executionDate: string;
  visitedDate: string;
  hospitalName: string;
  doctorName: string;
  treatmentName: string;
}

export interface PaymentHistoryItem {
  paymentId: string;
  status: string;
  paymentNo: string;
  amount: number;
  memberId: string;
  name: string;
  nameEn: string;
  executionDate: string;
  visitedDate: string;
  hospitalName: string;
  doctorName: string;
  treatmentName: string;
}

export interface DetailResponse<T> {
  success: boolean;
  message: string;
  payload: T;
}
export interface PaymentDetails {
  appointeeName: string,
  appointeeNameEn: string,
  nationality: string,
  birth: string,
  contact: string,
  hospitalName: string,
  appointmentDate: string,
  doctorName: string,
  treatmentName: string,
  memo: string,
  paymentNo: string,
  paymentMethod: PaymentMethod,
  executionDate: string,
  amount: number
}