import { useCallback, useState } from 'react';
import TableDefault from '../../../components/Tables/TableDefault';
import InnterTableTag from '../../../components/InnterTableTag';
import { FileExcelOutlined } from '@ant-design/icons';
import DefaultPopup from '../../../components/Popup/DefaultPopup';
import ReservationInformation from '../ReservationInformation';
import { ExcelDownloadButton, PaymentDataWrapper, SubHeading, TotalNum, TotalPayment } from './styles';
import PaymentInformation from '../PaymentInformation';
import UserReservationTable from '../../Users/UsersList/UsersReservationTable';
import DetailButton from '../../../components/InnerTdButton/DetailButton';
import { useApi } from '../../../contexts/ApiContext';
import { PaymentDetails, PaymentHistoriesResponse, PaymentHistoryItem } from '../../../model';
import moment from 'moment/moment';


interface PaymentHistoryProps {
  paymentHistories?: PaymentHistoriesResponse<PaymentHistoryItem>;
  paymentItems?: PaymentHistoryItem[];
  pagination: any;
}



const PaymentHistoryTable = (props: PaymentHistoryProps) => {
  const api = useApi();

  const [detailMemberId, setDetailMemberId] = useState<string>('');
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>();
  const [isDetailVisible, setIsDetailVisible] = useState<boolean>(false);

  const columns = [
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (value: string) => {
        return value === 'PAYMENT' ?
          <InnterTableTag isPaid>결제</InnterTableTag> : <InnterTableTag isCanceled>취소</InnterTableTag>
      }
    },
    {
      title: '결제/취소번호',
      dataIndex: 'paymentNo',
      key: 'paymentNo',
    },
    {
      title: '성명(영문명)',
      dataIndex: 'memberName',
      key: 'memberName',
      render: (value: string, paymentItem: PaymentHistoryItem) => {
        return (paymentItem.nameEn === undefined || paymentItem.nameEn === '') ? paymentItem.name :
          paymentItem.name + '(' + paymentItem.nameEn + ')';
      }
    },
    {
      title: '금액',
      dataIndex: 'amount',
      key: 'amount',
      render: (value: string) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    {
      title: '결제/취소일',
      dataIndex: 'executionDate',
      key: 'executionDate',
    },
    {
      title: '방문일',
      dataIndex: 'visitedDate',
      key: 'visitedDate',
    },
    {
      title: '병원명',
      dataIndex: 'hospitalName',
      key: 'hospitalName',
    },
    {
      title: '의사/시술명',
      dataIndex: 'docTreatName',
      key: 'docTreatName',
      render: (value: string, paymentItem: PaymentHistoryItem) => {
        return <span>{paymentItem.doctorName}/{paymentItem.treatmentName}</span>
      }
    },
    {
      title: '관리',
      dataIndex: 'detail',
      key: 'detail',
      render: (value: any, paymentItem: PaymentHistoryItem) =>
        <DetailButton onClick={() => onClickDetail(paymentItem)} />,
    },
  ];

  const onClickDetail = (payment: PaymentHistoryItem) => {
    loadPaymentDetails(payment.paymentId).then().catch()

    setDetailMemberId(payment.memberId)
    setIsDetailVisible(true);
  };

  const loadPaymentDetails = useCallback(async (paymentId: string) => {
    const payment = (await api.payment.getPaymentDetails(paymentId)).data;

    setPaymentDetails(payment.payload);
  }, [api.payment]);

  const onClickDownload = useCallback( async () => {
    const result = await api.payment.downloadPaymentList()
    const blob = new Blob([result.data], {type: 'application/msexcel;charset=UTF-8'});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = '결제내역_' + moment().format('yyyyMMDD') + '.xlsx';
    link.click();
  }, [api.payment]);

  return (
    <>
      <PaymentDataWrapper>
        <TotalPayment>
          <strong>결제 내역</strong>
          <TotalNum>
            총<strong>{props.paymentHistories?.totalElements}</strong>건
          </TotalNum>
        </TotalPayment>
        <div>
          <ExcelDownloadButton type="button" onClick={onClickDownload}>
            <FileExcelOutlined style={{ marginRight: 5 }} />
            엑셀 다운로드
          </ExcelDownloadButton>
        </div>
      </PaymentDataWrapper>
      <TableDefault columns={columns}
                    dataSource={props.paymentItems}
                    scroll={{ x: 1500 }} pagination={props.pagination} />
      {isDetailVisible && (
        <DefaultPopup
          title="상세보기"
          isWithCancel="확인"
          isPopupVisible={isDetailVisible}
          setIsPopupVisible={setIsDetailVisible}
        >
          <SubHeading>예약 정보</SubHeading>
          <ReservationInformation
            paymentDetails={paymentDetails}
          />
          <div style={{ margin: '50px 0' }}>
            <SubHeading>결제 정보</SubHeading>
            <PaymentInformation
              paymentDetails={paymentDetails}/>
          </div>
          <UserReservationTable
            memberId={detailMemberId}
          />
        </DefaultPopup>
      )}
    </>
  );
};

export default PaymentHistoryTable;
