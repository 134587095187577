import styled from "styled-components";
import { buttonBase, fontStyle16, flexbox } from "../../../styles/mixins";

export const Section = styled.section`
	@media (min-width: 821px) {
		margin: 0 30px 100px;
	}

	@media screen and (min-width: 1025px) {
		margin: 0 70px 100px;
	}
	
	input {
    width: 100%;
  }
`;

export const FlexWrapper = styled.div`
	${flexbox("space-between", "flex-start")}
	margin:0 0 45px 0;
`;

export const ImageWrapper = styled.div`
	${flexbox("flex-start", "flex-start")};
	margin: 0 0 45px 0;
`;

export const Button = styled.button<{isFilled?: boolean; isBig?: boolean;}>`
	${buttonBase};
	${fontStyle16};
	width: 110px;
	height: 36px;
	color: ${(props) => props.theme.white};
`;


export const SaveButton = styled(Button)`
  margin: -10px 0 80px auto;
`;
