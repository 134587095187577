import { ChangeEvent, FormEvent, ReactNode, useCallback, useEffect, useState } from "react";
import { Breadcrumb, TablePaginationConfig } from "antd";
import ContentHeading from "../../../components/ContentHeading";
import Select from "../../../components/Inputs/Select";
import SearchBox from "../../../components/SearchBox";
import TableDefault from "../../../components/Tables/TableDefault";
import ToggleButton from "../../../components/ToggleButton";
import EditLink from "../../../components/InnerTdButton/EditLink";
import ConfirmPopup from "../../../components/Popup/ConfirmPopup";
import { StyledLayout, StyledTag, FlexWrapper } from "./styles";
import {
	DepartmentItem,
	HospitalInventory,
	HospitalInventoryItem,
	SearchType,
	TreatmentItemForAdmin,
} from '../../../model';
import { useApi } from "../../../contexts/ApiContext";
import moment, { Moment } from "moment";
import { EventValue, RangeValue } from "rc-picker/lib/interface";

const optionListUnder = ["등록일", "수정일"];
const dateFormat = "YYYYMMDD";
const popupText = {
	hide: 
		<>
			미노출 처리 하시겠습니까? <br />
			<small>미 노출시 소속된 의사,시술 또한 앱에 표시되지 않습니다.</small>
		</>,
	unhideable:
		<>
			홈 화면 컨텐츠에 등록되어 있어 <br />
			미노출이 불가능 합니다.
		</>,
	display:
		<>
			노출 처리 하시겠습니까? <br />
			<small>노출 시 앱에 바로 반영됩니다.</small>
		</>,
	undisplayable:
		<>
			미등록 컨텐츠입니다. <br />
			컨텐츠 등록 후 노출이 가능합니다.
		</>
};

const Hospital = () => {
	const api = useApi();

	// 진료과 목록
	const [departments ,setDepartments] = useState<DepartmentItem[]>([]);
	const [selectedDepartment, setSelectedDepartment] = useState<DepartmentItem>();

	// 병원 목록
	const [hospitalData, setHospitalData] = useState<HospitalInventory>();
	const [hospitals, setHospitals] = useState<HospitalInventoryItem[]>([]);

	// 병원 검색
	const [keyword, setKeyword] = useState<string>();
	const [searchKeyword, setSearchKeyword] = useState<string>();

	const [type, setType] = useState<SearchType>(SearchType.REG);
	const [searchType, setSearchType] = useState<SearchType>(SearchType.REG);

	const [startDate, setStartDate] = useState<EventValue<Moment> | undefined>(moment('2022-01-01'));
	const [endDate, setEndDate] = useState<EventValue<Moment> | undefined>(moment());
	const [searchStartDate, setSearchStartDate] = useState<EventValue<Moment>>();
	const [searchEndDate, setSearchEndDate] = useState<EventValue<Moment>>();

	// 병원 페이징
	const [page, setPage] = useState<number>(0);
	const [size,] = useState<number>(25);

	// 팝업 관리
	const [popupContents, setPopupContents] = useState<string | ReactNode>();
	const [popupHandleOk, setPopupHandleOk] = useState(() => () => {});
	const [popupHandleCancel, setPopupHandleCancel] = useState(() => () => {});
	const [isWithCancel, setIsWithCancel] = useState(false);
	const [isPopupVisible, setIsPopupVisible] = useState(false);

	// 진료과 데이터
	const getAllDepartments = useCallback(async () => {
		const data = (await api.category.getDepartments()).data;
		if (data.success) {
			setDepartments(data.result);
		}
	}, [api.category]);

	// 병원 데이터
	const getAllHospitals = useCallback(async () => {
		const data = (await api.hospital.getAllHospitals(page, size, searchKeyword, selectedDepartment?.id, searchType, searchStartDate?.format(dateFormat), searchEndDate?.format(dateFormat))).data;
		setHospitalData(data);
	}, [api.hospital, searchEndDate, page, searchKeyword, searchType, selectedDepartment?.id, size, searchStartDate]);

	// 병원 목록 칼럼
	const columns = [
		{
			title: "번호",
			dataIndex: "number",
			key: "number",
			render: (value: any, record: HospitalInventoryItem, index: number) => {
				return (size * page) + (index + 1);
			}
		},
		{
			title: "등록일",
			dataIndex: "registerDate",
			key: "registerDate",
			sorter: (a: HospitalInventoryItem, b: HospitalInventoryItem) => {
				const aDate = moment(a.registerDate, "YYYY.MM.DD");
				const bDate = moment(b.registerDate, "YYYY.MM.DD");
				return bDate.diff(aDate);
			},
		},
		{
			title: "수정일",
			dataIndex: "updateDate",
			key: "updateDate",
			sorter: (a: HospitalInventoryItem, b: HospitalInventoryItem) => {
				const aDate = moment(a.updateDate, "YYYY.MM.DD");
				const bDate = moment(b.updateDate, "YYYY.MM.DD");
				return bDate.diff(aDate);
			},
		},
		{ title: "진료과", dataIndex: "departmentName", key: "departmentName" },
		{
			title: "병원명",
			dataIndex: "hospitalName",
			key: "hospitalName",
			sorter: (a: HospitalInventoryItem, b: HospitalInventoryItem) => {
				return a.hospitalName < b.hospitalName ? -1 : a.hospitalName === b.hospitalName ? 0 : 1;
			},
		},
		{
			title: "상태",
			dataIndex: "registered",
			key: "registered",
			render: (value: any, record: HospitalInventoryItem, index: number) => {
				if (record.registered) {
					return <StyledTag isRegistered>등록됨</StyledTag>;
				} else {
					return <StyledTag>미등록</StyledTag>;
				}
			},
			sorter: (a: HospitalInventoryItem, b: HospitalInventoryItem) => {
				return a.registered < b.registered ? -1 : a.registered === b.registered ? 0 : 1;
			},
		},
		{
			title: "관리",
			dataIndex: "edit",
			key: "edit",
			render: (value: any, record: HospitalInventoryItem) => 
				<EditLink 
					style={{ position: "relative", top: "5px", margin: "auto" }}
					link={`/contents/hospital/${record.hospitalId}`} 
				/>,
		},
		{
			title: "노출",
			dataIndex: "exposure",
			key: "exposure",
			render: (value: any, record: HospitalInventoryItem) => 
				<ToggleButton checked={record.display} onChange={() => checkDeactivation(record)} /> 
		},
	];

	// 병원 목록 페이지네이션
	const pagination: TablePaginationConfig = { 
		position: ["bottomCenter"], 
		defaultPageSize: size,
		showSizeChanger: false,
		total: hospitalData?.totalElements, 
		current: page + 1,
		onChange: (page: number, pageSize: number) => {
			setPage(page - 1);
		},
	};

	// 노출 api 호출
	const changeState = useCallback(async (item: HospitalInventoryItem) => {
		let data = null
		if(item.display) {
			data = (await api.hospital.deactivateHospitalDisplay(item.hospitalId)).data;
		} else {
			data = (await api.hospital.activateHospitalDisplay(item.hospitalId)).data;
		}		

		setIsPopupVisible(false);
		getAllHospitals();
	}, [api.hospital, getAllHospitals]);

	// 미노출 가능 여부 확인
	const checkDeactivation = async (item: HospitalInventoryItem) => {
		if (item.display) {
			const data = (await api.hospital.checkRecommendedHospital(item.hospitalId)).data;
			if(data.canBeDisabled) {
				onChangeToggle(item);
			} else {
				deactivationPopup();
			}
		} else {
			onChangeToggle(item);
		}
	};

	// 미노출 불가 Popup
	const deactivationPopup = () => {
		let isWithCancel = false;
		let popupContents = popupText.unhideable;
		let handleOk = () => () => setIsPopupVisible(false);
		let handleCancel = () => () => setIsPopupVisible(false);

		setIsWithCancel(isWithCancel);
		setPopupContents(popupContents);
		setPopupHandleOk(handleOk);
		setPopupHandleCancel(handleCancel);
		setIsPopupVisible(true);
	};

	// 노출 여부 토글
	const onChangeToggle = useCallback((item: HospitalInventoryItem) => {

		let isWithCancel = false;
		let popupContents = popupText.hide;
		let handleOk = () => () => setIsPopupVisible(false);
		let handleCancel = () => () => setIsPopupVisible(false);

		if (item.display) {			
			// 숨김
			popupContents = popupText.hide;
			handleOk = () => async () => await changeState(item);
			isWithCancel = true;
		} else {			
			// 노출
			if (item.registered) {
				// 등록 콘텐츠
				popupContents = popupText.display;
				handleOk = () => async () => await changeState(item);
			} else {
				// 미등록 콘텐츠
				popupContents = popupText.undisplayable;
			}
		}

		setIsWithCancel(isWithCancel);
		setPopupContents(popupContents);
		setPopupHandleOk(handleOk);
		setPopupHandleCancel(handleCancel);
		setIsPopupVisible(true);
	}, [changeState]);

	// 병원 검색 onChange
	const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value) {
			setKeyword(e.target.value);
		} else {
			setKeyword(undefined);
		}
	};
	const onChangeUnderOption = (e: any) => {
		switch (e) {
			case "등록일": setType(SearchType.REG); break;
			case "수정일": setType(SearchType.MOD); break;
		}
	};
	const onChangeDate = (values: RangeValue<Moment>) => {
		setStartDate(values?.[0]);
		setEndDate(values?.[1]);
	};

	// 검색 submit
	const onSubmit = useCallback((e: FormEvent) => {
		e.preventDefault();

		setPage(0);

		setSearchKeyword(keyword);
		setSearchType(type);
		setSearchStartDate(startDate);
		setSearchEndDate(endDate);
	}, [endDate, keyword, startDate, type]);

	// 진료과 변경
	const onChangeDepartments = useCallback((value: string) => {
		const department = departments.find(row => row.name === value);
		setSelectedDepartment(department);
	}, [departments]);

	// 진료과 데이터 호출
	useEffect(() => {
		getAllDepartments();
	}, [getAllDepartments]);

	// 병원 데이터 호출
	useEffect(() => {
		getAllHospitals();
	}, [getAllHospitals]);

	// 병원 데이터 처리
	useEffect(() => {
		const data = hospitalData?.hospitals.map((row) => {
			return { key: row.hospitalId, ...row };
		});

		if (data) {
			setHospitals(data);
		}
	}, [hospitalData]);

	return (
		<>
			<Breadcrumb separator=">">
				<Breadcrumb.Item>컨텐츠 관리</Breadcrumb.Item>
				<Breadcrumb.Item href="">병원 관리</Breadcrumb.Item>
			</Breadcrumb>
			<ContentHeading>병원 관리</ContentHeading>

			<StyledLayout>
				<SearchBox 
					title="병원명"
					searchValue={keyword}
					onChangeSearch={onChangeSearch}
					optionListUnder={optionListUnder}
					onChangeUnderOption={onChangeUnderOption}
					startDate={startDate}
					endDate={endDate}
					onChangeDate={onChangeDate}
					onSubmit={onSubmit}
					allowClear={true}
				/>
				<FlexWrapper>
					<span>
						총 <strong>{hospitalData?.totalElements ?? 0}</strong>개
					</span>
					<Select value={selectedDepartment?.name ?? '전체 진료과'} onChange={onChangeDepartments} optionList={["전체 진료과", ...(departments.map((row) => (row.name)))]} />
				</FlexWrapper>
				<TableDefault columns={columns} dataSource={hospitals} pagination={pagination} />
			</StyledLayout>
			{/* 팝업 3-10-1 */}
			{/* {isPopupVisible && (
				<ConfirmPopup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} isWithCancel>
					미노출 처리 하시겠습니까? <br />
					<small>미 노출시 소속된 의사,시술 또한 앱에 표시되지 않습니다.</small>
				</ConfirmPopup>
			)} */}
			{/* 팝업 3-10-2 */}
			{/* {isPopupVisible && (
				<ConfirmPopup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible}>
					홈 화면 컨텐츠에 등록되어 있어 <br />
					미노출이 불가능 합니다.
				</ConfirmPopup>
			)} */}
			{/* 팝업 3-10-3 */}
			{/* {isPopupVisible && (
				<ConfirmPopup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} isWithCancel>
					노출 처리 하시겠습니까? <br />
					<small>노출 시 앱에 바로 반영됩니다.</small>
				</ConfirmPopup>
			)} */}
			{/* 팝업 3-10-4 */}
			{/* {isPopupVisible && (
				<ConfirmPopup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible}>
					미등록 컨텐츠입니다. <br />
					컨텐츠 등록 후 노출이 가능합니다.
				</ConfirmPopup>
			)} */}
			{isPopupVisible && (
				<ConfirmPopup isWithCancel={isWithCancel} handleOk={popupHandleOk} handleCancel={popupHandleCancel} isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible}>
					{popupContents}
				</ConfirmPopup>
			)}
		</>
	);
};

export default Hospital;
