import { Outlet, useLocation } from "react-router-dom";
import { Layout } from "antd";
import GNB from "../../layouts/GNB";
import LNB from "../../layouts/LNB";
import { StyledLayout, StyledContent } from "./styles";
import { useEffect, useState } from "react";

const Contents = () => {
	const { pathname } = useLocation();
	const [menu, setMenu] = useState<string[]>();

	useEffect(() => {
		let menu: string[] = [];
		if (pathname.startsWith("/contents/hospital")) {
			menu = ["2"];
		} else if (pathname.startsWith("/contents/doctor")) {
			menu = ["3"];
		} else if (pathname.startsWith("/contents/treat")) {
			menu = ["4"];
		}

		setMenu(menu);
	}, [pathname]);

	return (
		<>
			<Layout>
				<GNB />
				<Layout>
					<LNB defaultMenu={menu} defaultOpenKeys={["sub1"]} />
					<StyledLayout>
						<StyledContent>
							<Outlet />
						</StyledContent>
					</StyledLayout>
				</Layout>
			</Layout>
		</>
	);
};

export default Contents;
