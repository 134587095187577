import React from 'react';
import { EditOutlined } from "@ant-design/icons";
import { StyledButton } from "../styles";

interface EditLinkProps {
	link?: string;
	style?: any;
}

const EditLink = ({ link, style }: EditLinkProps) => {
	return (
		<StyledButton style={style} as="a" href={link}>
			<EditOutlined style={{ fontSize: "18px" }} />
		</StyledButton>
	);
};

export default React.memo(EditLink);
