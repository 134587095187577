import React from 'react';
import { InputProps } from 'antd';
import { ChangeEventHandler } from 'react';
import { FlexWrapper, InputWrapper, StyledInput, HelperWrapper, MaxLength, HelperText } from './styles';

interface StyledInputProps extends InputProps {
  placeholder?: string;
  value?: string | ReadonlyArray<string> | number | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isRequiredEmpty?: boolean;
  maxLength?: number;
  isCost?: boolean;
  isPopup?: boolean;
  disabled?: boolean;
}

// 필수 입력 값이 비어있는 경우, isRequiredEmpty props를 넣어줍니다.
const Input = ({
  type,
  placeholder,
  value,
  onChange,
  onKeyDown,
  isRequiredEmpty,
  maxLength,
  isCost,
  isPopup,
  disabled,
}: StyledInputProps) => {
  return (
    <FlexWrapper>
      <InputWrapper isDisabled={disabled}>
        <StyledInput
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
          isRequiredEmpty={isRequiredEmpty}
          isCost={isCost}
          disabled={disabled}
        />
        {isCost && <span>원~</span>}
      </InputWrapper>
      {maxLength && (
        <MaxLength isPopup={isPopup}>
          {value?.toString()?.length} / <span>{maxLength}자 </span>
        </MaxLength>
      )}
      <HelperWrapper>{isRequiredEmpty && <HelperText>필수 입력 값입니다.</HelperText>}</HelperWrapper>
    </FlexWrapper>
  );
};

export default React.memo(Input);
