import styled from "styled-components";
import { flexbox, fontStyle24, fontStyle12, buttonBase } from "../../styles/mixins";

export const Wrapper = styled.div`
	${flexbox("space-between", "center")};
	margin: 30px 80px 0 24px;
`;

export const HeadingWrapper = styled.div`
	${flexbox("flex-start", "flex-end")};
`;

export const StyledPageHeading = styled.h1`
	${fontStyle24};
	font-weight: 700;
`;

export const HelperText = styled.span`
	${fontStyle12};
	color: ${(props) => props.theme.red};
	margin: 0 0 0 5px;
`;

export const Button = styled.button<{isFilled?: boolean; isMiddle?: boolean}>`
	${buttonBase};
	position: relative;
	top: 20px;
	right: 18px;
	height: 42px;
	font-weight: 500;
`;
