import styled from "styled-components";
import { buttonBase, flexbox, fontStyle14, fontStyle16 } from "../../styles/mixins";
import logo from "../../assets/logo.svg";

export const Wrapper = styled.div`
	${flexbox("center", "center")};
	width: 100vw;
	height: 100vh;
`;

export const LogoWrapper = styled.div`
    width: 400px;
    height: 180px;
    border-radius: 8px;
    margin: 0 100px 0 0;
    background-image: url(${logo});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Form = styled.form`
	width: 400px;
`;

export const InputWrapper = styled.div`
	position: relative;
	margin: 0 0 15px 0;

	&:nth-of-type(2) {
		margin: 0 0 8px 0;
	}

	.anticon {
		position: absolute;
		top: 12px;
		left: 10px;
		color: ${(props) => props.theme.primary};
	}
`;

export const Input = styled.input`
	${fontStyle16};
	color: ${(props) => props.theme.primary};
	display: block;
	width: 100%;
	height: 48px;
	padding: 8px 16px 8px 45px;
	border: 1px solid ${(props) => props.theme.inputBorder};
	border-radius: 4px;
	transition: border 200ms ease-in-out;

	::placeholder {
		color: ${(props) => props.theme.placeholder};
	}

	&:focus {
		border: 1px solid ${(props) => props.theme.accent};
	}
`;

export const HelperWrapper = styled.div`
	min-height: 30px;
`;

export const Helper = styled.div`
	${fontStyle14};
	color: ${(props) => props.theme.red};
	margin: 0 0 10px 0;

	span {
		margin: 0 0 0 3px;
	}
`;

export const Button = styled.button`
	${buttonBase};
	width: 100%;
	height: 52px;
	margin: 10px 0 0 0;
`;
