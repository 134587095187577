import React from 'react';
import { StyledTag } from './styles';

interface InnterTableTagTagProps {
  isRegistered?: boolean;
  isCanceled?: boolean;
  isPaid?: boolean;
  children?: any;
}

const InnterTableTag = ({ isRegistered, isCanceled, isPaid, children }: InnterTableTagTagProps) => {
  return (
    <StyledTag isRegistered={isRegistered} isCanceled={isCanceled} isPaid={isPaid}>
      {children}
    </StyledTag>
  );
};

export default InnterTableTag;
