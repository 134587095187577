import { CreatePartnersRequest, DataResponse, DeleteResponse, ItemsResponse, ModifyPartnersAccountRequest, ModifyPartnersRequest, ModifyResponse, PartnersCreated, PartnersDetail, PartnersInventoryItem, PartnersModified, PartnersSearchOptions, ResultResponse, ValidResponse } from '../model';
import api from './index';
import Qs from 'qs';

const Partners = {
  getPartners: (
    page: number = 0, 
    size: number = 25, 
    keywordOption: PartnersSearchOptions = PartnersSearchOptions.ALL,
    keyword?: string,
    start?: string,
    end?: string,
  ) => {
    return api.get<ResultResponse<ItemsResponse<PartnersInventoryItem>>>(
      '/partners', 
      {
        params: {
          pageNumber: page,
          pageSize: size,
          keywordOption: keywordOption,
          keyword: keyword,
          start: start,
          end: end,
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    );
  },
  getPartner: (partnersId: string) => {
    return api.get<DataResponse<PartnersDetail>>(`/partners/${partnersId}`);
  },
  addPartner: (data: CreatePartnersRequest) => {
    return api.post<PartnersCreated>('/partners', data);
  },
  modifyPartner: (partnersId: string, data: ModifyPartnersRequest) => {
    return api.put<PartnersModified>(`/partners/${partnersId}`, data);
  },
  deleteParnter: (partnersId: string) => {
    return api.delete<DeleteResponse>(`/partners/${partnersId}`);
  },
  modifyPartnersAccount: (accountId: string, data: ModifyPartnersAccountRequest) => {
    return api.put<ModifyResponse>(`/partners/accounts/${accountId}`, data);
  },
  verifyLoginName: (loginName: string) => {
    return api.get<ValidResponse>('/partners/accounts/login-name.verify', {params: {loginName: loginName}});
  },
};

export default Partners;