import React from 'react';
import { Div } from "./styles";

const Divider = React.memo(() => {
	return <Div />;
});

const SubDivider = React.memo(() => {
	return <Div isSub />;
});

export {Divider, SubDivider};
