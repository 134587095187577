import { DataResponse, ItemsResponse, SearchPeriodOption, SortOrder, TreatmentItemForAdmin, TreatmentSearchKeywordOption, SearchSortBy, ActivateResponse, DeactivateResponse, DeleteResponse, TreatmentDetails, CreateTreatmentRequest, CreateTreatmentReponse, ModifyTreatmentRequest, ModifyResponse, AssignResponse } from '../model';
import api from './index';
import Qs from 'qs';

const Treatment = {
  getTreatments: (
    keywordOption: TreatmentSearchKeywordOption,
    start: string,
    end: string,
    keyword?: string,
    periodOption?: SearchPeriodOption,
    departmentId?: string,
    categoryId?: string,
    pageNumber: number = 0,
    pageSize: number = 25,
    sortBy: SearchSortBy = SearchSortBy.CREATED,
    sortOrder: SortOrder = SortOrder.DESC,
  ) => {
    return api.get<DataResponse<ItemsResponse<TreatmentItemForAdmin>>>(
      '/treatments',
      {
        params: {
          keywordOption: keywordOption,
          start: start,
          end: end,
          keyword: keyword,
          periodOption: periodOption,
          departmentId: departmentId,
          categoryId: categoryId,
          pageNumber: pageNumber,
          pageSize: pageSize,
          sortBy: sortBy,
          sortOrder: sortOrder,
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    )
  },
  getTreatmentsForSearch: (
    keywordOption: TreatmentSearchKeywordOption,
    keyword?: string,
    pageNumber: number = 0,
    pageSize: number = 25,
    sortBy: SearchSortBy = SearchSortBy.CREATED,
    sortOrder: SortOrder = SortOrder.DESC,
  ) => {
    return api.get<DataResponse<ItemsResponse<TreatmentItemForAdmin>>>(
      '/treatments.search',
      {
        params: {
          keywordOption: keywordOption,
          keyword: keyword,
          pageNumber: pageNumber,
          pageSize: pageSize,
          sortBy: sortBy,
          sortOrder: sortOrder,
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    )
  },
  activateTreatment: (treatmentId: string) => {
    return api.get<ActivateResponse>(`/treatments/${treatmentId}/state.activate`);
  },
  deactivateTreatment: (treatmentId: string) => {
    return api.put<DeactivateResponse>(`/treatments/${treatmentId}/state.deactivate`);
  },
  deleteTreatment: (treatmentId: string) => {
    return api.delete<DeleteResponse>(`/treatments/${treatmentId}`);
  },
  getTreatment: (treatmentId: string, region: string) => {
    return api.get<DataResponse<TreatmentDetails>>(`/treatments/${treatmentId}/${region}`);
  },
  addTreatment: (data: CreateTreatmentRequest) => {
    return api.post<CreateTreatmentReponse>('/treatments', data);
  },
  modifyTreatment: (treatmentId: string, data: ModifyTreatmentRequest, region: string) => {
    return api.put<ModifyResponse>(`/treatments/${treatmentId}/${region}`, data);
  },
  checkBannerTreatment: (treatmentId: string) => {
    return api.get<AssignResponse>(`/treatments/${treatmentId}/register/banner`);
  },
};

export default Treatment;