const theme = {
	primary: '#3F4150',
	white: '#fff',
	red: '#f86d7d',
	blue: '#76bce3;',
	grey: '#7e7e7e',
	placeholder: '#B2B3B9',

	accentLight: '#D4994C',
	accent: '#C99447',
	accentDark: '#CE9448',

	menuItem_bg: '#fafafa',
	submenuItem_bg: '#f5f5f5',
	submenuItemDark_bg: 'f1f1f1',

	inputBorder: '#bdbdbd',
	inputBorderHover: '#a6a2a2',
	inputBorderFocus: '#5c5a5a',

	iconButtonHover_bg: '#f5f7fa',
	iconButtonAcitve_bg: '#e9eff5',

	divider_bg: '#ddd',
	subDivider_bg: '#eee',

	tag_bg: '#F09471',
	tagHover_bg: '#d68565',
	tagDisabled_bg: '#d9d9d9',

	disabled: '#ABABAB',
	disabled_bg: '#D0CFCF',
};

export default theme;
