import { Layout, TablePaginationConfig } from 'antd';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import GNB from '../../layouts/GNB';
import LNB from '../../layouts/LNB';
import { StyledContent, StyledLayout } from './styles';
import SearchBox from '../../components/SearchBox';
import PageHeading from '../../components/PageHeading';
import PaymentHistoryTable from './PaymentHistoryTable';
import moment, { Moment } from 'moment';
import { EventValue, RangeValue } from 'rc-picker/lib/interface';
import { PaymentHistoriesResponse, PaymentHistoryItem, PaymentSearchOptions, SearchPeriod } from '../../model';
import { useApi } from '../../contexts/ApiContext';


const Payments = () => {
  const api = useApi();
  const paymentRef = useRef<any>();
  const dateFormat = 'YYYYMMDD';

  const [displayOption, setDisplayOption] = useState<string>('all');
  const [searchOption, setSearchOption] = useState<string>('all');
  const onChangeSearchOption = useCallback((value: any) => {
    setDisplayOption(value);}, []);
  const [displayKeyword, setDisplayKeyword] = useState<string>('');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const onChangeSearchKeyword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDisplayKeyword(e.target.value);}, []);
  const [displayStartDate, setDisplayStartDate] = useState<EventValue<Moment> | undefined>(moment().subtract(3, 'year'));
  const [displayEndDate, setDisplayEndDate] = useState<EventValue<Moment> | undefined>(moment());
  const [startDate, setStartDate] = useState<EventValue<Moment> | undefined>(moment().subtract(3, 'year'));
  const [endDate, setEndDate] = useState<EventValue<Moment> | undefined>(moment());
  const onChangeDate = (values: RangeValue<Moment>) => {
    setDisplayStartDate(values?.[0]);
    setDisplayEndDate(values?.[1]);
  };

  const [page, setPage] = useState<number>(0);
  const [size] = useState<number>(10);

  const [paymentsHistories, setPaymentsHistories] = useState<PaymentHistoriesResponse<PaymentHistoryItem>>();
  const [paymentItems, setPaymentItems] = useState<PaymentHistoryItem[]>();

  const optionList = [
    { value: PaymentSearchOptions.ALL, text: '전체' },
    { value: PaymentSearchOptions.HOSPITAL, text: '병원명' },
    { value: PaymentSearchOptions.MEMBER, text: '성명' },
    { value: PaymentSearchOptions.PAYMENT, text: '결제/취소번호' }];



  const onSearch = useCallback((e: FormEvent) => {
    e.preventDefault();

    setPage(0);
    setSearchOption(displayOption);
    setSearchKeyword(displayKeyword);
    setStartDate(displayStartDate ?? moment().subtract(6, 'month'));
    setEndDate(displayEndDate ?? moment());
  },[displayOption, displayKeyword, displayStartDate, displayEndDate]);

  const onReset = useCallback(() => {
    setDisplayOption('all');
    setDisplayKeyword('');
    setDisplayStartDate(moment().subtract(3, 'year'))
    setDisplayEndDate(moment())
    paymentRef.current.setPeriod(SearchPeriod.MONTHS);
  }, []);

  const loadPaymentHistories = useCallback( async () => {
    const histories = (await api.payment.getAllPaymentHistories(
        page,
        size,
        searchOption,
        searchKeyword,
        startDate?.format(dateFormat) ?? moment().subtract(3, 'year').format(dateFormat),
        endDate?.format(dateFormat) ?? moment().format(dateFormat),)
    ).data;

    setPaymentsHistories(histories)
  }, [api.payment, page, size, searchOption, searchKeyword, startDate, endDate]);

  const pagination: TablePaginationConfig = {
    position: ['bottomCenter'],
    defaultPageSize: size,
    showSizeChanger: false,
    total: paymentsHistories?.totalElements,
    current: page + 1,
    onChange: (page: number) => {
      setPage(page - 1);
    },
  };

  useEffect(() => {
    loadPaymentHistories()
      .then().catch()
  }, [loadPaymentHistories]);

  useEffect(() => {
    const itemsContainKey = paymentsHistories?.items.map(row => {
      return { key: row.paymentId, ...row };
    });
    if(itemsContainKey) {
      setPaymentItems(itemsContainKey);
    }
  }, [paymentsHistories]);

  return (
    <>
      <Layout>
        <GNB />
        <Layout>
          <LNB defaultMenu={['9']} defaultOpenKeys={['sub1']} />
          <StyledLayout>
            <PageHeading>결제 내역</PageHeading>
            <StyledContent>
              <SearchBox
                ref={paymentRef}
                optionList={optionList}
                optionValue={displayOption}
                onChangeOption={onChangeSearchOption}
                searchValue={displayKeyword}
                onChangeSearch={onChangeSearchKeyword}
                startDate={displayStartDate}
                endDate={displayEndDate}
                onChangeDate={onChangeDate}
                onSubmit={onSearch}
                allowClear
                isReset
                onReset={onReset}
              />
              <PaymentHistoryTable
                paymentHistories={paymentsHistories}
                paymentItems={paymentItems}
                pagination={pagination}
              />
            </StyledContent>
          </StyledLayout>
        </Layout>
      </Layout>
    </>
  );
};

export default Payments;
