import ContentHeading from '../../../../../../components/ContentHeading';
import TableHeading from '../../../../../../components/TableHeading';
import LabelInput from '../../../../../../components/Inputs/LabelInput';
import TableDefault from '../../../../../../components/Tables/TableDefault';
import DefaultImage from '../../../../../../components/DefaultImage';
import InnerTdButton from '../../../../../../components/InnerTdButton';
import UpDownButton from '../../../../../../components/UpDownButton';
import AddImageButton from '../../../../../../components/AddImageButton';
import ImageWithButton from '../../../../../../components/ImageWithButton';
import { Divider } from '../../../../../../components/Divider';
import { treatData } from '../../../../../../mockData';
import {
  Layout,
  Section,
  ImageWrapper,
  LabelImageWrapper,
  FlexWrapper,
  PopupImageWrapper,
  ButtonWrapper,
  ImageTextWrapper,
  HelperText,
  Button,
} from './styles';
import LabelTextarea from '../../../../../../components/Inputs/LabelTextarea';
import { FormEvent, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import {
  BeforeAndAfterTreatmentItem,
  TreatmentAdvantageAndEffectItem,
  TreatmentFaqItem,
  TreatmentProcedureItem,
} from '../../../../../../model';
import Image from '../../../../../../components/Image';
import { DetailImageWrapper } from '../../styles';

interface TreatDetailProps {
  defaultFirstValue: string;
  defaultSecondValue: string;
  defaultThirdValue: string;
  firstExplain?: string;
  onChangeFirstExplain: any;
  secondExplain?: string;
  onChangeSecondExplain: any;
  thirdExplain?: string;
  onChangeThirdExplain: any;
  fourthExplain?: string;
  onChangeFourthExplain: any;
  fifthExplain?: string;
  onChangeFifthExplain: any;
  isEmptyFirstExplain?: boolean;
  advantages?:  TreatmentAdvantageAndEffectItem[];
  advantageColumns: ColumnsType<TreatmentAdvantageAndEffectItem>;
  procedures?:  TreatmentProcedureItem[];
  procedureColumns: ColumnsType<TreatmentProcedureItem>;
  beforeAndAfters?: BeforeAndAfterTreatmentItem[];
  beforeAndAfterColumns: ColumnsType<BeforeAndAfterTreatmentItem>;
  faqs?: TreatmentFaqItem[];
  faqColumns: ColumnsType<TreatmentFaqItem>;
  goPrev: any;
  onClickSubmit: any;
}

const TreatDetail = ({
  defaultFirstValue,
  defaultSecondValue,
  defaultThirdValue,
  firstExplain,
                       onChangeFirstExplain,
  secondExplain,
                       onChangeSecondExplain,
  thirdExplain,
                       onChangeThirdExplain,
  fourthExplain,
                       onChangeFourthExplain,
  fifthExplain,
                       onChangeFifthExplain,
                       advantages,
                       advantageColumns,
                       procedures,
                       procedureColumns,
                       beforeAndAfters,
                       beforeAndAfterColumns,
                       faqs,
                       faqColumns,
  isEmptyFirstExplain,
  goPrev,
                       onClickSubmit
}: TreatDetailProps) => {
  const [isNotEnoughAdvantages, setIsNotEnoughAdvantages] = useState(false);
  const [isNotEnoughProcedures, setIsNotEnoughProcedures] = useState(false);
  const [isNotEnoughFaqs, setIsNotEnoughFaq] = useState(false);

  return (
    <>
      <form onSubmit={onClickSubmit}>
        <Section>
          <TableHeading>시술 추천 대상 설명</TableHeading>
          <LabelInput
            text="시술 설명1"
            value={firstExplain}
            placeholder={defaultFirstValue}
            onChange={onChangeFirstExplain}
            maxLength={200}
            isRequired
          />
          <LabelInput
            text="시술 설명2"
            value={secondExplain}
            placeholder={defaultSecondValue}
            onChange={onChangeSecondExplain}
            maxLength={200}
            isRequired
          />
          <LabelInput
            text="시술 설명3"
            value={thirdExplain}
            placeholder={defaultThirdValue}
            onChange={onChangeThirdExplain}
            maxLength={200}
            isRequired
          />
          <LabelInput
            text="시술 설명4 (선택)"
            value={fourthExplain}
            placeholder="내용을 입력해주세요."
            onChange={onChangeFourthExplain}
            maxLength={200}
          />
          <LabelInput
            text="시술 설명5 (선택)"
            value={fifthExplain}
            placeholder="내용을 입력해주세요."
            onChange={onChangeFifthExplain}
            maxLength={200}
          />
        </Section>
        <Divider />
        <Section>
          <TableHeading hasButton="추가" isButtonDisabled>
            시술 장점 및 효과(선택)
            <small>선택 시 최소 2개, 최대 3개까지 추가 가능합니다.</small>
          </TableHeading>
          <TableDefault columns={advantageColumns} dataSource={advantages} />
          {isNotEnoughAdvantages && <HelperText>필수로 2개 항목을 추가 해야합니다.</HelperText>}
        </Section>
        <Divider />
        <Section>
          <TableHeading hasButton="추가" isButtonDisabled>
            시술 과정(선택)
            <small>선택 시 최소 3개, 최대 8개까지 추가 가능합니다.</small>
          </TableHeading>
          <TableDefault columns={procedureColumns} dataSource={procedures} />
          {isNotEnoughProcedures && <HelperText>필수로 3개 항목을 추가 해야합니다.</HelperText>}
        </Section>
        <Divider />
        <Section>
          <TableHeading hasButton="추가" isButtonDisabled>
            시술 전후(선택)
            <small>최대 10개까지 추가 가능합니다.</small>
          </TableHeading>
          <TableDefault columns={beforeAndAfterColumns} dataSource={beforeAndAfters} />
        </Section>
        <Divider />
        <Section>
          <TableHeading hasButton="추가" isRequired isButtonDisabled>
            자주 묻는 질문
            <small>최소 2개, 최대 6개까지 추가 가능합니다.</small>
          </TableHeading>
          <TableDefault columns={faqColumns} dataSource={faqs} />
          {isNotEnoughFaqs && <HelperText>필수로 2개 항목을 추가 해야합니다.</HelperText>}
        </Section>
        <Divider />
        <ButtonWrapper>
          <Button isBig onClick={goPrev}>
            이전
          </Button>
          <Button isBig isFilled>
            저장
          </Button>
        </ButtonWrapper>
      </form>
    </>
  );
};

export default TreatDetail;