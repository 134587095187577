import styled from 'styled-components';
import { Tabs } from 'antd';
import { buttonBase, flexbox, fontStyle14, fontStyle18, fontStyle24 } from '../../../../styles/mixins';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';

export const BreadWrapper = styled.div`
  /* margin: 0 0 30px 0; */
`;

export const StyledTabs = styled(Tabs)<{ isSubMenu?: boolean }>`
  margin: 0 30px 0 24px;
  color: ${props => props.theme.primary};

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  :not(:disabled).ant-tabs-tab-btn:hover {
    color: ${props => props.theme.accent};
    font-weight: 500;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 35px 0;
  }

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: ${props => props.theme.accent};
  }

  .ant-tabs-tab:hover {
    color: ${props => props.theme.accent};
  }
`;

export const Layout = styled.div<{ isSubMenu?: boolean }>`
  @media (min-width: 821px) {
    margin: 0 30px;
  }

  @media screen and (min-width: 1025px) {
    margin: ${props => (props.isSubMenu ? '0 70px' : '0')};
  }

  h2 {
    font-weight: 700;
    margin-top: -20px;
  }
`;

export const ButtonWrapper = styled.div`
  ${flexbox('center', 'center')};
`;

export const ImageWrapper = styled.div`
  display: flex;
  margin: 0 0 70px 0;
`;

export const StyledLink = styled(Link)`
  ${flexbox('center', 'center')};
  ${fontStyle18};
  padding: 0 15px;
  border-radius: 4px;
  text-align: center;
  width: 260px;
  height: 52px;
  margin: 0 10px 0 0;
  background-color: ${props => props.theme.white};
  border: 1px solid #e3b476;
  color: ${props => props.theme.accent};

  transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out;

  &:hover {
    color: ${props => props.theme.accent};
    border: 1px solid ${props => props.theme.accentLight};
    background-color: ${props => props.theme.menuItem_bg};
  }

  &:active {
    border: 1px solid ${props => props.theme.accentLight};
    background-color: ${props => props.theme.submenuItem_bg};
  }

  &:focus {
    border: 1px solid ${props => props.theme.accent};
    background-color: ${props => props.theme.accentLight};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export const Button = styled.button<{ isBig?: boolean; isFilled?: boolean }>`
  ${buttonBase};
  width: 260px;
  height: 52px;
`;

export const Section = styled.section`
  margin: 0 0 150px 0;

  &:last-of-type {
    margin: 0 0 100px 0;
  }

  > small {
    color: ${props => props.theme.grey};
  }
`;

export const DetailImageWrapper = styled.div`
  .ant-image.ant-image-error:nth-of-type(2) {
    margin: 0 0 0 10px;
  }
`;

export const LabelImageWrapper = styled.div`
  display: flex;
  margin: 0 0 70px 0;
`;

export const FlexWrapper = styled.div`
  ${flexbox};
`;

export const PopupImageWrapper = styled.div`
  margin: 0 0 40px 0;
`;
export const ImageTextWrapper = styled.div`
  > p {
    width: 100px;
    margin: 5px 13px 0;
    color: ${props => props.theme.primary};
    text-align: center;
  }
`;

export const HelperText = styled.p`
  ${fontStyle14};
  font-weight: 500;
  color: ${props => props.theme.red};
  margin: 7px 0 0 0;
`;
