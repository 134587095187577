import styled from 'styled-components';
import { Layout, Radio } from 'antd';
import { buttonBase, flexbox, fontStyle16 } from '../../styles/mixins';

export const StyledLayout = styled(Layout)`
  margin: 64px 0 0 260px; // for fixed gnb and lnb
  padding: 0 30px 24px;

  @media screen and (max-width: 1535px) {
    margin: 64px 0 0 150px;
  }
`;

export const StyledContent = styled(Layout.Content)`
  padding: 24px;
  margin: 30px auto 0;
  min-height: 280px;
  max-width: 1230px;
`;

export const Section = styled.div`
  margin: 0 0 150px 0;

  &:last-of-type {
    margin: 0 0 100px 0;
  }
`;

export const Wrapper = styled.div`
  ${flexbox('flex-start', 'center')};
`;

export const TableWrapper = styled.div`
  width: 500px;
  min-height: 850px;
  height: 850px;
  border-radius: 16px;
  padding: 32px;
  background-color: ${props => props.theme.submenuItem_bg};
  overflow: auto;

  &:first-of-type {
    margin: 0 60px 0 0;
  }

  table,
  tbody {
    width: 100%;
  }

  tr {
    ${flexbox('space-between', 'center')};
    height: 70px;
    width: 100%;
    background-color: ${props => props.theme.white};
    border-radius: 8px;
    padding: 16px;
    margin: 0 0 15px 0;
    border: 1px solid #ffffff;
  }

  /* 선택된 경우 */
  tr.selected {
    border: 1px solid #000000;
  }

  td {
    color: ${props => props.theme.primary};
  }
  /* 진료과 미사용 */
  tr.unused {
    background-color: rgba(0, 0, 0, 0.1);
    td {
      color: ${props => props.theme.grey};
    }

    td:first-of-type {
      button {
        pointer-events: none;
      }
    }

    button {
      opacity: 0.6;
    }
  }
`;

export const StyledRadio = styled(Radio)`
  ${fontStyle16};
  color: ${props => props.theme.primary};
  margin: 0 30px 50px 0;

  &:first-of-type {
    margin: 0 30px 50px 130px;
  }

  .ant-radio {
    color: ${props => props.theme.primary};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${props => props.theme.accent};
  }

  .ant-radio-input:active + .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: unset;
  }

  &.ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${props => props.theme.accent};
  }

  .ant-radio-inner::after {
    background-color: ${props => props.theme.accent};
  }
`;

export const Button = styled.button<{ isFilled?: boolean; isBig?: boolean }>`
  ${buttonBase};
  width: 260px;
  height: 52px;
  margin: 100px auto 0;
`;
