import { Divider } from 'antd';
import AddImageButton from '../../../../../components/AddImageButton';
import ContentHeading from '../../../../../components/ContentHeading';
import Label from '../../../../../components/Inputs/Label';
import LabelInput from '../../../../../components/Inputs/LabelInput';
import LabelSelect from '../../../../../components/Inputs/LabelSelect';
import LabelText from '../../../../../components/Inputs/LabelText';
import LabelToggle from '../../../../../components/Inputs/LabelToggle';
import TableHeading from '../../../../../components/TableHeading';
import TableDefault from '../../../../../components/Tables/TableDefault';
import { ImageWrapper, Button, ButtonWrapper, Section } from './styles';
import { ColumnsType } from 'antd/lib/table/interface';
import { DetailItemImage, DetailItemTreatment } from '../../../../../model';
import UpDownButton from '../../../../../components/UpDownButton';
import InnerTdButton from '../../../../../components/InnerTdButton';
import { ChangeEventHandler } from 'react';

interface DoctorEditTranslateProps {
	display?: boolean;
	hospitalName?: any;
	defaultName?: string;
	position?: string;
	defaultIntro?: string;
	representative?: string;
	treats?: any;
	children?: JSX.Element;
	doctorColumns?: ColumnsType<DetailItemImage>;
	images?: any;
	onChangeName: ChangeEventHandler<HTMLInputElement>;
	onChangeIntro: ChangeEventHandler<HTMLInputElement>;
	showAddDoctorPopup: any;
}

const DoctorEditTranslate = ({onChangeName, onChangeIntro, showAddDoctorPopup,
														 display, hospitalName, defaultName, position, defaultIntro, representative, treats,
														 children, doctorColumns, images }: DoctorEditTranslateProps) => {
	const treatColumns: ColumnsType<DetailItemTreatment> = [
		{
			title: '순서 변경',
			dataIndex: 'change',
			key: 'change',
			width: 220,
			render: (value: any, record: DetailItemTreatment, index: number) => {
				return (
					<div>
						<UpDownButton isDisabled />
						<UpDownButton isDisabled isDown />
					</div>
				);
			},
		},
		{
			title: '노출 순서',
			dataIndex: 'displayOrder',
			key: 'displayOrder',
			width: 120,
		},
		{ title: '시술명', dataIndex: 'treatmentName', key: 'treatmentName' },
		{
			title: '관리',
			dataIndex: 'edit',
			key: 'edit',
			render: (value: any, record: DetailItemTreatment, index: number) => <InnerTdButton isDisabled />,
		},
	];
	return (
		<>
			<Section>
				<ContentHeading isRequired>의료진 기본 정보</ContentHeading>
				<LabelToggle text="노출" checked={display} disabled />
				<LabelText text="병원명">{hospitalName}</LabelText>
				<LabelInput text="의사 성명" placeholder={defaultName}
										value={defaultName} onChange={onChangeName}
										maxLength={200} isRequired />
				<LabelSelect text="직위" value={position} optionList={[]} isRequired disabled />
				<LabelInput text="한줄 소개" placeholder={defaultIntro}
										value={defaultIntro} onChange={onChangeIntro}
										isRequired maxLength={200} />
				<ImageWrapper>
					<Label text="대표 사진" isRequired />
					<AddImageButton url={representative} isDisabled />
				</ImageWrapper>
			</Section>
			<Divider />
			<Section>
				<ContentHeading>의료진 소개</ContentHeading>
				<TableHeading hasButton="추가" onClick={showAddDoctorPopup}>
					소개 사진(선택)
				</TableHeading>
				<TableDefault dataSource={images} columns={doctorColumns} />
			</Section>
			<Divider />
			<Section>
				<TableHeading>진료 시술(선택)</TableHeading>
				<TableDefault columns={treatColumns} dataSource={treats} />
			</Section>
			<Divider />
			<ButtonWrapper>
				<Button type="button" isBig>
					취소
				</Button>
				<Button type="submit" isFilled isBig>
					저장
				</Button>
			</ButtonWrapper>
		</>
	);
};

export default DoctorEditTranslate;
