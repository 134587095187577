import React, { ChangeEventHandler } from 'react';
import Label from '../Label';
import { FlexWrapper, TextareaWrapper, Textarea, HelperText, MaxLength } from './styles';

interface LabelTextAreaProps {
	isRequired?: boolean;
	text: string;
	placeholder?: string;
	value?: any;
	onChange?: ChangeEventHandler;
	maxLength?: number;
	isRequiredEmpty?: boolean;
	isPopup?: boolean;
}

const LabelTextarea = ({ isRequired, text, placeholder, value, onChange, maxLength, isRequiredEmpty, isPopup }: LabelTextAreaProps) => {
	return (
		<FlexWrapper>
			<Label text={text} isRequired={isRequired} isPopup={isPopup} />
			<TextareaWrapper>
				<Textarea placeholder={placeholder} value={value} onChange={onChange} isPopup={isPopup} maxLength={maxLength} isRequiredEmpty={isRequiredEmpty} />
				{isRequiredEmpty && <HelperText>필수 입력 값입니다.</HelperText>}
				{maxLength && (
					<MaxLength isPopup={isPopup}>
						{value?.length} / <span>{maxLength}자 </span>
					</MaxLength>
				)}
			</TextareaWrapper>
		</FlexWrapper>
	);
};

export default React.memo(LabelTextarea);
