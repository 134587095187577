import { Layout, Tabs } from 'antd';
import { ChangeEvent, FormEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import GNB from '../../layouts/GNB';
import LNB from '../../layouts/LNB';
import PageHeading from '../../components/PageHeading';
import TableHeading from '../../components/TableHeading';
import LabelInput from '../../components/Inputs/LabelInput';
import Label from '../../components/Inputs/Label';
import UpDownButton from '../../components/UpDownButton';
import InnerTdButton from '../../components/InnerTdButton';
import TableDefault from '../../components/Tables/TableDefault';
import DefaultPopup from '../../components/Popup/DefaultPopup';
import SelectInput from '../../components/Inputs/SelectInput';
import TableSort from '../../components/Tables/TableSort';
import ConfirmPopup from '../../components/Popup/ConfirmPopup';
import { Divider } from '../../components/Divider';
import { StyledLayout, StyledContent, Section, FlexWrapper, SelectInputWrapper, Button, SmallButton, PopupButton } from './styles';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useApi } from '../../contexts/ApiContext';
import { AssignSearchContentsRequest, ItemsResponse, ShiftDirection, TreatmentItem, TreatmentItemForAdmin, TreatmentSearchKeywordOption } from '../../model';
import { ItemDefault } from '../../components/Inputs/Select';
import moment from 'moment';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useNavigate } from 'react-router-dom';
import { StyledTabs } from '../Home/Tabs/styles';
import SearchForm from './SerachForm';

const { TabPane } = Tabs;

const popupText = {
	delete: <>삭제 하시겠습니까?</>,
	unsave: (
		<>
			저장되지 않은 변경 내용이 있습니다. <br />
			저장하지 않고 나가려면 확인을 눌러주세요.
		</>
	),
	validation: <>필수 항목을 다시 확인해 주세요.</>,
	save: (
		<>
			입력한 내용을 저장할까요?
			<br />
			확인을 누르면 앱에 바로 반영됩니다.
		</>
	),
	unchanged: <>변경 사항이 없습니다.</>,
};

const Search = () => {
	const api = useApi();
	const navigate = useNavigate();

	const [region, setRegion] = useState<string>('ko');
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [isNotModified, setIsNotModified] = useState<boolean>(true);
	const [changedRegion, setChangedRegion] = useState<boolean>(false);

	// 전체 시술 목록
	const [treatmentsData, setTreatmentsData] = useState<ItemsResponse<TreatmentItemForAdmin>>();
	const [treatments, setTreatments] = useState<TreatmentItemForAdmin[]>([]);

	// 선택된 시술
	const [selectedTreatment, setSelectedTreatment] = useState<TreatmentItemForAdmin[]>([]);

	// 전체 시술 페이징
	const [treatSize] = useState<number>(10);
	const [treatPage, setTreatPage] = useState<number>(0);

	// 시술 등록 검색
	const [optionList] = useState<ItemDefault[]>([
		{ value: TreatmentSearchKeywordOption.ALL, text: '전체' },
		{ value: TreatmentSearchKeywordOption.HOSPITAL, text: '병원명' },
		{ value: TreatmentSearchKeywordOption.TREATMENT, text: '시술명' },
	]);
	const [keywordOption, setKeywordOption] = useState<TreatmentSearchKeywordOption>(TreatmentSearchKeywordOption.ALL);
	const onChangeKeywordOption = useCallback((value: string) => setKeywordOption(value as TreatmentSearchKeywordOption), []);
	const [keyword, setKeyword] = useState<string>('');
	const onChangeKeyword = useCallback((e: ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value), []);

	// 실제 검색에 사용됨
	const [searchKeywordOption, setSearchKeywordOption] = useState<TreatmentSearchKeywordOption>(TreatmentSearchKeywordOption.ALL);
	const [searchKeyword, setSearchKeyword] = useState<string>('');

	// 인기 검색어
	const [populars, setPopulars] = useState<string[]>([]);
	const [isEmptyPopulars, setIsEmptyPopulars] = useState<boolean[]>([false, false, false, false, false, false]);
	const onChangePopular = useCallback((e: ChangeEvent<HTMLInputElement>, index: number) => {
		setPopulars((prev) => {
			const copy = [...prev];
			copy[index] = e.target.value;

			return copy;
		});
	}, []);

	// 추천 시술 제목
	const [title, setTitle] = useState<string>('');
	const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);

	// 추천 시술 목록
	const [treats, setTreats] = useState<TreatmentItem[]>([]);

	// 팝업 관리
	// Confirm Popup
	const [popupContents, setPopupContents] = useState<string | ReactNode>();
	const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => {});
	const [popupHandleCancel, setPopupHandleCancel] = useState<VoidFunction | undefined>(() => () => {});
	const [isWithCancel, setIsWithCancel] = useState(false);
	const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState(false);

	// 전체 시술 목록 팝업
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const onClickAdd = () => setIsPopupVisible(true);

	// 검색 관리 데이터 조회 API
	const getSearchContents = useCallback(async () => {
		const data = (await api.search.getSearchContents(region)).data;

		if (data.searchTerms.length === 6) {
			setPopulars(data.searchTerms);
		} else {
			setPopulars(['', '', '', '', '', '']);
		}
		setTitle((prev) => data.recommendedTreatment?.title ?? prev);
		setTreats((prev) => data.recommendedTreatment?.treatmentItems ?? prev);
		setIsLoaded(true);
	}, [api.search, region]);

	// 전체 시술 목록 api
	const getAllTreatments = useCallback(async () => {
		const data = (await api.treatment.getTreatmentsForSearch(searchKeywordOption, searchKeyword, treatPage, treatSize)).data;
		setTreatmentsData(data.data);
	}, [api.treatment, searchKeywordOption, searchKeyword, treatPage, treatSize]);

	// 불러온 전체 시술 목록 가공
	const getTreatments = useCallback(() => {
		const data = treatmentsData?.items.map((row) => {
			return { key: row.treatmentId, ...row };
		});

		if (data) {
			setTreatments(data);
		}
	}, [treatmentsData]);

	const onRegionChange = useCallback((key: any) => {
		if(key === "1") {
			setRegion('ko');
		}
		if(key === "2") {
			setRegion('en');
		}
		if(key === "3") {
			setRegion('zh');
		}
		setChangedRegion(true)
	}, []);

	// 팝업 설정
	const setPopupData = (contents: JSX.Element, isWithCancel: boolean, handleOk?: VoidFunction, handleCancel?: VoidFunction) => {
		setIsWithCancel(isWithCancel);
		setPopupContents(contents);
		setPopupHandleOk(handleOk);
		setPopupHandleCancel(handleCancel);
	};

	// 전체 시술 검색
	const onSearch = useCallback(
		(e: FormEvent) => {
			e.preventDefault();
			setTreatPage(0);
			setSearchKeyword(keyword);
			setSearchKeywordOption(keywordOption);
		},
		[keyword, keywordOption]
	);

	// 추천 시술 저장
	const onSaveRecommendedTreatments = useCallback(() => {
		const recommended: TreatmentItem[] = selectedTreatment.map((row, index) => ({
			key: row.treatmentId,
			treatmentId: row.treatmentId,
			treatmentName: row.name,
			displayOrder: treats.length + index + 1,
		}));

		setTreats((prev) => [...prev, ...recommended]);
	}, [selectedTreatment, treats.length]);

	// 추천 시술 삭제
	const onDeleteTreats = useCallback((index: number) => {
		setTreats((prev) => {
			if (index > -1) {
				let copy = [...prev];
				copy.splice(index, 1);
				copy = copy.map((row, index) => ({ ...row, displayOrder: index + 1 }));

				return copy;
			}

			return prev;
		});
	}, []);

	// 추천 시술 삭제 버튼
	const onClickDeleteTreats = useCallback(
		(index: number) => {
			setPopupData(popupText.delete, true, () => () => onDeleteTreats(index));
			setIsConfirmPopupVisible(true);
		},
		[onDeleteTreats]
	);

	// 진료 시술 순서 변경
	const swapTreatsOrder = useCallback(
		async (target: TreatmentItem, index: number, direction: ShiftDirection) => {
			let swapTarget;
			let swapIndex;
			if (treats.length >= 2) {
				switch (direction) {
					case ShiftDirection.FORWARD: {
						swapIndex = index - 1;
						swapTarget = treats.at(swapIndex);
						break;
					}
					case ShiftDirection.BACKWARD: {
						swapIndex = index + 1;
						swapTarget = treats.at(swapIndex);
						break;
					}
				}

				if (swapTarget) {
					const key = swapTarget.key;
					const order = swapTarget.displayOrder;

					swapTarget.key = target.key;
					swapTarget.displayOrder = target.displayOrder;
					target.key = key;
					target.displayOrder = order;

					const copy = [...treats];
					copy[swapIndex] = swapTarget;
					copy[index] = target;
					copy.sort((a, b) => a.displayOrder - b.displayOrder);

					setTreats(copy);
				}
			}
		},
		[treats]
	);

	// 유효성 체크
	const checkValidation = useCallback(() => {
		let validation = true;
		let isEmpty = [...isEmptyPopulars];
		populars.forEach((value, index) => {
			if (!value) {
				isEmpty[index] = true;
				validation = false;
			} else {
				isEmpty[index] = false;
			}
		});

		if (validation) {
			isEmpty = isEmpty.fill(false);
		} else {
			setPopupData(popupText.validation, false);
			setIsConfirmPopupVisible(true);
		}

		setIsEmptyPopulars(isEmpty);

		return validation;
	}, [isEmptyPopulars, populars]);

	// 저장
	const onSubmit = useCallback(async () => {
		const submitData: AssignSearchContentsRequest = {
			searchTerms: populars,
			recommendedTreatment: {
				title: title,
				treatmentItems: treats,
			},
		};

		const data = (await api.search.modifySearchContents(submitData, region)).data;
		if (data.assigned) {
			navigate(0);
		}
	}, [api.search, navigate, populars, title, treats, region]);

	// 저장 클릭
	const onClickSubmit = useCallback((e: FormEvent) => {
			e.preventDefault();

			if(isNotModified) {
				setPopupData(popupText.unchanged, false);
				setIsConfirmPopupVisible(true);
			} else if (checkValidation()) {
				setPopupData(popupText.save, true, () => () => onSubmit());
				setIsConfirmPopupVisible(true);
			}
		},[checkValidation, isNotModified, onSubmit]);

	// 추천 시술 목록 팝업
	const columns: ColumnsType<TreatmentItem> = [
		{
			title: '순서 변경',
			dataIndex: 'change',
			key: 'change',
			width: 220,
			render: (value, record, index) => {
				return (
					<>
						<UpDownButton
							onClick={() => {
								if (index > 0) {
									swapTreatsOrder(record, index, ShiftDirection.FORWARD);
								}
							}}
						/>
						<UpDownButton
							onClick={() => {
								if (index < treats.length - 1) {
									swapTreatsOrder(record, index, ShiftDirection.BACKWARD);
								}
							}}
							isDown
						/>
					</>
				);
			},
		},
		{
			title: '노출 순서',
			dataIndex: 'displayOrder',
			key: 'displayOrder',
			width: 120,
		},
		{
			title: '시술명',
			dataIndex: 'treatmentName',
			key: 'treatmentName',
		},
		{
			title: '관리',
			dataIndex: 'edit',
			key: 'edit',
			render: (value, record, index) => {
				return <InnerTdButton onClickDelete={() => onClickDeleteTreats(index)} />;
			},
		},
	];

	// 전체 시술 목록 팝업
	const popupColumns: ColumnsType<TreatmentItemForAdmin> = [
		{
			title: '등록일',
			dataIndex: 'createdAt',
			key: 'createdAt',
			sorter: (a, b) => {
				const aDate = moment(a.createdAt);
				const bDate = moment(b.createdAt);

				return bDate.diff(aDate);
			},
		},
		{ title: '병원명', dataIndex: 'hospitalName', key: 'hospitalName' },
		{ title: '시술명', dataIndex: 'name', key: 'name' },
	];

	// 전체 시술 목록 페이징
	const popupPagination: TablePaginationConfig = {
		position: ['bottomCenter'],
		defaultPageSize: treatSize,
		showSizeChanger: false,
		total: treatmentsData?.totalCount,
		current: treatPage + 1,
		onChange: (page: number, pageSize: number) => {
			setTreatPage(page - 1);
		},
	};

	// 전체 시술 체크 박스
	const rowSelection: TableRowSelection<TreatmentItemForAdmin> = useMemo(
		() => ({
			onChange: (keys, rows) => {
				setSelectedTreatment(rows);
			},
			getCheckboxProps: (record) => {
				if (treats.find((row) => row.treatmentId === record.treatmentId)) {
					return {
						disabled: true,
					};
				} else {
					return {
						disabled: false,
					};
				}
			},
		}),
		[treats]
	);

	// 전체 시술 목록 API 호출
	useEffect(() => {
		getAllTreatments();
	}, [getAllTreatments]);

	// 기존 검색 관리 데이터
	useEffect(() => {
		getSearchContents();
	}, [getSearchContents]);

	// API 호출로 불러온 전체 시술 목록 가공
	useEffect(() => {
		getTreatments();
	}, [getTreatments]);

	useEffect(() => {
		if(changedRegion) {
			return () => {
				setIsNotModified(true)
				setChangedRegion(false)
				setIsLoaded(false)
			}
		} else if(isLoaded) {
			return () => {
				setIsNotModified(false);
			}
		}
	}, [isLoaded, populars, title, treats, changedRegion])

	return (
		<>
			<Layout>
				<GNB />
				<Layout>
					<LNB defaultMenu={['5']} />
					<StyledLayout>
						<PageHeading>검색 관리</PageHeading>
						<StyledContent>
							<StyledTabs type="card" defaultActiveKey="1" onTabClick={(key) => onRegionChange(key)}>
								<TabPane tab="한국어" key="1">
									<form onSubmit={onClickSubmit}>
										<Section>
											<TableHeading isRequired>
												인기 검색어
												<small>6개의 검색어를 모두 입력해주세요.</small>
											</TableHeading>
											<LabelInput value={populars[0]} onChange={(e) => onChangePopular(e, 0)} isRequiredEmpty={isEmptyPopulars[0]} text="검색어1" placeholder="내용을 입력해주세요." isRequired />
											<LabelInput value={populars[1]} onChange={(e) => onChangePopular(e, 1)} isRequiredEmpty={isEmptyPopulars[1]} text="검색어2" placeholder="내용을 입력해주세요." isRequired />
											<LabelInput value={populars[2]} onChange={(e) => onChangePopular(e, 2)} isRequiredEmpty={isEmptyPopulars[2]} text="검색어3" placeholder="내용을 입력해주세요." isRequired />
											<LabelInput value={populars[3]} onChange={(e) => onChangePopular(e, 3)} isRequiredEmpty={isEmptyPopulars[3]} text="검색어4" placeholder="내용을 입력해주세요." isRequired />
											<LabelInput value={populars[4]} onChange={(e) => onChangePopular(e, 4)} isRequiredEmpty={isEmptyPopulars[4]} text="검색어5" placeholder="내용을 입력해주세요." isRequired />
											<LabelInput value={populars[5]} onChange={(e) => onChangePopular(e, 5)} isRequiredEmpty={isEmptyPopulars[5]} text="검색어6" placeholder="내용을 입력해주세요." isRequired />
										</Section>
										<Divider />
										<Section>
											<TableHeading>추천 시술</TableHeading>
											<LabelInput value={title} onChange={onChangeTitle}
																	text="추천 시술 제목" placeholder="제목을 입력해주세요." maxLength={200} />
											<FlexWrapper>
												<Label text="시술 등록" />
												<SmallButton type="button" onClick={onClickAdd} isMiddle isFilled>
													컨텐츠 등록
												</SmallButton>
											</FlexWrapper>
											<TableDefault dataSource={treats} columns={columns} />
										</Section>
										<Button type="submit" isFilled isBig>
											저장
										</Button>
									</form>
								</TabPane>
								<TabPane tab="영어" key="2">
									<SearchForm values={[populars[0], populars[1], populars[2], populars[3], populars[4], populars[5]]}
															columns={columns}
															recommend={title}
															treats={treats}
															onChangeTitle={onChangeTitle}
															onClickAdd={onClickAdd}
															onChangePopular={onChangePopular}
															onClickSubmit={onClickSubmit}/>
								</TabPane>
								<TabPane tab="중국어" key="3">
									<SearchForm values={[populars[0], populars[1], populars[2], populars[3], populars[4], populars[5]]}
															columns={columns}
															recommend={title}
															treats={treats}
															onChangeTitle={onChangeTitle}
															onClickAdd={onClickAdd}
															onChangePopular={onChangePopular}
															onClickSubmit={onClickSubmit}/>
								</TabPane>
							</StyledTabs>
						</StyledContent>
					</StyledLayout>
				</Layout>
			</Layout>

			{isPopupVisible && (
				<DefaultPopup title="컨텐츠 등록" onClickOk={onSaveRecommendedTreatments} isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} isWithCancel isWithSave>
					<form onSubmit={onSearch}>
						<SelectInputWrapper>
							<SelectInput value={keyword} onChange={onChangeKeyword} onChangeSelect={onChangeKeywordOption} optionList={optionList} placeholder="검색어를 입력하세요." />
							<PopupButton isSmall isFilled>
								검색
							</PopupButton>
						</SelectInputWrapper>
					</form>
					<TableSort data={treatments} columns={popupColumns} rowSelection={rowSelection} pagination={popupPagination} scroll={{ y: 275 }} />
				</DefaultPopup>
			)}

			{isConfirmPopupVisible && (
				<ConfirmPopup isWithCancel={isWithCancel} handleOk={popupHandleOk} handleCancel={popupHandleCancel} isPopupVisible={isConfirmPopupVisible} setIsPopupVisible={setIsConfirmPopupVisible}>
					{popupContents}
				</ConfirmPopup>
			)}
		</>
	);
};

export default Search;
