import { Tabs } from "antd";
import { StyledTabs } from "./styles";
import Banner from "../Banner";
import RecHospital from "../RecHospital";
import RecDoctor from "../RecDoctor";
import NewHospital from "../NewHospital";
import Premium from "../Premium";

import { HomeDepartmentItem } from '../../../model';

interface Props {
	tabs?: HomeDepartmentItem[]
}

const Tab = (props: Props) => {
	const { TabPane } = Tabs;

	function callback(key: any) {
		console.log(key);
	}

	return (
		<StyledTabs defaultActiveKey="1" type="card" onChange={callback}>
			<TabPane tab="오늘의 Pick" key="1">
				<Banner />
				<RecHospital />
				<RecDoctor />
				<NewHospital />
				<Premium />
			</TabPane>
			{props.tabs?.map((item) => {
				return (
					<TabPane tab={item.name} key={item.id}>
						<RecHospital department={item} />
						<RecDoctor department={item} />
						<NewHospital department={item} />
					</TabPane>
				);
			})}
		</StyledTabs>
	);
};

export default Tab;
