import styled from "styled-components";
import { Layout } from "antd";
import { buttonBase, flexbox } from "../../styles/mixins";

export const StyledLayout = styled(Layout)`
	margin: 64px 0 0 260px; // for fixed gnb and lnb
	padding: 0 30px 24px;

	@media screen and (max-width: 1535px) {
		margin: 64px 0 0 150px;
	}
`;

export const StyledContent = styled(Layout.Content)`
	padding: 24px;
	margin: 0;
	min-height: 280px;
`;

export const ButtonWrapper = styled.div`
	${flexbox("center", "center")};
`;

export const Button = styled.button<{isFilled?: boolean; isBig?: boolean}>`
	${buttonBase};
	width: 260px;
	height: 52px;
`;
