import { ChangeEvent, ReactNode, FormEvent, useCallback, useEffect, useState } from 'react';
import TableHeading from '../../../components/TableHeading';
import TableDefault from '../../../components/Tables/TableDefault';
import UpDownButton from '../../../components/UpDownButton';
import LabelInput from '../../../components/Inputs/LabelInput';
import LabelToggle from '../../../components/Inputs/LabelToggle';
import Label from '../../../components/Inputs/Label';
import DefaultPopup from '../../../components/Popup/DefaultPopup';
import AddImageButton from '../../../components/AddImageButton';
import InnerTdButton from '../../../components/InnerTdButton';
import ConfirmPopup from '../../../components/Popup/ConfirmPopup';
import LabelInputTranslate from '../../../components/Inputs/LabelInputTranslate';
import { Section, FlexWrapper, ImageWrapper, Button, SaveButton } from './styles';
import TabInterface from '../TabInterface';
import { PartnersHospitalListItem, PartnersHospitalRequest, PremiumCertificationCounter, ShiftDirection } from '../../../model';
import { useApi } from '../../../contexts/ApiContext';
import { fileToDataUri } from '../../../helpers/FileHelper';
import Image from '../../../components/Image';
import { ColumnsType } from 'antd/lib/table';

const popupText = {
	save: (
		<>
			변경한 내용을 저장할까요? <br />
			확인을 누르면 앱에 바로 반영됩니다.
		</>
	),
};

const Premium = (props: TabInterface) => {
	const api = useApi();

	const [popupContents, setPopupContents] = useState<string | ReactNode>();
	const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => {});
	const [popupHandleCancel, setPopupHandleCancel] = useState<VoidFunction | undefined>(() => () => {});
	const [isWithCancel, setIsWithCancel] = useState(false);

	const [counter, setCounter] = useState<PremiumCertificationCounter>();

	// 프리미엄 병원, 의사, 실제 데이터 노출 여부
	const [hospitalCount, setHospitalCount] = useState<number>(0);
	const [doctorCount, setDoctorCount] = useState<number>(0);
	const [isDisplayRealData, setIsDisplayRealData] = useState<boolean>(false);

	// 파트너 병원사
	const [hospitals, setHospitals] = useState<PartnersHospitalListItem[]>([]);

	// 밸리데이션 체크
	const [isImageEmpty, setIsImageEmpty] = useState<boolean>(false);
	const [isNameEmpty, setIsNameEmpty] = useState<boolean>(false);

	// 업로드할 이미지 파일, 병원명
	const [imageUrl, setImageUrl] = useState<string>();
	const [file, setFile] = useState<File>();
	const [hospitalName, setHospitalName] = useState<string>('');
	const [hospitalNameEn, setHospitalNameEn] = useState<string>('');
	const [hospitalNameZh, setHospitalNameZh] = useState<string>('');

	// 파트너사 편집 선택
	const [selectedHospital, setSelectedHospital] = useState<PartnersHospitalListItem>();

	// 파트너사 삭제 아이디
	const [deleteId, setDeleteId] = useState<string>('');

	// 프리미엄 카운터 및 실제 노출 여부
	const getCounter = useCallback(async () => {
		const data = (await api.home.getPremiumCertificationCount()).data;
		setCounter(data.counter);
	}, [api.home]);

	// 파트너 병원사
	const getPartnersHospitals = useCallback(async () => {
		const data = (await api.home.getPartnesrHospitals()).data;
		setHospitals(data.items.map((row) => ({ key: row.partnersHospitalId, ...row })));
	}, [api.home]);

	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [isSubmitCountPopupVisible, setIsSubmitCountPopupVisible] = useState(false);
	const showPopup = () => {
		setSelectedHospital(undefined);

		setHospitalName('');
		setHospitalNameEn('');
		setHospitalNameZh('');
		setImageUrl(undefined);
		setFile(undefined);

		setIsImageEmpty(false);
		setIsNameEmpty(false);

		setIsPopupVisible(true);
	};

	// 팝업에서 필수 입력 값을 넣지 않고 '저장'버튼을 누르는 경우 보여주는 팝업
	const [isConfirmVisible, setIsConfirmVisible] = useState(false);

	const [isDeleteVisible, setIsDeleteVisible] = useState(false);
	const onClickDelete = (hospitalId: string) => {
		setDeleteId(hospitalId);
		setIsDeleteVisible(true);
	};

	// 순서 변경
	const swapOrder = useCallback(
		async (hospitalId: string, direction: ShiftDirection) => {
			const data = (await api.home.swapPartnersHospitalOrder(hospitalId, direction)).data;

			if (data.modified) {
				await getPartnersHospitals();
			}
		},
		[api.home, getPartnersHospitals]
	);

	// 파트너 병원 편집
	const onClickEdit = useCallback((item: PartnersHospitalListItem) => {
		setSelectedHospital(item);

		setHospitalName(item.hospitalName);
		setHospitalNameEn(item.hospitalNameEn);
		setHospitalNameZh(item.hospitalNameZh);
		setImageUrl(item.imageUrl);

		setIsImageEmpty(false);
		setIsNameEmpty(false);

		setIsPopupVisible(true);
	}, []);

	// 파트너 병원 삭제
	const onDelete = useCallback(async () => {
		if (deleteId) {
			const data = (await api.home.deletePartnersHospital(deleteId)).data;

			if (data.removed) {
				getPartnersHospitals();
			}

			setIsDeleteVisible(false);
			setDeleteId('');
		}
	}, [api.home, deleteId, getPartnersHospitals]);

	// 병원 카운트 onChange
	const onHospitalChange = (e: ChangeEvent<HTMLInputElement>) => {
		const change = Number(e.target.value);
		setHospitalCount(isNaN(change) ? 0 : change);
	};

	// 의사 카운트 onChange
	const onDoctorChange = (e: ChangeEvent<HTMLInputElement>) => {
		const change = Number(e.target.value);
		setDoctorCount(isNaN(change) ? 0 : change);
	};

	const setPopupData = (contents: JSX.Element, isWithCancel: boolean, handleOk?: VoidFunction, handleCancel?: VoidFunction) => {
		setIsWithCancel(isWithCancel);
		setPopupContents(contents);
		setPopupHandleOk(handleOk);
		setPopupHandleCancel(handleCancel);
	};

	// 카운트 수정
	const onSubmit = useCallback(async () => {
		const data = (
			await api.home.modifyPremiumCertificationCount({
				totalPartnerDoctors: doctorCount,
				totalPartnerHospitals: hospitalCount,
				displayRealData: isDisplayRealData,
			})
		).data;

		if (data.assigned) {
			getCounter();
		}
	}, [api.home, doctorCount, getCounter, hospitalCount, isDisplayRealData]);

	const onSubmitCount = useCallback(
		async (e: FormEvent) => {
			e.preventDefault();
			setPopupData(popupText.save, true, () => () => onSubmit());
			setIsSubmitCountPopupVisible(true);
		},
		[onSubmit]
	);

	// 밸리데이션 체크
	const checkValidation = useCallback((): boolean => {
		let validation = true;
		if (!imageUrl && !file) {
			setIsImageEmpty(true);
			validation = false;
		} else {
			setIsImageEmpty(false);
		}

		if (!hospitalName) {
			setIsNameEmpty(true);
			validation = false;
		} else {
			setIsNameEmpty(false);
		}

		if (validation) {
			setIsImageEmpty(false);
			setIsNameEmpty(false);
		} else {
			setIsConfirmVisible(true);
		}

		return validation;
	}, [file, hospitalName, imageUrl]);

	// 병원 추가 팝업 저장
	const onSubmitHospital = useCallback(async () => {
		if (checkValidation()) {
			let imageFileUrl: string = imageUrl ?? '';

			if (file) {
				const fileReader = await fileToDataUri(file);
				if (fileReader) {
					const data = (await api.file.getPresignedUrl(file.name)).data;
					await api.file.uploadFile(data.signedUrl, file);

					imageFileUrl = data.uploadTargetUrl;
				}
			}

			const submitData: PartnersHospitalRequest = {
				hospitalName: hospitalName,
				hospitalNameEn: hospitalNameEn,
				hospitalNameZh: hospitalNameZh,
				imageUrl: imageFileUrl,
			};

			if (selectedHospital !== undefined) {
				// 편집
				const data = (await api.home.modifyPartnersHospital(selectedHospital.partnersHospitalId, submitData)).data;

				if (data.modified) {
					await getPartnersHospitals();
					setIsPopupVisible(false);
				}
			} else {
				// 추가
				const data = (await api.home.addPartnersHospital(submitData)).data;

				if (data.created) {
					await getPartnersHospitals();
					setIsPopupVisible(false);
				}
			}
		}
	}, [api.file, api.home, checkValidation, file, getPartnersHospitals, hospitalName, hospitalNameEn, hospitalNameZh, imageUrl, selectedHospital]);

	const onOpenHospitalPopup = useCallback(
		async (e: FormEvent) => {
			e.preventDefault();
		},
		[onSubmitHospital]
	);

	const onOpenConfirmHospital = useCallback(() => {
		if (checkValidation()) {
			setPopupData(popupText.save, true, () => () => onSubmitHospital());
			setIsSubmitCountPopupVisible(true);
		}
	}, [checkValidation, onSubmitHospital]);

	// 병원 추가 팝업 취소
	const onClickCancel = () => {
		setIsPopupVisible(false);
	};

	// 병원 이미지 변경
	const onChangeFile = (file: File | undefined) => {
		setImageUrl(undefined);
		setFile(file);
	};

	const columns: ColumnsType<PartnersHospitalListItem> = [
		{
			title: '순서 변경',
			dataIndex: 'change',
			key: 'change',
			render: (value, record, index) => {
				return (
					<>
						<UpDownButton
							onClick={() => {
								if (index > 0) {
									swapOrder(record.partnersHospitalId, ShiftDirection.FORWARD);
								}
							}}
						/>
						<UpDownButton
							onClick={() => {
								if (index < hospitals.length - 1) {
									swapOrder(record.partnersHospitalId, ShiftDirection.BACKWARD);
								}
							}}
							isDown
						/>
					</>
				);
			},
		},
		{ title: '노출 순서', dataIndex: 'displayOrder', key: 'displayOrder', width: 130 },
		{
			title: '병원로고',
			dataIndex: 'imageUrl',
			key: 'imageUrl',
			render: (value: any, record: PartnersHospitalListItem) => <Image src={record.imageUrl} />,
		},
		{
			title: '병원명',
			dataIndex: 'hospitalName',
			key: 'hospitalName',
			render: (value: any, record: PartnersHospitalListItem) => (
				<>
					<p style={{ textAlign: 'left' }}>{record.hospitalName}</p>
					<p style={{ textAlign: 'left' }}>{record.hospitalNameEn}</p>
					<p style={{ textAlign: 'left' }}>{record.hospitalNameZh}</p>
				</>
			),
		},
		{
			title: '관리',
			dataIndex: 'edit',
			key: 'edit',
			render: (value: any, record: PartnersHospitalListItem) => <InnerTdButton isWithEdit onClickEdit={() => onClickEdit(record)} onClickDelete={() => onClickDelete(record.partnersHospitalId)} />,
		},
	];

	// 파트너 병원 호출
	useEffect(() => {
		getPartnersHospitals();
	}, [getPartnersHospitals]);

	// 프리미엄 카운터 및 실제 노출 여부 호출
	useEffect(() => {
		getCounter();
	}, [getCounter]);

	// 호출한 카운터 정보 반영
	useEffect(() => {
		if (counter) {
			setHospitalCount(counter.totalPartnerHospitals);
			setDoctorCount(counter.totalPartnerDoctors);
			setIsDisplayRealData(counter.displayRealData);
		}
	}, [counter]);

	return (
		<>
			<Section>
				<TableHeading isRequired>프리미엄 인증 제도</TableHeading>
				<form onSubmit={onSubmitCount}>
					<LabelInput type="number" text="파트너 병원" value={hospitalCount} onChange={onHospitalChange} />
					<LabelInput type="number" text="파트너 닥터" value={doctorCount} onChange={onDoctorChange} />
					<LabelToggle text="실제 데이터 노출여부" checked={isDisplayRealData} onChange={(e) => setIsDisplayRealData(e)} />
					<SaveButton isFilled isBig>
						저장
					</SaveButton>
				</form>
			</Section>
			<Section>
				<form onSubmit={onOpenHospitalPopup}>
					<FlexWrapper>
						<Label text="파트너스 병원추가" />
						<Button isFilled isBig onClick={showPopup}>
							추가
						</Button>
					</FlexWrapper>
				</form>
				<TableDefault columns={columns} dataSource={hospitals} />
			</Section>
			{/* 2-1.홈화면 관리 >2-1-5 파트너스 병원 추가 팝업  */}
			{isPopupVisible && (
				<DefaultPopup
					okWithoutClose={true}
					title={selectedHospital ? '편집' : '추가'}
					isPopupVisible={isPopupVisible}
					setIsPopupVisible={setIsPopupVisible}
					onClickOk={onOpenConfirmHospital}
					onClickCancel={onClickCancel}
					isWithCancel
					isWithSave
				>
					<form onSubmit={(e) => e.preventDefault()}>
						<ImageWrapper>
							<Label text="병원로고" isRequired isPopup />
							<AddImageButton isRequiredEmpty={isImageEmpty} url={imageUrl} onChange={onChangeFile} />
						</ImageWrapper>
						<LabelInputTranslate
							text="병원명"
							defaultValue={hospitalName}
							enValue={hospitalNameEn}
							zhValue={hospitalNameZh}
							onChangeKo={(e) => setHospitalName(e.target.value)}
							onChangeEn={(e) => setHospitalNameEn(e.target.value)}
							onChangeZh={(e) => setHospitalNameZh(e.target.value)}
							isRequiredEmpty={isNameEmpty}
							hasHelperText
						/>
					</form>
				</DefaultPopup>
			)}
			{/* 팝업 3-1 */}
			{isConfirmVisible && (
				<ConfirmPopup isPopupVisible={isConfirmVisible} setIsPopupVisible={setIsConfirmVisible}>
					필수 항목을 다시 확인해 주세요.
				</ConfirmPopup>
			)}
			{/* 팝업 4-9 */}
			{isDeleteVisible && (
				<ConfirmPopup isPopupVisible={isDeleteVisible} handleOk={onDelete} setIsPopupVisible={setIsDeleteVisible} isWithCancel>
					삭제하시겠습니까?
				</ConfirmPopup>
			)}
			{/* 팝업 5-1 */}
			{isSubmitCountPopupVisible && (
				<ConfirmPopup isWithCancel={isWithCancel} handleOk={popupHandleOk} handleCancel={popupHandleCancel} isPopupVisible={isSubmitCountPopupVisible} setIsPopupVisible={setIsSubmitCountPopupVisible}>
					{popupContents}
				</ConfirmPopup>
			)}
			{/* <ConfirmPopup isPopupVisible={} setIsPopupVisible={} isWithCancel >
						변경한 내용을 저장할까요? <br/>
						확인을 누르면 앱에 바로 반영됩니다.
				</ConfirmPopup> */}
			{/* 팝업 5-3 */}
			{/* <ConfirmPopup isPopupVisible={} setIsPopupVisible={} >
						변경 사항이 없습니다.
				</ConfirmPopup> */}
			{/* 팝업 5-4 */}
			{/* <ConfirmPopup isPopupVisible={} setIsPopupVisible={} isWithCancel >
					저장되지 않은 변경 내용이 있습니다. <br/>
					저장하지 않고 나가려면 확인을 눌러주세요.
				</ConfirmPopup> */}
		</>
	);
};

export default Premium;
