import styled from 'styled-components';
import { flexbox, flexboxColumn } from '../../styles/mixins';
import { Link } from 'react-router-dom';

export const ButtonWrapper = styled.div`
	${flexbox('center', 'center')};
	width: 100%;

	@media screen and (max-width: 820px) {
		${flexboxColumn('center', 'center')};
	}
`;

export const StyledButton = styled.button`
	${flexbox('center', 'center')};
	width: 36px;
	height: 36px;
	border-radius: 4px;
	color: ${(props) => props.theme.primary};
	&:nth-of-type(2) {
		margin: 0 0 0 7px;
	}

	&:hover {
		color: ${(props) => props.theme.accentLight};
		background-color: ${(props) => props.theme.submenuItem_bg};
	}

	&:active {
		color: ${(props) => props.theme.accent};
		background-color: ${(props) => props.theme.submenuItemDark_bg};
	}

	&:focus {
		color: ${(props) => props.theme.accentDark};
		background-color: ${(props) => props.theme.submenuItemDark_bg};
	}

	&:disabled {
		color: ${(props) => props.theme.disabled};
		background-color: ${(props) => props.theme.disable_bg};
		cursor: not-allowed;

		&:hover {
			background-color: ${(props) => props.theme.menuItem_bg};
		}
	}
`;
