import styled from 'styled-components';
import { flexbox } from '../../styles/mixins';

export const RelativeContainer = styled.div`
	position: relative;
`;

export const CloseButton = styled.button`
	${flexbox('center', 'center')};
	position: absolute;
	top: 8px;
	right: 10px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: ${(props) => props.theme.primary};
	color: ${(props) => props.theme.white};
	transition: background-color 100ms ease-in-out;
	display: ${(props) => (props.isDisabled ? 'none' : 'block')};

	:hover {
		background-color: #29292e;
	}

	:active {
		background-color: #111111;
	}
`;
