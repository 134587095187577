import { Table } from './styles';
import { PaymentDetails } from '../../../model';

interface ReservationInformationProps {
  paymentDetails?: PaymentDetails;
}
const ReservationInformation = (props: ReservationInformationProps) => {

  return (
    <Table>
      <tbody>
        <tr>
          <th>
            <strong>성명(영문명)</strong>
          </th>
          <td>{props.paymentDetails?.appointeeName}
            {props.paymentDetails?.appointeeNameEn ? '(' + props.paymentDetails?.appointeeNameEn + ')' : ''}</td>
          <th>국적</th>
          <td>{props.paymentDetails?.nationality}</td>
        </tr>
        <tr>
          <th>생년월일</th>
          <td>{props.paymentDetails?.birth}</td>
          <th>전화번호</th>
          <td>{props.paymentDetails?.contact}</td>
        </tr>
        <tr>
          <th>병원명</th>
          <td>{props.paymentDetails?.hospitalName}</td>
          <th>방문일</th>
          <td>{props.paymentDetails?.appointmentDate}</td>
        </tr>
        <tr>
          <th>의사명</th>
          <td>{props.paymentDetails?.doctorName}</td>
          <th>시술명</th>
          <td>{props.paymentDetails?.treatmentName}</td>
        </tr>
        <tr>
          <th>요구사항</th>
          <td colSpan={3} lang="zh">
            {props.paymentDetails?.memo}
            {/*因为皮肤干燥敏感，所以想尽量减少刺激，请参考治疗。*/}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ReservationInformation;
