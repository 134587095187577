import { ChangeEvent, FormEvent, useState } from 'react';
import LabelToggle from '../../../../../../components/Inputs/LabelToggle';
import LabelText from '../../../../../../components/Inputs/LabelText';
import LabelInput from '../../../../../../components/Inputs/LabelInput';
import LabelTextarea from '../../../../../../components/Inputs/LabelTextarea';
import Label from '../../../../../../components/Inputs/Label';
import AddImageButton from '../../../../../../components/AddImageButton';
import LabelSelect from '../../../../../../components/Inputs/LabelSelect';
import { ButtonWrapper, ImageWrapper, StyledLink, Button } from './styles';
interface TreatBasicProps {
  display?: boolean;
  hospitalName?: string;
  defaultTreatName?: string;
  departmentValue?: string;
  departmentOption?: any;
  treatCategoryOption?: any;
  treatCategotyValue?: string;
  representativeOption?: any;
  representative?: string;
  cost?: number;
  costOption?: string;
  treatIntro?: string;
  imageUrl?: string;
  textUrl?: string;
  onChangeTreatName: any;
  onChangeCostOption: any;
  onChangeTreatIntro: any;
  isEmptyTextImageFile?: boolean;
  onChangeTextImageFile: any;
  onSubmitBasic: any;
}

const TreatBasic = ({
  display,
  hospitalName,
  defaultTreatName,
  departmentValue,
  departmentOption,
  treatCategoryOption,
  treatCategotyValue,
  representativeOption,
  representative,
  cost,
                      costOption,
                      treatIntro,
  imageUrl,
  textUrl,
                      onChangeTreatName,
                      onChangeCostOption,
                      onChangeTreatIntro,
                      isEmptyTextImageFile,
                      onChangeTextImageFile,
                      onSubmitBasic
}: TreatBasicProps) => {
  return (
    <>
      <form onSubmit={onSubmitBasic}>
        <LabelToggle text="노출" checked={display} />
        <LabelText text="병원명">{hospitalName}</LabelText>
        <LabelInput
          text="시술명"
          placeholder={defaultTreatName}
          value={defaultTreatName}
          onChange={onChangeTreatName}
          isRequired
          maxLength={200}
        />
        <LabelSelect text="진료과" value={departmentValue} optionList={departmentOption} isRequired disabled />
        <LabelSelect
          text="시술 카테고리"
          isRequired
          disabled
          value={treatCategotyValue}
          optionList={treatCategoryOption}
        />
        <LabelSelect
          text="대표 시술 여부"
          isRequired
          disabled
          value={representative}
          optionList={representativeOption}
        />
        <LabelInput text="가격" value={cost} isRequired disabled isCost />
        <LabelInput
          text="가격 옵션(선택)"
          placeholder="예)4cc/1회"
          maxLength={200}
          value={costOption}
          onChange={onChangeCostOption}
          hasHelperText
        >
          가격하단에 표시되는 문구입니다.
          <br />
          (용량, 횟수)
        </LabelInput>
        <LabelTextarea
          text="시술 소개"
          placeholder="시술 소개를 입력해주세요."
          maxLength={2000}
          value={treatIntro}
          onChange={onChangeTreatIntro}
          isRequired
        />
        <ImageWrapper>
          <Label text="시술 이미지형 사진" isRequired />
          <AddImageButton url={imageUrl} isDisabled />
        </ImageWrapper>
        {/*<ImageWrapper>*/}
        {/*  <Label text="시술 텍스트형 사진" isRequired />*/}
        {/*  <AddImageButton url={textUrl} />*/}
        {/*</ImageWrapper>*/}
        <ImageWrapper>
          <Label text="시술 텍스트형 사진" isRequired />
          <AddImageButton
            url={textUrl}
            onChange={onChangeTextImageFile}
            isRequiredEmpty={isEmptyTextImageFile}
          />
        </ImageWrapper>

        <ButtonWrapper>
          <StyledLink to="/contents/treat/">취소</StyledLink>
          <Button isBig isFilled>
            다음
          </Button>
        </ButtonWrapper>
      </form>
    </>
  );
};

export default TreatBasic;
