import styled from 'styled-components';
import { fontStyle15, fontStyle16 } from '../../../styles/mixins';

export const StyledLabel = styled.div<{ isPopup?: boolean; isSubMenu?: boolean }>`
  min-width: ${props => {
    if (props.isPopup) {
      if (props.isSubMenu) {
        return '75px';
      }
      return '130px';
    } else {
      return '220px';
    }
  }};

  label {
    ${props => (props.isSubMenu ? fontStyle15 : fontStyle16)}
    color: ${props => (props.isSubMenu ? props.theme.grey : props.theme.primary)};
    font-weight: ${props => (props.isSubMenu ? 400 : 500)};
  }

  span {
    color: ${props => props.theme.red};
  }
`;
