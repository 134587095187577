import styled from "styled-components";
import { buttonBase, flexbox } from "../../../styles/mixins";

export const Section = styled.section`
	margin: 0 0 100px 0;

	@media (min-width: 821px) {
		margin: 0 30px 100px;
	}

	@media screen and (min-width: 1025px) {
		margin: 0 70px 150px;
	}
`;

export const ImageWrapper = styled.div`
	${flexbox("flex-start", "flex-start")};
	margin: 0 0 35px 0;
`;

export const InputWrapper = styled.div`
	display: flex;
	margin: 10px 0 0;
`;

export const Button = styled.button<{isFilled?: boolean; isMiddle?: boolean}>`
	${buttonBase};
	height: 42px;
	margin: 0 0 0 10px;
`;
