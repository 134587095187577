import { ChangeEvent, FormEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import PageHeading from '../../../components/PageHeading';
import SearchBox from '../../../components/SearchBox';
import DetailButton from '../../../components/InnerTdButton/DetailButton';
import DeleteButton from '../../../components/InnerTdButton/DeleteButton';
import TableDefault from '../../../components/Tables/TableDefault';
import ConfirmPopup from '../../../components/Popup/ConfirmPopup';
import DefaultPopup from '../../../components/Popup/DefaultPopup';
import PartnersDetailTable from './PartnersDetailTable';
import LabelInput from '../../../components/Inputs/LabelInput';
import AuthInput from './AuthInput';
import { Section, StyledContent } from '../styles';
import { DeleteButtonWrapper, InputWrapper, PopupSubHeading, TotalNum } from './styles';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { ItemDefault } from '../../../components/Inputs/Select';
import {
  CreatePartnersRequest,
  ItemsResponse,
  ModifyPartnersAccountRequest,
  ModifyPartnersRequest,
  PartnersDetail,
  PartnersInventoryItem,
  PartnersSearchOptions,
} from '../../../model';
import moment, { Moment } from 'moment';
import { EventValue, RangeValue } from 'rc-picker/lib/interface';
import { useApi } from '../../../contexts/ApiContext';
import LabelInputTranslate from '../../../components/Inputs/LabelInputTranslate';

const dateFormat = 'YYYY-MM-DD';
const popupText = {
  save: (
    <>
      입력한 내용을 저장할까요? <br />
      확인을 누르면 앱에 바로 반영됩니다.
    </>
  ),
  unsave: (
    <>
      저장되지 않은 변경 내용이 있습니다. <br />
      저장하지 않고 나가려면 확인을 눌러주세요.
    </>
  ),
  validation: <>필수 항목을 다시 확인해 주세요.</>,
  delete: (
    <>
      삭제 하시겠습니까? <br />
      삭제된 정보는 복구가 불가능하며
      <br />
      앱에 바로 반영됩니다.
    </>
  ),
};

const PartnersList = () => {
  const api = useApi();

  // 파트너 목록
  const [partnersData, setPartnersData] = useState<ItemsResponse<PartnersInventoryItem>>();
  const [partners, setPartners] = useState<PartnersInventoryItem[]>([]);

  // 파트너 상세
  const [partnersDetail, setPartnersDetail] = useState<PartnersDetail>();

  // 페이징
  const [page, setPage] = useState<number>(0);
  const [size] = useState<number>(25);

  // 검색 옵션 목록
  const [partnersOptionList] = useState<ItemDefault[]>([
    { value: PartnersSearchOptions.ALL, text: '전체' },
    { value: PartnersSearchOptions.HOSPITAL_NAME, text: '병원명' },
    { value: PartnersSearchOptions.ID, text: '아이디' },
  ]);

  // 검색 옵션
  const [displayOption, setDisplayOption] = useState<PartnersSearchOptions>(PartnersSearchOptions.ALL);
  const [searchOption, setSearchOption] = useState<PartnersSearchOptions>(PartnersSearchOptions.ALL);
  const onChangeSearchOption = useCallback((value: any) => {
    setDisplayOption(value as PartnersSearchOptions);
  }, []);

  // 검색어
  const [displayKeyword, setDisplayKeyword] = useState<string>('');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const onChangeSearchKeyword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDisplayKeyword(e.target.value);
  }, []);

  // 검색 기간
  const [startDate, setStartDate] = useState<EventValue<Moment> | undefined>(moment('2022-01-01'));
  const [endDate, setEndDate] = useState<EventValue<Moment> | undefined>(moment());
  const [searchStartDate, setSearchStartDate] = useState<EventValue<Moment>>(moment('2022-01-01'));
  const [searchEndDate, setSearchEndDate] = useState<EventValue<Moment>>(moment());
  const onChangeDate = (values: RangeValue<Moment>) => {
    setStartDate(values?.[0]);
    setEndDate(values?.[1]);
  };

  // 병원 편집 데이터
  const [isModified, setIsModified] = useState<boolean>(false);
  const [editPartner, setEditPartner] = useState<PartnersInventoryItem>();
  const [editHospitalName, setEditHospitalName] = useState<string>('');
  const [editHospitalNameEn, setEditHospitalNameEn] = useState<string>('');
  const [editHospitalNameZh, setEditHospitalNameZh] = useState<string>('');
  const [isEmptyEditHospitalName, setIsEmptyEditHospitalName] = useState<boolean>(false);
  const onChangeEditHospitalName = useCallback((e: ChangeEvent<HTMLInputElement>) =>
      setEditHospitalName(e.target.value),[]);
  const onChangeEditHospitalNameEn = useCallback((e: ChangeEvent<HTMLInputElement>) =>
      setEditHospitalNameEn(e.target.value),[]);
  const onChangeEditHospitalNameZh = useCallback((e: ChangeEvent<HTMLInputElement>) =>
      setEditHospitalNameZh(e.target.value),[]);
  const [editRepresentative, setEditRepresentative] = useState<string>('');
  const onChangeEditRepresentative = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setEditRepresentative(e.target.value),
    [],
  );
  const [editManagerName, setEditManagerName] = useState<string>('');
  const onChangeEditManagerName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setEditManagerName(e.target.value),
    [],
  );
  const [editManagerContact, setEditManagerContact] = useState<string>('');
  const onChangeEditManagerContact = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setEditManagerContact(e.target.value),
    [],
  );
  const [editBusinessNumber, setEditBusinessNumber] = useState<string>('');
  const onChangeEditBusinessNumber = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setEditBusinessNumber(e.target.value),
    [],
  );

  // 비밀번호 변경 데이터
  const [editLoginName, setEditLoginName] = useState<string>('');
  const [isValidEditLoginName, setIsValidEditLoginName] = useState<boolean>(true);
  const [editPassword, setEditPassword] = useState<string>('');
  const [isValidEditPassword, setIsValidEditPassword] = useState<boolean>(true);
  const [editPasswordCheck, setEditPasswordCheck] = useState<string>('');
  const [isValidEditPasswordCheck, setIsValidEditPasswordCheck] = useState<boolean>(true);

  // 파트너스 계정 생성
  const [hospitalName, setHospitalName] = useState<string>('');
  const [hospitalNameEn, setHospitalNameEn] = useState<string>('');
  const [hospitalNameZh, setHospitalNameZh] = useState<string>('');
  const [isEmptyHospitalName, setIsEmptyHospitalName] = useState<boolean>(false);
  const onChangeHospitalName = useCallback((e: ChangeEvent<HTMLInputElement>) =>
    setHospitalName(e.target.value), []);
  const onChangeHospitalNameEn = useCallback((e: ChangeEvent<HTMLInputElement>) =>
    setHospitalNameEn(e.target.value), []);
  const onChangeHospitalNameZh = useCallback((e: ChangeEvent<HTMLInputElement>) =>
    setHospitalNameZh(e.target.value), []);
  const [representative, setRepresentative] = useState<string>('');
  const onChangeRepresentative = useCallback((e: ChangeEvent<HTMLInputElement>) =>
      setRepresentative(e.target.value),[]);
  const [managerName, setManagerName] = useState<string>('');
  const onChangeManagerName = useCallback((e: ChangeEvent<HTMLInputElement>) => setManagerName(e.target.value), []);
  const [managerContact, setManagerContact] = useState<string>('');
  const onChangeManagerContact = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setManagerContact(e.target.value),
    [],
  );
  const [businessNumber, setBusinessNumber] = useState<string>('');
  const onChangeBusinessNumber = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setBusinessNumber(e.target.value),
    [],
  );
  const [loginName, setLoginName] = useState<string>('');
  const [isValidLoginName, setIsValidLoginName] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');
  const [isValidPassword, setIsValidPassword] = useState<boolean>(true);
  const [passwordCheck, setPasswordCheck] = useState<string>('');
  const [isValidPasswordCheck, setIsValidPasswordCheck] = useState<boolean>(true);

  // 공통 팝업 관리
  const [popupContents, setPopupContents] = useState<string | ReactNode>();
  const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => {});
  const [popupHandleCancel, setPopupHandleCancel] = useState<VoidFunction | undefined>(() => () => {});
  const [isWithCancel, setIsWithCancel] = useState(false);
  const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState(false);

  // 팝업
  const [isCreateVisible, setIsCreateVisible] = useState(false);
  const onClickCreate = () => setIsCreateVisible(true);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);

  // 파트너 목록 api
  const getPartners = useCallback(async () => {
    const data = (
      await api.partners.getPartners(
        page,
        size,
        searchOption,
        searchKeyword,
        searchStartDate?.format(dateFormat) ?? moment().subtract(6, 'month').format(dateFormat),
        searchEndDate?.format(dateFormat) ?? moment().format(dateFormat),
      )
    ).data;

    setPartnersData(data.result);
  }, [api.partners, page, searchEndDate, searchKeyword, searchOption, searchStartDate, size]);

  // 파트너 상세 정보 api
  const getPartnersDetail = useCallback(
    async (partnersId: string) => {
      const data = (await api.partners.getPartner(partnersId)).data;
      setPartnersDetail(data.data);
    },
    [api.partners],
  );

  // 파트너 삭제 api
  const deleteParnter = useCallback(
    async (partnersId: string) => {
      const data = (await api.partners.deleteParnter(partnersId)).data;

      if (data.deleted) {
        await getPartners();
      }
    },
    [api.partners, getPartners],
  );

  // 파트너 편집 api
  const modifyBanner = useCallback(
    async (partnersId: string, editData: ModifyPartnersRequest) => {
      await api.partners.modifyPartner(partnersId, editData);
      await getPartners();

      setIsEditVisible(false);
      setIsDetailVisible(false);
    },
    [api.partners, getPartners],
  );

  // 아이디 체크 api
  const verifyLoginName = useCallback(
    async (loginName: string) => {
      return (await api.partners.verifyLoginName(loginName)).data;
    },
    [api.partners],
  );

  // 비밀번호 수정 api
  const modifyPartnersAccount = useCallback(
    async (accountId: string, editData: ModifyPartnersAccountRequest) => {
      const data = (await api.partners.modifyPartnersAccount(accountId, editData)).data;

      if (data.modified) {
        await getPartners();

        setIsPasswordVisible(false);
        setIsDetailVisible(false);
      }
    },
    [api.partners, getPartners],
  );

  // 파트너 등록 api
  const addPartner = useCallback(
    async (submitData: CreatePartnersRequest) => {
      const data = (await api.partners.addPartner(submitData)).data;

      if (data.created) {
        getPartners();
        setIsCreateVisible(false);
      }
    },
    [api.partners, getPartners],
  );

  // 팝업 설정
  const setPopupData = (
    contents: JSX.Element,
    isWithCancel: boolean,
    handleOk?: VoidFunction,
    handleCancel?: VoidFunction,
  ) => {
    setIsWithCancel(isWithCancel);
    setPopupContents(contents);
    setPopupHandleOk(handleOk);
    setPopupHandleCancel(handleCancel);
  };

  // 검색
  const onSearch = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      setPage(0);

      setSearchOption(displayOption);
      setSearchKeyword(displayKeyword);
      setSearchStartDate(startDate ?? moment().subtract(6, 'month'));
      setSearchEndDate(endDate ?? moment());
    },
    [displayKeyword, displayOption, endDate, startDate],
  );

  // 상세 정보 클릭
  const onClickDetail = useCallback(
    async (item: PartnersInventoryItem) => {
      await getPartnersDetail(item.partnersId);
      setEditPartner(item);
      setIsDetailVisible(true);
    },
    [getPartnersDetail],
  );

  // 삭제 클릭
  const onClickDelete = useCallback(
    (item: PartnersInventoryItem) => {
      const handleOk = () => async () => deleteParnter(item.partnersId);
      const handleCancel = () => async () => setIsConfirmPopupVisible(false);

      setPopupData(popupText.delete, true, handleOk, handleCancel);
      setIsConfirmPopupVisible(true);
    },
    [deleteParnter],
  );

  // 편집 클릭
  const onClickEdit = useCallback(() => {
    checkValidationEdit();
    setIsEditVisible(true);
  }, [partnersDetail, editHospitalName]);

  useEffect(() => {
    setEditHospitalName(partnersDetail?.hospitalName ?? '');
    setEditHospitalNameEn(partnersDetail?.hospitalNameEn ?? '');
    setEditHospitalNameZh(partnersDetail?.hospitalNameZh ?? '');
    setEditRepresentative(partnersDetail?.representative ?? '');
    setEditManagerName(partnersDetail?.managerName ?? '');
    setEditManagerContact(partnersDetail?.managerContact ?? '');
    setEditBusinessNumber(partnersDetail?.businessNumber ?? '');
  }, [partnersDetail]);

  useEffect(() => {
    if(isEditVisible) {
      if(partnersDetail?.hospitalName !== editHospitalName ||
        partnersDetail?.hospitalNameEn !== editHospitalNameEn ||
        partnersDetail?.hospitalNameZh !== editHospitalNameZh ||
        partnersDetail?.representative !== editRepresentative ||
        partnersDetail?.managerName !== editManagerName ||
        partnersDetail?.managerContact !== editManagerContact ||
        partnersDetail?.businessNumber !== editBusinessNumber) {
        setIsModified(true);
      } else {
        setIsModified(false);
      }
    }
  }, [editBusinessNumber, editHospitalName, editHospitalNameEn, editHospitalNameZh, editManagerContact, editManagerName, editRepresentative, hospitalName, isEditVisible, isModified, partnersDetail?.businessNumber, partnersDetail?.hospitalName, partnersDetail?.hospitalNameEn, partnersDetail?.hospitalNameZh, partnersDetail?.managerContact, partnersDetail?.managerName, partnersDetail?.representative]);

  const onClickCancelModify = useCallback(() => {
    if(isModified) {
      setPopupData(popupText.unsave, true, () => () => modifyCancel());
      setIsConfirmPopupVisible(true);
    } else {
      setIsEditVisible(false);
    }
  }, [isModified]);

  // 편집 취소
  const modifyCancel = useCallback(async () => {
    setEditHospitalName(partnersDetail?.hospitalName ?? '');
    setEditHospitalNameEn(partnersDetail?.hospitalNameEn ?? '');
    setEditHospitalNameZh(partnersDetail?.hospitalNameZh ?? '');
    setEditRepresentative(partnersDetail?.representative ?? '');
    setEditManagerName(partnersDetail?.managerName ?? '');
    setEditManagerContact(partnersDetail?.managerContact ?? '');
    setEditBusinessNumber(partnersDetail?.businessNumber ?? '');

    setIsEditVisible(false);
  }, [partnersDetail?.businessNumber, partnersDetail?.hospitalName, partnersDetail?.hospitalNameEn, partnersDetail?.hospitalNameZh, partnersDetail?.managerContact, partnersDetail?.managerName, partnersDetail?.representative]);

  // 편집 밸리데이션 체크
  const checkValidationEdit = useCallback(() => {
    if (editHospitalName) {
      setIsEmptyEditHospitalName(false);

      return true;
    } else {
      setIsEmptyEditHospitalName(true);

      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);

      return false;
    }
  }, [editHospitalName]);

  // 편집
  const onSubmitEdit = useCallback(async () => {
    if (checkValidationEdit() && editPartner) {
      const data: ModifyPartnersRequest = {
        hospitalName: editHospitalName,
        hospitalNameEn: editHospitalNameEn,
        hospitalNameZh: editHospitalNameZh,
        representative: editRepresentative,
        managerName: editManagerName,
        managerContact: editManagerContact,
        businessNumber: editBusinessNumber,
      };

      await modifyBanner(editPartner.partnersId, data);
    }
  }, [
    checkValidationEdit,
    editBusinessNumber,
    editHospitalName,
    editHospitalNameEn,
    editHospitalNameZh,
    editPartner,
    editManagerContact,
    editManagerName,
    editRepresentative,
    modifyBanner,
  ]);

  // 비밀번호 입력값 초기화
  const resetPassword = () => {
    setIsValidEditPassword(true);
    setIsValidEditPasswordCheck(true);
    setEditPassword('');
    setEditPasswordCheck('');
  };

  // 비밀번호 변경 클릭
  const onClickEditPassword = useCallback(() => {
    setEditLoginName(partnersDetail?.loginName ?? '');

    setIsPasswordVisible(true);
  }, [partnersDetail?.loginName]);

  // 편집 아이디 onChange 유효성 검사
  const onChangeEditLoginName = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const loginName = e.target.value;

      setEditLoginName(loginName);

      const validData = await verifyLoginName(loginName);
      if (partnersDetail?.loginName === loginName || validData.valid) {
        setIsValidEditLoginName(true);
      } else {
        setIsValidEditLoginName(false);
      }
    },
    [verifyLoginName, partnersDetail?.loginName],
  );

  // 편집 비밀번호 onChange 길이 검사
  const onChangeEditPassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;

    setEditPassword(password);
    if (password.length >= 8 && password.length <= 32) {
      setIsValidEditPassword(true);
    } else {
      setIsValidEditPassword(false);
    }
  }, []);

  const onChangeEditPasswordCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const check = e.target.value;

      setEditPasswordCheck(check);
      if (check === editPassword) {
        setIsValidEditPasswordCheck(true);
      } else {
        setIsValidEditPasswordCheck(false);
      }
    },
    [editPassword],
  );

  // 비밀번호 변경 밸리데이션 체크
  const checkValidationAccount = useCallback(async () => {
    let validation = true;

    const validData = await verifyLoginName(editLoginName);
    if (partnersDetail?.loginName === editLoginName || validData.valid) {
      setIsValidEditLoginName(true);
    } else {
      setIsValidEditLoginName(false);
      validation = false;
    }

    if (editPassword.length >= 8 && editPassword.length <= 32) {
      setIsValidEditPassword(true);
    } else {
      setIsValidEditPassword(false);
      validation = false;
    }

    if (editPassword === editPasswordCheck) {
      setIsValidEditPasswordCheck(true);
    } else {
      setIsValidEditPasswordCheck(false);
      validation = false;
    }

    if (validation) {
      setIsValidEditLoginName(true);
      setIsValidEditPassword(true);
      setIsValidEditPasswordCheck(true);
    } else {
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }

    return validation;
  }, [editLoginName, editPassword, editPasswordCheck, partnersDetail?.loginName, verifyLoginName]);

  // 비밀번호 변경
  const onSubmitAccount = useCallback(async () => {
    if ((await checkValidationAccount()) && editPartner) {
      const data: ModifyPartnersAccountRequest = {
        loginName: editLoginName,
        loginPassword: editPassword,
      };

      await modifyPartnersAccount(editPartner.accountId, data);
    }
  }, [checkValidationAccount, editLoginName, editPartner, editPassword, modifyPartnersAccount]);

  // 등록 아이디 onChange 유효성 검사
  const onChangeLoginName = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const loginName = e.target.value;
      setLoginName(loginName);

      if ((await verifyLoginName(loginName)).valid) {
        setIsValidLoginName(true);
      } else {
        setIsValidLoginName(false);
      }
    },
    [verifyLoginName],
  );

  // 등록 비밀번호 onChange 길이 검사
  const onChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;

    setPassword(password);
    if (password.length >= 8 && password.length <= 32) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
    }
  }, []);

  const onChangePasswordCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const check = e.target.value;

      setPasswordCheck(check);
      if (check === password) {
        setIsValidPasswordCheck(true);
      } else {
        setIsValidPasswordCheck(false);
      }
    },
    [password],
  );

  // 파트너 등록 밸리데이션 체크
  const checkValidationAdd = useCallback(async () => {
    let validation = true;

    if (!hospitalName) {
      setIsEmptyHospitalName(true);
      validation = false;
    } else {
      setIsEmptyHospitalName(false);
    }

    if ((await verifyLoginName(loginName)).valid) {
      setIsValidLoginName(true);
    } else {
      setIsValidLoginName(false);
      validation = false;
    }

    if (password.length >= 8 && password.length <= 32) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
      validation = false;
    }

    if (password === passwordCheck) {
      setIsValidPasswordCheck(true);
    } else {
      setIsValidPasswordCheck(false);
      validation = false;
    }

    if (validation) {
      setIsEmptyHospitalName(false);
      setIsValidLoginName(true);
      setIsValidPassword(true);
      setIsValidPasswordCheck(true);
    } else {
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }

    return validation;
  }, [hospitalName, loginName, password, passwordCheck, verifyLoginName]);

  // 파트너스 추가
  const onSubmitAdd = useCallback(async () => {
    if (await checkValidationAdd()) {
      setPopupData(popupText.save, true, () => async () => {
        const data: CreatePartnersRequest = {
          hospitalName: hospitalName,
          hospitalNameEn: hospitalNameEn,
          hospitalNameZh: hospitalNameZh,
          representative: representative !== '' ? representative : undefined,
          managerName: managerName !== '' ? managerName : undefined,
          managerContact: managerContact !== '' ? managerContact : undefined,
          businessNumber: businessNumber !== '' ? businessNumber : undefined,
          loginName: loginName,
          loginPassword: password,
        };

        await addPartner(data);
        resetForm();
      });
      setIsConfirmPopupVisible(true);
    }
  }, [
    checkValidationAdd,
    addPartner,
    businessNumber,
    hospitalName,
    hospitalNameEn,
    hospitalNameZh,
    loginName,
    managerContact,
    managerName,
    password,
    representative,
  ]);

  const hasChanged = useCallback(() => {
    return (
      hospitalName !== '' ||
      representative !== '' ||
      managerName !== '' ||
      managerContact !== '' ||
      businessNumber !== '' ||
      loginName !== '' ||
      password !== '' ||
      passwordCheck !== ''
    );
  }, [hospitalName, representative, managerName, managerContact, businessNumber, loginName, password, passwordCheck]);

  const onSubmitCancel = useCallback(() => {
    if (hasChanged()) {
      setPopupData(popupText.unsave, true, () => () => {
        // 생성할 파트너스 계정 정보 일부입력 -> 취소버튼 -> confirm 팝업 -> 확인버튼 (모두 캔슬하고 팝업들 닫기))
        setIsConfirmPopupVisible(false);
        setIsCreateVisible(false);

        resetForm();
      });
      setIsConfirmPopupVisible(true);
    }
  }, [hasChanged]);

  const resetForm = useCallback(() => {
    setHospitalName('');
    setRepresentative('');
    setManagerName('');
    setManagerContact('');
    setBusinessNumber('');
    setLoginName('');
    setPassword('');
    setPasswordCheck('');
  }, []);

  const columns: ColumnsType<PartnersInventoryItem> = [
    {
      title: '번호',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: any) => {
        return moment(value).format('YYYY.MM.DD')
      },
      sorter: (a, b) => {
        const aDate = moment(a.createdAt);
        const bDate = moment(b.createdAt);
        return bDate.diff(aDate);
      },
    },
    {
      title: '병원명',
      dataIndex: 'hospitalName',
      key: 'hospitalName',
    },
    {
      title: '아이디',
      dataIndex: 'loginName',
      key: 'loginName',
    },
    {
      title: '상세보기',
      dataIndex: 'detail',
      key: 'detail',
      render: (value, record) => <DetailButton onClick={() => onClickDetail(record)} />,
    },
    {
      title: '관리',
      dataIndex: 'edit',
      key: 'edit',
      render: (value, record) => (
        <DeleteButtonWrapper>
          <DeleteButton onClick={() => onClickDelete(record)} />
        </DeleteButtonWrapper>
      ),
    },
  ];

  const pagination: TablePaginationConfig = {
    position: ['bottomCenter'],
    defaultPageSize: size,
    showSizeChanger: false,
    total: partnersData?.totalCount,
    current: page + 1,
    onChange: (page: number, pageSize: number) => {
      setPage(page - 1);
    },
  };

  // 파트너 목록 api 호출
  useEffect(() => {
    getPartners();
  }, [getPartners]);

  // 파트너 목록 데이터 가공
  useEffect(() => {
    const data = partnersData?.items.map(row => {
      return { key: row.partnersId, ...row };
    });

    if (data) {
      setPartners(data);
    }
  }, [partnersData]);

  return (
    <>
      <PageHeading hasButton="파트너스 계정 생성" onClick={onClickCreate}>
        파트너스 목록
      </PageHeading>
      <StyledContent>
        <Section>
          <SearchBox
            optionList={partnersOptionList}
            onChangeOption={onChangeSearchOption}
            searchValue={displayKeyword}
            onChangeSearch={onChangeSearchKeyword}
            startDate={startDate}
            endDate={endDate}
            onChangeDate={onChangeDate}
            onSubmit={onSearch}
            titleUnder="기간"
            allowClear={false}
          />
          <TotalNum>
            총 <strong>{partnersData?.totalCount ?? 0}</strong>개
          </TotalNum>
          <TableDefault dataSource={partners} columns={columns} pagination={pagination} />
        </Section>
      </StyledContent>
      {/* 6-2-2.파트너스 상세 팝업  */}
      {isDetailVisible && partnersDetail && (
        <DefaultPopup
          title="상세보기"
          onClickOk={onClickEdit}
          isPopupVisible={isDetailVisible}
          setIsPopupVisible={setIsDetailVisible}
          okWithoutClose
          isWithCancel="확인"
          isWithEdit
        >
          <PartnersDetailTable onClick={onClickEditPassword} partner={partnersDetail} />
        </DefaultPopup>
      )}
      {/* 3-6-1. 팝업  */}
      {/* isDeleteVisible && (
				<ConfirmPopup isPopupVisible={isDeleteVisible} setIsPopupVisible={setIsDeleteVisible} isWithCancel>
					삭제 하시겠습니까? <br />
					삭제된 정보는 복구가 불가능하며
					<br />
					앱에 바로 반영됩니다.
				</ConfirmPopup>
			)*/}
      {/*  6-2-3.파트너스 계정 생성 팝업 */}
      {isCreateVisible && (
        <DefaultPopup
          title="파트너스 계정 생성"
          onClickOk={onSubmitAdd}
          onClickCancel={onSubmitCancel}
          isPopupVisible={isCreateVisible}
          setIsPopupVisible={setIsCreateVisible}
          okWithoutClose={true}
          okWithoutCloseWhenCanceled={hasChanged()}
          isWithCancel
          isWithSave
        >
          <InputWrapper>
            <PopupSubHeading>1. 병원 기본 정보 입력</PopupSubHeading>
            {/* <LabelInput
              text="병원명"
              value={hospitalName}
              onChange={onChangeHospitalName}
              isRequiredEmpty={isEmptyHospitalName}
              isRequired
              isPopup
            /> */}
            {/*<LabelInputTranslate text="병원명" isPopup />*/}
            <LabelInputTranslate
              text="병원명"
              defaultValue={hospitalName}
              enValue={hospitalNameEn}
              zhValue={hospitalNameZh}
              onChangeKo={onChangeHospitalName}
              onChangeEn={onChangeHospitalNameEn}
              onChangeZh={onChangeHospitalNameZh}
              isRequiredEmpty={isEmptyEditHospitalName}
              isPopup
            />
            <LabelInput text="대표자" value={representative} onChange={onChangeRepresentative} isPopup />
            <LabelInput text="담당자 이름" value={managerName} onChange={onChangeManagerName} isPopup />
            <LabelInput text="담당자 번호" value={managerContact} placeholder="-없이 숫자만 입력해주세요."
                        onChange={onChangeManagerContact} isPopup />
            <LabelInput text="사업자 번호" value={businessNumber} placeholder="-없이 숫자만 입력해주세요."
                        onChange={onChangeBusinessNumber} isPopup />
          </InputWrapper>
          <InputWrapper>
            <PopupSubHeading>2. 계정 정보 입력</PopupSubHeading>
            <AuthInput
              loginName={loginName}
              password={password}
              passwordCheck={passwordCheck}
              isValidLoginName={isValidLoginName}
              isValidPassword={isValidPassword}
              isValidPasswordCheck={isValidPasswordCheck}
              onChangeLoginName={onChangeLoginName}
              onChangePassword={onChangePassword}
              onChangePasswordCheck={onChangePasswordCheck}
            />
          </InputWrapper>
        </DefaultPopup>
      )}

      {/*  6-2-3.파트너스 계정 생성 팝업 > 팝업 4-1  */}
      {/* <ConfirmPopup isPopupVisible={} setIsPopupVisible={} isWithCancel>
				저장되지 않은 변경 내용이 있습니다. <br/>
				저장하지 않고 나가려면 확인을 눌러주세요.
			</ConfirmPopup> */}
      {/*  6-2-3.파트너스 계정 생성 팝업 > 팝업 5-1  */}
      {/* <ConfirmPopup isPopupVisible={} setIsPopupVisible={}>
				필수 항목을 다시 확인해 주세요.
			</ConfirmPopup> */}
      {/* 6-2-4.파트너스 계정 편집 팝업*/}
      {isEditVisible && (
        <DefaultPopup
          title="편집"
          onClickOk={onSubmitEdit}
          onClickCancel={onClickCancelModify}
          isPopupVisible={isEditVisible}
          setIsPopupVisible={setIsEditVisible}
          okWithoutClose
          okWithoutCloseWhenCanceled
          isWithCancel
          isWithSave
        >
          <PopupSubHeading>1. 병원 기본 정보 입력</PopupSubHeading>
          <LabelInputTranslate
            text="병원명"
            defaultValue={editHospitalName}
            enValue={editHospitalNameEn}
            zhValue={editHospitalNameZh}
            onChangeKo={onChangeEditHospitalName}
            onChangeEn={onChangeEditHospitalNameEn}
            onChangeZh={onChangeEditHospitalNameZh}
            isRequiredEmpty={isEmptyEditHospitalName}
            isPopup
          />
          <LabelInput text="대표자" value={editRepresentative} onChange={onChangeEditRepresentative} isPopup />
          <LabelInput text="담당자 이름" value={editManagerName} onChange={onChangeEditManagerName} isPopup />
          <LabelInput text="담당자 번호" value={editManagerContact} onChange={onChangeEditManagerContact} isPopup />
          <LabelInput text="사업자 번호" value={editBusinessNumber} onChange={onChangeEditBusinessNumber} isPopup />
        </DefaultPopup>
      )}
      {/* 6-2-4-2.파트너스 비밀번호 변경 팝업 */}
      {isPasswordVisible && (
        <DefaultPopup
          title="비밀번호 변경"
          onClickOk={onSubmitAccount}
          onClickCancel={resetPassword}
          isPopupVisible={isPasswordVisible}
          setIsPopupVisible={setIsPasswordVisible}
          okWithoutClose
          isWithCancel
          isWithSave
        >
          <PopupSubHeading>2. 계정 정보 입력</PopupSubHeading>
          <AuthInput
            loginName={editLoginName}
            password={editPassword}
            passwordCheck={editPasswordCheck}
            isValidLoginName={isValidEditLoginName}
            isValidPassword={isValidEditPassword}
            isValidPasswordCheck={isValidEditPasswordCheck}
            onChangeLoginName={onChangeEditLoginName}
            onChangePassword={onChangeEditPassword}
            onChangePasswordCheck={onChangeEditPasswordCheck}
          />
        </DefaultPopup>
      )}

      {isConfirmPopupVisible && (
        <ConfirmPopup
          isWithCancel={isWithCancel}
          handleOk={popupHandleOk}
          handleCancel={popupHandleCancel}
          isPopupVisible={isConfirmPopupVisible}
          setIsPopupVisible={setIsConfirmPopupVisible}
        >
          {popupContents}
        </ConfirmPopup>
      )}
    </>
  );
};

export default PartnersList;
