import styled from "styled-components";
import "antd/dist/antd.css";
import { Modal, ModalProps } from "antd";
import { buttonBase, flexbox, flexboxColumn, fontStyle14, fontStyle18 } from "../../../styles/mixins";
import { ReactNode } from "react";

interface CustomModalProps extends ModalProps {
	children: ReactNode;
}

export const StyledModal = styled(Modal)<CustomModalProps>`
	&.ant-modal {
		width: 420px !important;
	}

	.ant-modal-content {
		/* height:300px; */
		height: 330px;
		border-radius: 16px;

		.ant-modal-close {
			display: none;
		}
	}

	.ant-modal-body {
		/* height:300px; */
		height: 330px;
		${flexbox("center", "center")};+
	}
`;

export const Content = styled.div`
	${flexboxColumn("space-between", "center")};

	small {
		${fontStyle14};
	}
`;

export const TextWrapper = styled.div`
	${flexboxColumn("center", "center")}
`;

export const Text = styled.p`
	${fontStyle18};
	text-align: center;
	margin: 18px 0 50px;

	> small {
		${fontStyle14};
	}
`;

export const ButtonWrapper = styled.div`
	${flexbox("center", "center")}
`;

export const Button = styled.button<{isFilled?: boolean; isBig?: boolean;}>`
	${buttonBase};
	width: 150px;
	height: 50px;
`;
