import styled from "styled-components";
import { buttonBase, flexbox } from "../../../styles/mixins";

export const Section = styled.section`
	@media (min-width: 821px) {
		margin: 0 30px 100px;
	}

	@media screen and (min-width: 1025px) {
		margin: 0 70px 200px;
	}
`;

export const InputWrapper = styled.div`
	${flexbox("flex-start", "center")};
	margin: 0;
`;

export const Button = styled.button<{isFilled?: boolean; isSmall?: boolean}>`
	${buttonBase};
	width: 62px;
	height: 42px;
	margin: 0 0 25px 10px;
`;
