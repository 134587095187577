import Label from '../../../components/Inputs/Label';
import LabelInput from '../../../components/Inputs/LabelInput';
import TableHeading from '../../../components/TableHeading';
import TableDefault from '../../../components/Tables/TableDefault';
import { Divider } from '../../../components/Divider';
import { Button, FlexWrapper, Section, SmallButton } from '../styles';
import { TreatmentItem } from '../../../model';

interface SearchFormProps {
  columns: any;
  values: string[];
  recommend?: string;
  treats?:  TreatmentItem[]
  onChangePopular: any;
  onChangeTitle: any;
  onClickAdd: any;
  onClickSubmit: any;
}

const SearchForm = ({ columns, values, recommend, treats,
                      onChangePopular, onChangeTitle, onClickAdd, onClickSubmit }: SearchFormProps) => {
  return (
    <form onSubmit={onClickSubmit}>
      <Section>
        <TableHeading isRequired>
          인기 검색어
          <small>6개의 검색어를 모두 입력해주세요.</small>
        </TableHeading>
        <LabelInput value={values[0]} onChange={(e) => onChangePopular(e, 0)}
                    text="검색어1" placeholder="내용을 입력해주세요." isRequired />
        <LabelInput value={values[1]} onChange={(e) => onChangePopular(e, 1)}
                    text="검색어2" placeholder="내용을 입력해주세요." isRequired />
        <LabelInput value={values[2]} onChange={(e) => onChangePopular(e, 2)}
                    text="검색어3" placeholder="내용을 입력해주세요." isRequired />
        <LabelInput value={values[3]} onChange={(e) => onChangePopular(e, 3)}
                    text="검색어4" placeholder="내용을 입력해주세요." isRequired />
        <LabelInput value={values[4]} onChange={(e) => onChangePopular(e, 4)}
                    text="검색어5" placeholder="내용을 입력해주세요." isRequired />
        <LabelInput value={values[5]} onChange={(e) => onChangePopular(e, 5)}
                    text="검색어6" placeholder="내용을 입력해주세요." isRequired />
      </Section>
      <Divider />
      <Section>
        <TableHeading>추천 시술</TableHeading>
        <LabelInput value={recommend}  onChange={onChangeTitle}
                    text="추천 시술 제목" placeholder="제목을 입력해주세요." maxLength={200} />
        <FlexWrapper>
          <Label text="시술 등록" />
          <SmallButton type="button" onClick={onClickAdd} isMiddle isFilled>
            컨텐츠 등록
          </SmallButton>
        </FlexWrapper>
        <TableDefault dataSource={treats} columns={columns} />
      </Section>
      <Button type="submit" isFilled isBig>
        저장
      </Button>
    </form>
  );
};

export default SearchForm;
