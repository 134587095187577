import { TablePaginationConfig } from 'antd';
import TableDefault from '../../../../components/Tables/TableDefault';
import { ReservationDataWrapper, TotalAmount, TotalNum, TotalReservation } from './styles';
import { useApi } from '../../../../contexts/ApiContext';

import { useCallback, useEffect, useState } from 'react';
import InnterTableTag from '../../../../components/InnterTableTag';
import { PaymentItem, PaymentListResponse } from '../../../../model';

interface UserReservationTableProps {
  memberId: string;
}
const UserReservationTable = (props: UserReservationTableProps) => {
  const api = useApi();

  const [memberPayments, setMemberPayments] = useState<PaymentListResponse<PaymentItem>>();
  const [paymentItems, setPaymentItems] = useState<PaymentItem[]>();

  // 페이징
  const [page, setPage] = useState<number>(0);
  const [size] = useState<number>(10);

  const columns = [
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (value: string) => {
        return value === 'PAYMENT' ? (
          <InnterTableTag isPaid>결제</InnterTableTag>
        ) : (
          <InnterTableTag isCanceled>취소</InnterTableTag>
        );
      },
    },
    {
      title: '결제/취소번호',
      dataIndex: 'paymentNo',
      key: 'paymentNo',
    },
    {
      title: '금액',
      dataIndex: 'amount',
      key: 'amount',
      render: (value: number, paymentItem: PaymentItem) => {
        const paymentAmount = paymentItem.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if (paymentItem.status === 'PAYMENT') {
          return <span>{paymentAmount}</span>;
        }
        if (paymentItem.status === 'CANCELED') {
          return <span>- {paymentAmount}</span>;
        }
      },
    },
    {
      title: '결제/취소일',
      dataIndex: 'executionDate',
      key: 'executionDate',
    },
    {
      title: '방문일',
      dataIndex: 'visitedDate',
      key: 'visitedDate',
    },
    {
      title: '병원명',
      dataIndex: 'hospitalName',
      key: 'hospitalName',
    },
    {
      title: '의사/시술명',
      dataIndex: 'docTreatName',
      key: 'docTreatName',
      render: (value: string, paymentItem: PaymentItem) => {
        return (
          <span>
            {paymentItem.doctorName}/{paymentItem.treatmentName}
          </span>
        );
      },
    },
  ];

  const pagination: TablePaginationConfig = {
    position: ['bottomCenter'],
    defaultPageSize: size,
    showSizeChanger: false,
    total: memberPayments?.totalElements,
    current: page + 1,
    onChange: (page: number, pageSize: number) => {
      setPage(page - 1);
    },
  };

  const loadAppointmentList = useCallback(async () => {
    const paymentList = (await api.payment.getPaymentForMember(props.memberId, page, size)).data;

    setMemberPayments(paymentList);
  }, [api.payment, page, size, setMemberPayments]);

  useEffect(() => {
    loadAppointmentList().then().catch();
  }, [loadAppointmentList]);

  useEffect(() => {
    const itemsContainKey = memberPayments?.items.map(row => {
      return { key: row.paymentId, ...row };
    });
    if (itemsContainKey) {
      setPaymentItems(itemsContainKey);
    }
  }, [memberPayments]);

  return (
    <>
      <ReservationDataWrapper>
        <TotalReservation>
          <strong>예약 내역</strong>
          <TotalNum>
            총
            <strong>
              <span>{memberPayments?.totalElements ?? 0}</span>
            </strong>{' '}
            건
          </TotalNum>
        </TotalReservation>
        <TotalAmount>
          총 결제 금액
          <strong>
            <span>{memberPayments?.totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0}</span>원
          </strong>
        </TotalAmount>
      </ReservationDataWrapper>
      <TableDefault columns={columns} pagination={pagination} scroll={{ x: 1300 }} dataSource={paymentItems} />
    </>
  );
};

export default UserReservationTable;
