import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { HomeOutlined, SnippetsOutlined, SearchOutlined, ClusterOutlined, UserOutlined, WalletOutlined } from "@ant-design/icons";
import { StyledSider, StyledMenu } from "./styles";
import { useEffect } from "react";

interface LNBProps {
	defaultMenu?: string[];
	defaultOpenKeys?: string[];
}

// 페이지 전체 레이아웃 우측 Side Navigation Bar
const LNB = ({ defaultMenu, defaultOpenKeys }: LNBProps) => {
	const [menu, setMenu] = useState<string[] | undefined>(defaultMenu ?? []);
	const [openKeys, setOpenKeys] = useState<string[] | undefined>(defaultOpenKeys ?? []);

	const { SubMenu, Item } = Menu;

	const onClick = (e: any) => {
		setMenu([e.key]);
	};

	const onOpenChange = (openKeys: string[]) => {
		setOpenKeys(openKeys);
	};

	useEffect(() => setMenu(defaultMenu), [defaultMenu]);
	useEffect(() => setOpenKeys(defaultOpenKeys), [defaultOpenKeys]);

	return (
		<StyledSider>
			<StyledMenu mode="inline" onClick={onClick} onOpenChange={onOpenChange} selectedKeys={menu} openKeys={openKeys}>
				<Item key="1" icon={<HomeOutlined />}>
					<Link to="/">홈 화면 관리</Link>
				</Item>
				<SubMenu key="sub1" title="컨텐츠 관리" icon={<SnippetsOutlined />}>
					<Item key="2">
						<Link to="/contents/hospital">병원 관리</Link>
					</Item>
					<Item key="3">
						<Link to="/contents/doctor">의료진 관리</Link>
					</Item>
					<Item key="4">
						<Link to="/contents/treat">시술 관리</Link>
					</Item>
				</SubMenu>
				<Item key="5" icon={<SearchOutlined />}>
					<Link to="/search">검색 관리</Link>
				</Item>
				<Item key="6" icon={<ClusterOutlined />}>
					<Link to="/category">카테고리 관리</Link>
				</Item>
				<SubMenu key="sub2" title="회원 관리" icon={<UserOutlined />}>
					<Item key="7">
						<Link to="/users/userslist">회원 목록</Link>
					</Item>
					<Item key="8">
						<Link to="/users/partnerslist">파트너스 목록</Link>
					</Item>
				</SubMenu>
				<Item key="9" icon={<WalletOutlined />}>
					<Link to="/payments">결제 내역</Link>
				</Item>
			</StyledMenu>
		</StyledSider>
	);
};

export default React.memo(LNB);
