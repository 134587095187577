import React, { useEffect, useMemo } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import LogIn from './pages/LogIn';
import Contents from './pages/Contents';
import Hospital from './pages/Contents/Hospital';
import Doctor from './pages/Contents/Doctor';
import Treat from './pages/Contents/Treat';
import HospitalEdit from './pages/Contents/Hospital/HospitalEdit';
import DoctorEdit from './pages/Contents/Doctor/DoctorEdit';
import TreatEdit from './pages/Contents/Treat/TreatEdit';
import Search from './pages/Search';
import Category from './pages/Category';
import Users from './pages/Users/';
import UsersList from './pages/Users/UsersList';
import PartnersList from './pages/Users/PartnersList';
import Payments from './pages/Payments';

import AuthContextProvider from './helpers/AuthProvider';
import ApiContextProvider from './helpers/ApiProvider';
import { useAuth } from './contexts/AuthContext';

import jwt_decode from 'jwt-decode';
import { LoginUser } from './model';

function RequireAuth({ children }: { children: JSX.Element }) {
	let auth = useAuth();
	let location = useLocation();

	const accessToken = useMemo(() => {
		return auth?.accessToken;
	}, [auth]);

	useEffect(() => {
		if (accessToken && !auth.user) {
			auth.setUser(jwt_decode<LoginUser>(accessToken));
		}
	}, [accessToken, auth]);

	if (!auth.accessToken) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
}

function App() {
	return (
		<ApiContextProvider>
			<AuthContextProvider>
				<Routes>
					<Route path="/login" element={<LogIn />} />
					<Route
						path="/"
						element={
							<RequireAuth>
								<Home />
							</RequireAuth>
						}
					/>
					<Route
						path="/contents"
						element={
							<RequireAuth>
								<Contents />
							</RequireAuth>
						}
					>
						<Route
							path="hospital"
							element={
								<RequireAuth>
									<Hospital />
								</RequireAuth>
							}
						/>
						<Route
							path="hospital/:id"
							element={
								<RequireAuth>
									<HospitalEdit />
								</RequireAuth>
							}
						/>
						<Route
							path="doctor"
							element={
								<RequireAuth>
									<Doctor />
								</RequireAuth>
							}
						/>
						<Route
							path="doctor/:id"
							element={
								<RequireAuth>
									<DoctorEdit />
								</RequireAuth>
							}
						/>
						<Route
							path="doctor/write/:id"
							element={
								<RequireAuth>
									<DoctorEdit />
								</RequireAuth>
							}
						/>
						<Route
							path="treat"
							element={
								<RequireAuth>
									<Treat />
								</RequireAuth>
							}
						/>
						<Route
							path="treat/:id"
							element={
								<RequireAuth>
									<TreatEdit />
								</RequireAuth>
							}
						/>
						<Route
							path="treat/write/:id"
							element={
								<RequireAuth>
									<TreatEdit />
								</RequireAuth>
							}
						/>
					</Route>
					<Route
						path="/search"
						element={
							<RequireAuth>
								<Search />
							</RequireAuth>
						}
					/>
					<Route
						path="/category"
						element={
							<RequireAuth>
								<Category />
							</RequireAuth>
						}
					/>
					<Route
						path="/users"
						element={
							<RequireAuth>
								<Users />
							</RequireAuth>
						}
					>
						<Route
							path="userslist"
							element={
								<RequireAuth>
									<UsersList />
								</RequireAuth>
							}
						/>
						<Route
							path="partnerslist"
							element={
								<RequireAuth>
									<PartnersList />
								</RequireAuth>
							}
						/>
					</Route>
					<Route
						path="/payments"
						element={
							<RequireAuth>
								<Payments />
							</RequireAuth>
						}
					/>
				</Routes>
			</AuthContextProvider>
		</ApiContextProvider>
	);
}

export default App;
