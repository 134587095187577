import React, {
  ChangeEventHandler,
  FormEventHandler, forwardRef,
  MouseEventHandler,
  ReactNode,
  useImperativeHandle,
  useState,
} from 'react';
import Select from '../Inputs/Select';
import {
  Wrapper,
  Form,
  Divider,
  UpperWrapper,
  LowerWrapper,
  Title,
  Input,
  StyledRangePicker,
  StyledRadioButton,
  StyledRadioGroup,
  ButtonWrapper,
  SearchButton,
} from './styles';
import { SearchOutlined, RedoOutlined } from '@ant-design/icons';
import { Moment } from 'moment';
import { EventValue, RangeValue } from 'rc-picker/lib/interface';
import { SearchPeriod } from '../../model';
import { RadioChangeEvent } from 'antd';
import moment from 'moment';

interface SearchBoxProps {
  title?: string;
  optionList?: any[];
  optionValue?: any;
  onChangeOption?: ChangeEventHandler<HTMLSelectElement>;
  titleUnder?: ReactNode;
  optionListUnder?: any[];
  onChangeUnderOption?: ChangeEventHandler<HTMLSelectElement>;
  startDate?: EventValue<Moment>;
  endDate?: EventValue<Moment>;
  onChangeDate?: (values: RangeValue<Moment>) => void;
  onSubmit?: FormEventHandler;
  searchValue?: string;
  onChangeSearch?: ChangeEventHandler<HTMLInputElement>;
  allowClear: boolean;
  isReset?: boolean;
  onReset?: MouseEventHandler<HTMLButtonElement>;
}

const SearchBox = forwardRef((props: SearchBoxProps, ref) => {
  const [period, setPeriod] = useState<SearchPeriod>(SearchPeriod.ALL);

  useImperativeHandle(ref, () => ({
    setPeriod
  }));

  const onChangePeriod = (e: RadioChangeEvent) => {
    const period: SearchPeriod = e.target.value;
    if (props.onChangeDate) {
      let startDate = moment();
      let endDate = moment();
      switch (period) {
        case SearchPeriod.TODAY: {
          startDate = moment();
          endDate = moment();
          break;
        }
        case SearchPeriod.WEEK: {
          startDate = moment().subtract(1, 'week');
          endDate = moment();
          break;
        }
        case SearchPeriod.MONTH: {
          startDate = moment().subtract(1, 'month');
          endDate = moment();
          break;
        }
        case SearchPeriod.MONTHS: {
          startDate = moment().subtract(6, 'month');
          endDate = moment();
          break;
        }
        case SearchPeriod.ALL: {
          startDate = moment('2022-01-01');
          endDate = moment();
          break;
        }
      }

      props.onChangeDate([startDate, endDate]);
    }
    setPeriod(period);
  };



  return (
    <>
      <Wrapper isReset={props.isReset}>
        <Form onSubmit={props.onSubmit}>
          <div>
            <UpperWrapper>
              {props.optionList ? (
                <Select
                  optionList={props.optionList}
                  value={props.optionValue}
                  onChange={props.onChangeOption}
                  style={{ margin: '0 15px 0 0', minWidth: '200px' }}
                />
              ) : (
                <Title>
                  <h1>{props.title}</h1>
                </Title>
              )}
              <Input
                type="text"
                placeholder="검색어를 입력하세요."
                value={props.searchValue}
                onChange={props.onChangeSearch}
              />
            </UpperWrapper>

            <LowerWrapper>
              <div>
                {props.optionListUnder ? (
                  <Select
                    optionList={props.optionListUnder}
                    onChange={props.onChangeUnderOption}
                    style={{ margin: '0 15px 0 0', minWidth: '200px' }}
                  />
                ) : (
                  <Title isReset={props.isReset}>
                    <h1>{props.titleUnder}</h1>
                  </Title>
                )}
                <StyledRangePicker
                  allowClear={props.allowClear}
                  allowEmpty={[props.allowClear, props.allowClear]}
                  value={props.startDate && props.endDate ? [props.startDate, props.endDate] : null}
                  onChange={props.onChangeDate}
                />
              </div>

              <StyledRadioGroup value={period}  onChange={onChangePeriod}>
                <StyledRadioButton value={SearchPeriod.TODAY}>오늘</StyledRadioButton>
                <StyledRadioButton value={SearchPeriod.WEEK}>1주일</StyledRadioButton>
                <StyledRadioButton value={SearchPeriod.MONTH}>1개월</StyledRadioButton>
                {/* 버튼 비활성화가 필요한 경우, 'disabled'를 넣어주시면 됩니다.*/}
                <StyledRadioButton value={SearchPeriod.ALL}>전체</StyledRadioButton>
              </StyledRadioGroup>
            </LowerWrapper>
          </div>
          <Divider />
          <ButtonWrapper isReset={props.isReset}>
            <SearchButton type="submit">
              <span>검색</span>
              <SearchOutlined style={{ fontSize: '19px' }} />
            </SearchButton>
            {props.isReset && (
              <SearchButton type="button" isReset={true} onClick={props.onReset}>
                <span>초기화</span>
                <RedoOutlined style={{ fontSize: '19px' }} />
              </SearchButton>
            )}
          </ButtonWrapper>
        </Form>
      </Wrapper>
    </>
  );
});

export default React.memo(SearchBox);
