import React from 'react';
import { FileSearchOutlined } from "@ant-design/icons";
import { Wrapper } from "./styles";
import { StyledButton } from "../styles";
import { MouseEventHandler } from "react";

interface DetailButtonProps {
	onClick?: MouseEventHandler;
}

const DetailButton = ({ onClick }: DetailButtonProps) => {
	return (
		<Wrapper>
			<StyledButton type="button" onClick={onClick}>
				<FileSearchOutlined style={{ fontSize: "18px" }} />
			</StyledButton>
		</Wrapper>
	);
};

export default React.memo(DetailButton);
