import { TreatmentCategoryRequest, CreateDepartmentResponse, DataResponse, DepartmentRequest, DepartmentWithCategoryList, ModifyResponse, ShiftDirection, TreatmentCategoryItem, CreateTreatmentCategoryResponse, ResultResponse, DepartmentItem } from '../model';
import api from './index';

const Category = {
  getDepartments: () => {
    return api.get<ResultResponse<DepartmentItem[]>>('/departments');
  },
  swapDepartment: (departmentId: string, direction: ShiftDirection) => {
    return api.put<ModifyResponse>(`/departments/${departmentId}/display-order`, {direction: direction});
  },
  modifyDepartment: (departmentId: string, data: DepartmentRequest) => {
    return api.put<ModifyResponse>(`/departments/${departmentId}`, data);
  },
  addDepartment: (data: DepartmentRequest) => {
    return api.post<CreateDepartmentResponse>('/departments', data);
  },
  getAllCategories: () => {
    return api.get<DataResponse<DepartmentWithCategoryList[]>>('/departments/all/categories');
  },
  getCategories: (departmentId: string) => {
    return api.get<DataResponse<TreatmentCategoryItem[]>>(`/departments/${departmentId}/categories`);
  },
  swapCategory: (departmentId: string, categoryId: string, direction: ShiftDirection) => {
    return api.put<ModifyResponse>(`/departments/${departmentId}/categories/${categoryId}/display-order`, {direction: direction});
  },
  modifyCategory: (departmentId: string, categoryId: string, data: TreatmentCategoryRequest) => {
    return api.put<ModifyResponse>(`/departments/${departmentId}/categories/${categoryId}`, data);
  },
  addCategory: (departmentId: string, data: TreatmentCategoryRequest) => {
    return api.post<CreateTreatmentCategoryResponse>(`/departments/${departmentId}/categories`, data);
  },
};

export default Category;