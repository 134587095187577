import styled from 'styled-components';
import { flexbox, fontStyle12, fontStyle18, fontStyle22, buttonBase } from '../../styles/mixins';

export const Wrapper = styled.div`
  ${flexbox('space-between', 'center')};
`;

export const HeadingWrapper = styled.div<{ isSub?: boolean }>`
  ${flexbox('flex-start', 'flex-end')};
  margin: ${props => (props.isSub ? '0px 25px 10px' : '20px 0 30px')};
`;

export const Heading = styled.h2<{ isSub?: boolean }>`
  ${fontStyle22};
  ${props => (props.isSub ? fontStyle18 : fontStyle22)};
  font-weight: 500;
`;

export const HelperText = styled.span`
  ${fontStyle12};
  font-weight: 500;
  color: ${props => props.theme.red};
  margin: 0 0 0 5px;
`;

export const Button = styled.button<{ isFilled?: boolean; isMiddle?: boolean }>`
  ${buttonBase};
  width: 110px;
  height: 36px;
  margin: auto 80px 0 0;
`;
