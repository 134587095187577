import styled from 'styled-components';
import { flexbox, flexboxColumn, fontStyle12 } from '../../../styles/mixins';

export const StyledLabelInputTranslate = styled.div`
  ${flexbox('space-between')}
  position: relative;
`;

export const FlexWrapper = styled.div`
  ${flexbox('flex-start', 'flex-start')};
  width: 100%;
  position: relative;
  padding-bottom: 20px;
`;

export const InputGroupWrapper = styled.div`
  ${flexboxColumn('flex-start', 'flex-start')}
  width: 100%;
`;

export const InputWrapper = styled.div`
  position: relative;
  ${flexbox('flex-start', 'flex-start')};
  width: 100%;
  position: relative;
  padding-bottom: 20px;
`;

export const HelperText = styled.p`
  ${fontStyle12};
  color: ${props => props.theme.grey};
  position: absolute;
  bottom: 45px;
  left: 0;
`;
