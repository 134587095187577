import styled, { css } from 'styled-components';
import { fontStyle14 } from '../../styles/mixins';

export const StyledTag = styled.div<{ isRegistered?: boolean; isCanceled?: boolean; isPaid?: boolean }>`
  ${fontStyle14};
  width: 50px;
  height: 26px;
  padding: 2px 5px;
  margin: 0 auto 3px;
  border-radius: 4px;
  text-align: center;
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.tagDisabled_bg};

  ${props =>
    props.isRegistered &&
    css`
      background-color: ${props.theme.tag_bg};
    `}

  ${props =>
    props.isCanceled &&
    css`
      background-color: ${props.theme.red};
    `}

  ${props =>
    props.isPaid &&
    css`
      background-color: #63ceab;
    `}
`;
