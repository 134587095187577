// 6-1. 회원 관리 > 6-1-3. 회원 상세  팝업
import moment from "moment";
import { Gender, MemberDetail } from "../../../../model";
import { Table } from "./styles";

interface Props {
	member?: MemberDetail;
}

const dateFormat = "YYYY.MM.DD";

const UserDetailTable = (props: Props) => {

	const getMemberTypeText = (param: string | undefined) => {
		return param === "NORMAL" ? "일반회원" : "소셜회원";
	}

	const getSocialRouteText = (param: string | undefined) => {
		switch (param) {
			case 'NORMAL':
				return '일반회원';
			case 'KAKAO':
				return '카카오';
			case 'NAVER':
				return '네이버';
			case 'APPLE':
				return '애플';
			case 'GOOGLE':
				return '구글';
			case 'WECHAT':
				return '위챗';
			default:
				return '';
		}
	}

	const englishName = (member: MemberDetail | undefined) => {
		if(member?.usernameEn) {
			return '(' + member?.usernameEn + ')';
		}
	}

	const isLeave = (param: MemberDetail | undefined) => {
		if(param?.resignedAt) {
			return '(탈퇴회원)';
		}
	}

	return (
		<Table>
			<tbody>
				<tr>
					<th>
						<strong>성명</strong>
					</th>
					<td>{props.member?.username}{englishName(props.member)}</td>
					<th>국적</th>
					<td>{props.member?.locale}</td>
				</tr>
				<tr>
					<th>가입일</th>
					<td>{moment(props.member?.createdAt).format(dateFormat)}</td>
					<th>탈퇴일</th>
					<td>{(props.member?.resignedAt) ? moment(props.member?.resignedAt).format(dateFormat) : "-"}</td>
				</tr>
				<tr>
					<th>생년월일</th>
					<td>{(props.member?.birthday !== "") ? moment(props.member?.birthday, "YYYY-MM-DD").format(dateFormat) : ""}</td>
					<th>성별</th>
					<td>{(props.member?.gender === Gender.FEMALE) ? "여성" : "남성"}</td>
				</tr>
				<tr>
					<th>이메일</th>
					<td>{props.member?.email}</td>
					<th>전화번호</th>
					<td>{props.member?.countryNumber} {props.member?.contact}</td>
				</tr>
				<tr>
					<th>회원구분</th>
					<td>{getMemberTypeText(props.member?.memberType)}{isLeave(props.member)}</td>
					<th>소셜루트</th>
					<td>{getSocialRouteText(props.member?.memberType)}</td>
				</tr>
			</tbody>
		</Table>
	);
};

export default UserDetailTable;
