import styled from "styled-components";

export interface DivProps {
	isSub?: Boolean;
}

export const Div = styled.div<DivProps>`
	height: 1px;
	width: 100%;
	margin: ${(props) => (props.isSub ? "50px 0" : "30px 0 90px")};
	background-color: ${(props) => (props.isSub ? props.theme.subDivider_bg : props.theme.divider_bg)};
`;
