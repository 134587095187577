import styled from "styled-components";
import "antd/dist/antd.css";
import { Layout } from "antd";
import { fontStyle16, flexbox, buttonBase } from "../../styles/mixins";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";

export const Header = styled(Layout.Header)`
  ${flexbox("space-between", "center")};
  position: fixed;
  z-index: 20;
  width: 100%;
  background-color: ${(props) => props.theme.white};
  box-shadow: 0 2px 8px #f0f1f2;
`;

export const StyledLogo = styled(Link)`
  display: block;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  background-image: url(${logo});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const FlexWrapper = styled.div`
  ${flexbox("center", "center")};
`;

export const AdminName = styled.h1`
  ${fontStyle16};
  font-weight: 500;
  color: ${(props) => props.theme.primary};
  margin: 0 20px 0 0;
`;

export const Button = styled.button`
  ${buttonBase};
  height: 35px;
`;

