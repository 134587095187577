import React from 'react';
import Label from '../Label';
import Input from '../Input';
import { FlexWrapper, HelperText } from './styles';
import { ChangeEventHandler, ReactNode } from 'react';
import { InputProps } from 'antd';

interface LabelInputProps extends InputProps {
	text: string;
	placeholder?: string;
	isRequired?: boolean;
	value?: string | ReadonlyArray<string> | number | undefined;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	isRequiredEmpty?: boolean;
	isCost?: boolean;
	isPopup?: boolean;
	maxLength?: number;
	hasHelperText?: boolean;
	children?: ReactNode;
	disabled?: boolean;
}

const LabelInput = ({ type, text, placeholder, isRequired, value, onChange, onKeyDown, isRequiredEmpty, isCost, isPopup, maxLength, hasHelperText, children, disabled }: LabelInputProps) => {
	return (
		<>
			<FlexWrapper isPopup={isPopup}>
				<Label text={text} isRequired={isRequired} isPopup={isPopup} />
				{hasHelperText && <HelperText>{children}</HelperText>}
				<Input
					type={type}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					onKeyDown={onKeyDown}
					maxLength={maxLength}
					isCost={isCost}
					isRequiredEmpty={isRequiredEmpty}
					isPopup={isPopup}
					disabled={disabled}
				/>
			</FlexWrapper>
		</>
	);
};

export default React.memo(LabelInput);
