import styled from "styled-components";
import { buttonBase, flexbox } from "../../../styles/mixins";

export const StyledLayout = styled.div`
	@media (min-width: 821px) {
		margin: 0 30px;
	}

	@media screen and (min-width: 1025px) {
		margin: 0 80px;
	}
`;

export const FlexWrapper = styled.div`
	${flexbox("space-between", "flex-end")};
	margin: 0 0 10px 0;

	strong {
		font-weight: 700;
	}

	.ant-select {
		margin: 0;
	}
`;

export const InputWrapper = styled.div`
	${flexbox("flex-start", "center")};
	margin: 0;
`;

export const Button = styled.button<{isFilled?: boolean, isSmall?: boolean}>`
	${buttonBase};
	width: 62px;
	height: 42px;
	margin: 0 0 25px 10px;
`;
