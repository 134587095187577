import { CameraFilled } from '@ant-design/icons';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import ImageWithButton from '../ImageWithButton';
import { Wrapper, ButtonWrapper, HelperText, TextWrapper } from './styles';
import nextId from 'react-id-generator';
import { convertToHeic } from '../../helpers/FileHelper';

interface ImageButtonProps {
	isRequiredEmpty?: boolean;
	notext?: boolean;
	url?: string;
	onChange?: (file: File | undefined) => void;
	isDisabled?: boolean;
}

// 필수 입력값인 사진이 없는 경우 'isRequiredEmpty' props를 넣어줍니다.
// 사진 업로드 안내 문구가 필요 없는 경우, 'notext' props를 넣어줍니다.
const AddImageButton: React.FC<ImageButtonProps> = (props) => {
	const id = nextId();
	const [file, setFile] = useState<File>();
	const [url, setUrl] = useState(props.url);

	// 이미지를 선택한 경우
	const onChange = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			let file = event.target?.files?.[0] ?? null;

			if (file && file.type.startsWith('image')) {
				if (/heic/.test(file.type)) {
					file = await convertToHeic(file);
				}

				setFile(file);
				setUrl(URL.createObjectURL(file));
			}

			if (props.onChange) {
				props.onChange(file ?? undefined);
			}
		},
		[props]
	);

	// 이미지 삭제
	const onClose = useCallback(() => {
		setFile(undefined);
		setUrl(undefined);
		if (props.onChange) {
			props.onChange(undefined);
		}
	}, [props]);

	useEffect(() => {
		setUrl(props.url);
	}, [props.url]);

	if (!file && !url) {
		return (
			<Wrapper>
				<ButtonWrapper>
					<label htmlFor={id}>
						<CameraFilled style={{ fontSize: '40px' }} />
						<p>사진 추가</p>
					</label>
					<input value={file} type="file" id={id} onChange={onChange} />
					{props.isRequiredEmpty && <HelperText>필수 입력 값입니다.</HelperText>}
				</ButtonWrapper>
				{!props.notext && (
					<TextWrapper>
						<p>* 대표 사진은 1080 X 720px에 최적화</p>
						<p>* 한 장당 10MB 이하, 최대 1장</p>
					</TextWrapper>
				)}
			</Wrapper>
		);
	} else {
		return (
			<Wrapper>
				{/* 사진을 등록한 경우, 우측 상단 삭제 버튼이 있는 이미지 - 기획서 2-1 오늘의 추천 관리(등록 후) 6-7번*/}
				<ImageWithButton src={url} onClose={onClose} isDisabled={props.isDisabled} />
			</Wrapper>
		);
	}
};

export default React.memo(AddImageButton);
