import styled from "styled-components";
import "antd/dist/antd.css";
import { Select } from "antd";
import { flexbox, fontStyle14, fontStyle15 } from "../../../styles/mixins";

export const StyledSelect = styled(Select)`
	${flexbox("center", "center")};
	${fontStyle15};
	color: ${(props) => props.theme.primary};
	height: 42px;
	min-width: 120px;
	margin: 0 0 25px 0;
	border-radius: 4px;

	&:not(.ant-select-customize-input) .ant-select-selector {
		height: 42px;
		border: 1px solid ${(props) => props.theme.inputBorder};
		border-radius: 4px;
		transition: border-color 200ms ease-in-out;
	}

	&:not(.ant-select-customize-input) .ant-select-selector:hover {
		border: 1px solid ${(props) => props.theme.inputBorderHover};
	}

	&:not(.ant-select-customize-input) .ant-select-selector:active,
	&:not(.ant-select-customize-input) .ant-select-selector:focus {
		border: 1px solid ${(props) => props.theme.inputBorderFocus};
	}

	&.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border: 1px solid ${(props) => props.theme.inputBorderFocus};
		box-shadow: unset;
	}

	.ant-select-selection-item {
		position: relative;
		top: 3px;
	}
`;

export const StyledOption = styled(Select.Option)``;

export const HelperWrapper = styled.div`
	height: 25px;
`;

export const HelperText = styled.p`
	${fontStyle14};
	font-weight: 500;
	color: ${(props) => props.theme.red};
`;
