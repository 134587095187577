import React from 'react';
import Label from '../Label';
import moment, { Moment } from 'moment';
import { Radio, RadioChangeEvent, RadioProps } from 'antd';
import 'antd/dist/antd.css';
import { Container, FlexWrapper, RadioWrapper, StyledRadio, StyledPicker } from './styles';
import { RangeValue } from 'rc-picker/lib/interface';

interface LabelRadioTimeProps extends RadioProps {
	text?: string;
	isRequired?: boolean;
	isBreaktime?: boolean;
	value?: any;
	onChange?: (e: RadioChangeEvent) => void;
	onChangeTime?: (values: RangeValue<Moment>) => void;
	start?: string;
	end?: string;
	disabled?: boolean;
}

const rangePickerFormat = 'HH:mm';

const LabelRadioTime = (props: LabelRadioTimeProps) => {
	const localTimeToMoment = (time?: string): Moment => {
		return time ? moment(time, 'HH:mm:ss') : moment();
	};

	return (
		<Container>
			<Label text={props.text ?? ''} isRequired={props.isRequired} />
			<FlexWrapper>
				<RadioWrapper>
					{props.isBreaktime ? (
						<Radio.Group value={props.value}>
							<StyledRadio onClick={props.onClick} value={true} disabled={props.disabled}>
								점심시간에도 정상진료
							</StyledRadio>
							<StyledRadio onClick={props.onClick} style={{ visibility: 'hidden' }} value={false} disabled={props.disabled}>
								점심시간에도 정상진료
							</StyledRadio>
						</Radio.Group>
					) : (
						<Radio.Group onChange={props.onChange} value={props.value}>
							<StyledRadio value={true} disabled={props.disabled}>
								진료함
							</StyledRadio>
							<StyledRadio value={false} disabled={props.disabled}>
								진료안함(휴진)
							</StyledRadio>
						</Radio.Group>
					)}
				</RadioWrapper>
				<StyledPicker
					format={rangePickerFormat}
					onChange={props.onChangeTime}
					value={[localTimeToMoment(props.start), localTimeToMoment(props.end)]}
					minuteStep={10}
					disabled={(props.isBreaktime && props.value) || (!props.isBreaktime && !props.value) || props.disabled}
				/>
			</FlexWrapper>
		</Container>
	);
};

export default React.memo(LabelRadioTime);
