import React from 'react';
import { EditOutlined } from "@ant-design/icons";
import { MouseEventHandler } from "react";
import { StyledButton } from "../styles";

interface EditButtonProps {
	onClick?: MouseEventHandler;
}

const EditButton = ({ onClick }: EditButtonProps) => {
	return (
		<StyledButton type="button" onClick={onClick}>
			<EditOutlined style={{ fontSize: "18px" }} />
		</StyledButton>
	);
};

export default React.memo(EditButton);
