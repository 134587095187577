import { Divider } from 'antd';
import { weekdays } from 'moment';
import AddImageButton from '../../../../../components/AddImageButton';
import ContentHeading from '../../../../../components/ContentHeading';
import { SubDivider } from '../../../../../components/Divider';
import Label from '../../../../../components/Inputs/Label';
import LabelInput from '../../../../../components/Inputs/LabelInput';
import LabelRadioTime from '../../../../../components/Inputs/LabelRadioTime';
import LabelText from '../../../../../components/Inputs/LabelText';
import LabelTextarea from '../../../../../components/Inputs/LabelTextarea';
import LabelToggle from '../../../../../components/Inputs/LabelToggle';
import { TreatmentPeriodItem } from '../../../../../model';
import { Section, ImageWrapper, Button } from '../styles';
import {ChangeEvent, ChangeEventHandler} from "react";

interface FormEngProps {
	value?: string;
	display?: boolean;
	defaultValue?: string;
	nodata?: any;
	intro?: string;
	introLong?: string;
	map?: string;
	representative?: string;
	weekend?: any;
	breaktime?: any;
	weekdays?: any;
	departments?: any;
	treatmentCategories?: any;
	tel?: string;
	address?: string;
	significant?: string;
	onChangeAddress: ChangeEventHandler<HTMLInputElement>;
	onChangeIntro: ChangeEventHandler<HTMLInputElement>;
	onChangeIntroLong: ChangeEventHandler<HTMLInputElement>;
	onChangeSignificant: ChangeEventHandler<HTMLInputElement>;
}

const HospitalEditTranslate = ({ onChangeAddress, onChangeIntro, onChangeIntroLong, onChangeSignificant,
																 tel, display, value, defaultValue, address, departments, treatmentCategories,
																 intro, introLong, map, representative, weekend, breaktime, weekdays, significant }: FormEngProps) => {
	return (
		<>
			<ContentHeading isRequired>기본 정보</ContentHeading>
			<Section>
				<LabelToggle text="노출" checked={display} disabled />
				{value ? <LabelInput text="병원명" value={value} disabled/> : <LabelInput text="병원명" value={defaultValue} disabled />}
				<LabelText text="진료과" nodata={departments.length <= 0}>
					{departments.length <= 0 ? <>등록된 진료과가 없습니다. 시술을 등록해주세요</> : departments.toString()}
				</LabelText>
				<LabelText text="시술 카테고리" nodata={treatmentCategories.length <= 0}>
					{treatmentCategories.length <= 0 ? <>등록된 시술 카테고리가 없습니다. 시술을 등록해주세요.</> : treatmentCategories.toString()}
				</LabelText>
				<LabelInput text="대표 전화" value={tel} placeholder="(-)없이 숫자만 입력해주세요." isRequired maxLength={200} disabled />
				<LabelInput text="주소" placeholder={address} value={address} onChange={onChangeAddress} isRequired maxLength={200} />
				<ImageWrapper>
					<Label text="주소 지도 사진" isRequired />
					<AddImageButton url={map} isDisabled />
				</ImageWrapper>
			</Section>
			<Divider />
			<Section>
				<ContentHeading>병원 소개</ContentHeading>
				<LabelInput text="한줄 소개" placeholder={intro} value={intro} onChange={onChangeIntro} isRequired />
				<LabelTextarea text="소개 내용" placeholder={introLong} value={introLong} onChange={onChangeIntroLong} isRequired />
				<ImageWrapper>
					<Label text="대표 사진" isRequired />
					<AddImageButton url={representative} isDisabled />
				</ImageWrapper>
			</Section>
			<Divider />
			<Section>
				<ContentHeading isRequired>진료 시간</ContentHeading>
				{weekdays.map((item: TreatmentPeriodItem, i: number) => (
					<LabelRadioTime key={i} text={item.dayOfWeek} value={item.treatment} start={item.opensAt} end={item.closesAt} isRequired disabled />
				))}
				<SubDivider />
				{weekend.map((item: TreatmentPeriodItem, i: number) => (
					<LabelRadioTime key={i + 5} text={item.dayOfWeek} value={item.treatment} start={item.opensAt} end={item.closesAt} isRequired disabled />
				))}
				<SubDivider />
				{breaktime.map((item: TreatmentPeriodItem, i: number) => (
					<LabelRadioTime key={i + 7} text={item.dayOfWeek} value={item.treatment} start={item.opensAt} end={item.closesAt} isBreaktime disabled />
				))}
				<SubDivider />
				<LabelInput text="진료특이사항(선택)" placeholder="예) 매주 첫째주 월요일 휴무입니다." value={significant} onChange={onChangeSignificant} maxLength={200} />
				<Divider />
			</Section>
			<Button type="submit" isFilled isBig>
				저장
			</Button>
		</>
	);
};

export default HospitalEditTranslate;
