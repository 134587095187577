import styled from 'styled-components';
import { buttonBase, flexbox, fontStyle18, fontStyle24 } from '../../../../../../styles/mixins';
import { Link } from 'react-router-dom';

export const Layout = styled.div`
  @media (min-width: 821px) {
    margin: 0 30px;
  }

  @media screen and (min-width: 1025px) {
    margin: 0 70px;
  }

  h2 {
    ${fontStyle24};
    font-weight: 700;
    margin-top: -20px;
  }
`;

export const ButtonWrapper = styled.div`
  ${flexbox('center', 'center')};
`;

export const ImageWrapper = styled.div`
  display: flex;
  margin: 0 0 70px 0;
`;

export const StyledLink = styled(Link)`
	${flexbox('center', 'center')};
	${fontStyle18};
	padding: 0 15px;
	border-radius: 4px;
	text-align: center;
	width: 260px;
	height: 52px;
	margin:0 10px 0 0;
	background-color: ${props => props.theme.white};
	border: 1px solid #E3B476;
	color: ${props => props.theme.accent};

	transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out;

	&:hover {
		color: ${props => props.theme.accent};
		border: 1px solid ${props => props.theme.accentLight};
		background-color: ${props => props.theme.menuItem_bg};
	}

	&:active {
		border: 1px solid ${props => props.theme.accentLight};
		background-color: ${props => props.theme.submenuItem_bg};
	}

	&:focus {
    border: 1px solid ${props => props.theme.accent}
		background-color: ${props => props.theme.accentLight};
	}

	&:disabled{
		cursor:not-allowed;
		opacity:0.4;
	}
`;

export const Button = styled.button<{ isBig?: boolean; isFilled?: boolean }>`
  ${buttonBase};
  width: 260px;
  height: 52px;
`;
