import { Layout } from "antd";
import GNB from "../../layouts/GNB";
import LNB from "../../layouts/LNB";
import { StyledLayout } from "./styles";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const Users = () => {
	const { pathname } = useLocation();
	const [menu, setMenu] = useState<string[]>();

	useEffect(() => {
		let menu: string[] = [];
		if (pathname.startsWith("/users/userslist")) {
			menu = ["7"];
		} else if (pathname.startsWith("/users/partnerslist")) {
			menu = ["8"];
		}

		setMenu(menu);
	}, [pathname]);

	return (
		<>
			<Layout>
				<GNB />
				<Layout>
					<LNB defaultMenu={menu} defaultOpenKeys={["sub2"]} />
					<StyledLayout>
						<Outlet />
					</StyledLayout>
				</Layout>
			</Layout>
		</>
	);
};

export default Users;
