import React from 'react';
import { StyledTable } from './styles';
import { TableProps } from 'antd';

export interface TableDefaultProps extends TableProps<any> {
	isWidePopup?: boolean;
}

const TableDefault = ({ columns, dataSource, scroll, pagination, isWidePopup }: TableDefaultProps) => {
	//  showSorterTooltip={false} 를 넣고, locale를 삭제하시면 tooltip이 제공되지 않습니다.
	return (
		<StyledTable
			columns={columns}
			dataSource={dataSource}
			pagination={pagination ? pagination : false}
			scroll={scroll}
			isWidePopup={isWidePopup}
			locale={{
				triggerDesc: '내림차순',
				triggerAsc: '오름차순',
				cancelSort: '정렬 취소',
			}}
		/>
	);
};

export default React.memo(TableDefault);
