import { ChangeResponse, CreateResponse, DeleteResponse, DoctorDetailItem, DoctorInventory, DoctorSearchTermsOption, ModifyResponse, RecommendedResponse, RequestDoctorCreate, RequestDoctorModify, SearchType } from '../model';
import api from './index';
import Qs from 'qs';

const Doctor = {
  getDoctors: (
    page: number = 0,
    size: number = 25,
    departmentId?: string,
    searchTermsType?: DoctorSearchTermsOption,
    searchTerms?: string,
    dateSearchType?: SearchType,
    start?: string,
    end?: string,
  ) => {
    return api.get<DoctorInventory>(
      '/admin/doctors',
      {
        params: {
          page: page,
          size: size,
          searchTermsType: searchTermsType,
          searchTerms: searchTerms,
          dateSearchType: dateSearchType,
          start: start,
          end: end,
          departmentId: departmentId,
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    );
  },
  getActiveDoctors: (
    page: number = 0,
    size: number = 25,
    searchTermsType?: DoctorSearchTermsOption,
    searchTerms?: string,
  ) => {
    return api.get<DoctorInventory>(
      '/admin/doctors.search',
      {
        params: {
          pageNumber: page,
          pageSize: size,
          searchTermsType: searchTermsType,
          searchTerms: searchTerms
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    );
  },
  getDoctor: (doctorId: string, region: string) => {
    return api.get<DoctorDetailItem>(`/admin/doctors/${doctorId}/${region}`);
  },
  addDoctor: (data: RequestDoctorCreate) => {
    return api.post<CreateResponse>('/admin/doctor', data);
  },
  modifyDoctor: (doctorId: string, data: RequestDoctorModify, region: string) => {
    return api.put<ModifyResponse>(`/admin/doctors/${doctorId}/${region}`, data);
  },
  modifyDoctorDisplay: (doctorId: string, display: boolean) => {
    return api.put<ChangeResponse>(
      `/admin/doctors/${doctorId}/display?display=${display}`, 
    );
  },
  deleteDoctor: (doctorId: string) => {
    return api.delete<DeleteResponse>(`/admin/doctors/${doctorId}`);
  },
  checkRecommendedDoctor: (doctorId: string) => {
    return api.get<RecommendedResponse>(`/admin/doctors/${doctorId}/recommend/status`);
  }
};

export default Doctor;