import React from "react";

import Authentication from "../api/Authentication";
import Doctor from "../api/Doctor";
import Home from "../api/Home";
import Hospital from "../api/Hospital";
import Treatment from "../api/Treatment";
import File from "../api/File";
import Category from "../api/Category";
import Search from "../api/Search";
import Partners from "../api/Partners";
import Member from "../api/Member";
import Payment from '../api/Payment';

export interface ApiContextType {
  authentication: typeof Authentication;
  doctor: typeof Doctor;
  home: typeof Home;
  hospital: typeof Hospital;
  treatment: typeof Treatment;
  file: typeof File;
  category: typeof Category;
  search: typeof Search;
  partners: typeof Partners,
  member: typeof Member,
  payment: typeof Payment,
}

export let ApiContext = React.createContext<ApiContextType>(null!);

export const useApi = () => {
  return React.useContext(ApiContext);
}