import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";
import SearchBox from "../../../../components/SearchBox";
import DetailButton from "../../../../components/InnerTdButton/DetailButton";
import DefaultPopup from "../../../../components/Popup/DefaultPopup";
import TableDefault from "../../../../components/Tables/TableDefault";
import UserDetailTable from "../UsersDetailTable";
import { SubHeading } from "../CurrentUsers/styles";
import { TotalNum } from "../styles";
import { Section } from "../../styles";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { useApi } from "../../../../contexts/ApiContext";
import { ItemDefault } from "../../../../components/Inputs/Select";
import { Gender, ItemsResponse, MemberDetail, MemberInventoryItem, ResignedMemberSearchOptions } from "../../../../model";
import { EventValue, RangeValue } from "rc-picker/lib/interface";
import moment, { Moment } from "moment";
import { Divider } from 'antd';
import UserReservationTable from '../UsersReservationTable';

const dateFormat = "YYYY-MM-DD";

const DeactivatedUsers = () => {
	const api = useApi();

	// 회원목록
	const [membersData, setMembersData] = useState<ItemsResponse<MemberInventoryItem>>();
	const [members, setMembers] = useState<MemberInventoryItem[]>([]);

	// 회원 디테일
	const [membersDetail, setMembersDetail] = useState<MemberDetail>();
	
	// 페이징
	const [page, setPage] = useState<number>(0);
	const [size] = useState<number>(25);

	// 검색 옵션 목록
	const [optionList] = useState<ItemDefault[]>([
		{ value: ResignedMemberSearchOptions.ALL, text: "전체"},
		{ value: ResignedMemberSearchOptions.EMAIL, text: "이메일"},
		{ value: ResignedMemberSearchOptions.NAME, text: "성명"},
	]);

	// 검색 옵션
	const [displayOption, setDisplayOption] = useState<ResignedMemberSearchOptions>(ResignedMemberSearchOptions.ALL);
	const [searchOption, setSearchOption] = useState<ResignedMemberSearchOptions>(ResignedMemberSearchOptions.ALL);
	const onChangeSearchOption = useCallback((value: any) => {
		setDisplayOption(value as ResignedMemberSearchOptions);
	}, []);

	// 검색어
	const [displayKeyword, setDisplayKeyword] = useState<string>("");
	const [searchKeyword, setSearchKeyword] = useState<string>("");
	const onChangeSearchKeyword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setDisplayKeyword(e.target.value);
	}, []);

	// 검색 기간
	const [startDate, setStartDate] = useState<EventValue<Moment> | undefined>(moment('2022-01-01'));
	const [endDate, setEndDate] = useState<EventValue<Moment> | undefined>(moment());
	const [searchStartDate, setSearchStartDate] = useState<EventValue<Moment>>(moment('2022-01-01'));
	const [searchEndDate, setSearchEndDate] = useState<EventValue<Moment>>(moment());
	const onChangeDate = (values: RangeValue<Moment>) => {
		setStartDate(values?.[0]);
		setEndDate(values?.[1]);
	};

	const [isDetailPopupVisible, setIsDetailPopupVisible] = useState(false);

	// 회원 목록 api
	const getMembers = useCallback(async () => {
		const data = (await api.member.getResignedMembers(
			page,
			size,
			searchOption, 
			searchKeyword, 
			searchStartDate?.format(dateFormat) ?? moment().subtract(6, "month").format(dateFormat), 
			searchEndDate?.format(dateFormat) ?? moment().format(dateFormat)
		)).data;

		setMembersData(data.data);
	}, [api.member, page, searchEndDate, searchKeyword, searchOption, searchStartDate, size]);

	// 회원 상세 api
	const getMembersDetail = useCallback(async (memberId: string) => {
		const data = (await api.member.getMember(memberId)).data;

		setMembersDetail(data.data);
	}, [api.member]);

	// 검색
	const onSearch = useCallback((e: FormEvent) => {
		e.preventDefault();

		setPage(0);

		setSearchOption(displayOption);
		setSearchKeyword(displayKeyword);
		setSearchStartDate(startDate ?? moment().subtract(6, "month"));
		setSearchEndDate(endDate ?? moment());
	}, [displayKeyword, displayOption, endDate, startDate]);

	// 상세 팝업 클릭
	const onClickDetail = useCallback(async (member: MemberInventoryItem) => {
		await getMembersDetail(member.memberId);
		setIsDetailPopupVisible(true);
	}, [getMembersDetail]);

	const columns: ColumnsType<MemberInventoryItem> = [
		{
			title: "번호",
			dataIndex: "number",
			key: "number",
		},
		{
			title: "가입일",
			dataIndex: "createdAt",
			key: "createdAt",
			sorter: (a, b) => {
				const aDate = moment(a.createdAt);
				const bDate = moment(b.createdAt);

				return bDate.diff(aDate);
			},
		},
		{
			title: "이메일",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "성명",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "생년월일",
			dataIndex: "birthday",
			key: "birthday",
		},
		{
			title: "전화번호",
			dataIndex: "contact",
			key: "contact",
		},
		{
			title: "국적",
			dataIndex: "locale",
			key: "locale",
		},
		{
			title: "성별",
			dataIndex: "gender",
			key: "gender",
			render: (value, record) => {
				switch (record.gender) {
					case Gender.FEMALE: return "여성";
					case Gender.MALE: return "남성";
				}
			},
			sorter: (a, b) => {
				return a < b ? -1 : a === b ? 0 : 1;
			},
		},
		{
			title: "",
			dataIndex: "detail",
			key: "detail",
			render: (value, record) => <DetailButton onClick={() => onClickDetail(record)} />,
		},
	];
	
	const pagination: TablePaginationConfig = { 
		position: ["bottomCenter"], 
		defaultPageSize: size,
		showSizeChanger: false,
		total: membersData?.totalCount,
		current: page + 1,
		onChange: (page: number, pageSize: number) => {
			setPage(page - 1);
		},
	};

	// 회원 목록 api 호출
	useEffect(() => {
		getMembers();
	}, [getMembers]);

	// 회원 목록 데이터 가공
	useEffect(() => {
		const data = membersData?.items.map((row) => {
			return { key: row.memberId, ...row };
		});

		if (data) {
			setMembers(data);
		}
	}, [membersData]);

	return (
		<>
			<Section>
				<SearchBox 
					optionList={optionList} 
					onChangeOption={onChangeSearchOption}
					searchValue={displayKeyword}
					onChangeSearch={onChangeSearchKeyword}
					startDate={startDate}
					endDate={endDate}
					onChangeDate={onChangeDate}
					onSubmit={onSearch}
					titleUnder="기간" 
					allowClear={false} 
				/>
				<TotalNum>
					총 <strong>{membersData?.totalCount ?? 0}</strong>개
				</TotalNum>
				<TableDefault columns={columns} dataSource={members} pagination={pagination} />
			</Section>
			{/* 6-1-3.회원 상세 팝업 */}
			{(isDetailPopupVisible && membersDetail) && (
				<DefaultPopup title="상세보기" isPopupVisible={isDetailPopupVisible} setIsPopupVisible={setIsDetailPopupVisible} isWide isWithOkay>
					<SubHeading>기본 정보</SubHeading>
					<UserDetailTable member={membersDetail} />
					<Divider />
					<UserReservationTable memberId={membersDetail.memberId}/>
				</DefaultPopup>
			)}
		</>
	);
};

export default DeactivatedUsers;
