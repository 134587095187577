import styled from "styled-components";
import { Layout } from "antd";

export const StyledLayout = styled(Layout)`
	margin: 64px 0 0 260px; // for fixed gnb and lnb
	padding: 0 30px 24px;

	@media screen and (max-width: 1535px) {
		margin: 64px 0 0 150px;
	}
`;

export const StyledContent = styled(Layout.Content)`
	padding: 24px;
	margin: 0;
	min-height: 280px;
`;

export const Section = styled.section`
	margin: 0 0 100px 0;

	@media (min-width: 821px) {
		margin: 0 30px;
	}

	@media screen and (min-width: 1025px) {
		margin: 0 70px;
	}
`;
