import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { MouseEventHandler } from 'react';
import { StyledButton } from '../styles';

interface DeleteButtonProps {
	onClick?: MouseEventHandler;
	isDisabled?: boolean;
}

const DeleteButton = ({ onClick, isDisabled }: DeleteButtonProps) => {
	return (
		<StyledButton type="button" onClick={onClick} disabled={isDisabled}>
			<DeleteOutlined style={{ fontSize: '18px' }} />
		</StyledButton>
	);
};

export default React.memo(DeleteButton);
