import React from "react";
import { ApiContext, ApiContextType } from "../contexts/ApiContext";

import Authentication from "../api/Authentication";
import Doctor from "../api/Doctor";
import Home from "../api/Home";
import Hospital from "../api/Hospital";
import Treatment from "../api/Treatment";
import File from "../api/File";
import Category from "../api/Category";
import Search from "../api/Search";
import Partners from "../api/Partners";
import Member from "../api/Member";
import Payment from '../api/Payment';


const ApiContextProvider = ({ children }: { children: React.ReactNode }) => {
  let value: ApiContextType = {
    authentication: Authentication,
    doctor: Doctor,
    home: Home,
    hospital: Hospital,
    treatment: Treatment,
    file: File,
    category: Category,
    search: Search,
    partners: Partners,
    member: Member,
    payment: Payment
  }

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}

export default ApiContextProvider;

