//  6-2-3.파트너스 계정 생성 팝업 > 3. 계정 정보 입력
import { ChangeEvent } from "react";
import Label from "../../../../components/Inputs/Label";
import { FlexWrapper, InputWrapper, Input, HelperText } from "./styles";

interface Props {
	loginName: string;
	isValidLoginName: boolean;
	onChangeLoginName: (e: ChangeEvent<HTMLInputElement>) => void;
	password: string;
	isValidPassword: boolean;
	onChangePassword: (e: ChangeEvent<HTMLInputElement>) => void;
	passwordCheck: string;
	isValidPasswordCheck: boolean;
	onChangePasswordCheck: (e: ChangeEvent<HTMLInputElement>) => void;
}

const AuthInput = (props: Props) => {
	return (
		<>
			<FlexWrapper>
				<Label text="아이디" isRequired isPopup />
				<InputWrapper>
					<Input type="text" value={props.loginName} onChange={props.onChangeLoginName} />
					{!props.isValidLoginName && <HelperText>이미 가입된 아이디입니다.</HelperText>}
				</InputWrapper>
			</FlexWrapper>
			<FlexWrapper>
				<Label text="비밀번호" isRequired isPopup />
				<InputWrapper>
					<Input type="password" value={props.password} onChange={props.onChangePassword} />
					{!props.isValidPassword && <HelperText>8~32자로 입력해주세요.</HelperText>}
				</InputWrapper>
			</FlexWrapper>
			<FlexWrapper>
				<Label text="비밀번호 확인" isRequired isPopup />
				<InputWrapper>
					<Input type="password" value={props.passwordCheck} onChange={props.onChangePasswordCheck}/>
					{!props.isValidPasswordCheck && <HelperText>비밀번호가 일치하지 않습니다.</HelperText>}
				</InputWrapper>
			</FlexWrapper>
		</>
	);
};

export default AuthInput;
