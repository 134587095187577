import styled from 'styled-components';
import { flexbox, flexboxColumn, fontStyle12, fontStyle14, fontStyle15, visuallyHidden } from '../../styles/mixins';

export const Wrapper = styled.div`
	${flexbox('flex-start', 'flex-end')};
`;
export const ButtonWrapper = styled.div`
	width: 130px;
	height: 145px;
	border-radius: 8px;
	border: 1px dashed rgb(218, 220, 224);
	background-color: #fafbfc;
	transition: background-color 200ms ease-in-out;

	&:hover {
		background-color: rgb(247, 248, 250);
	}

	&:nth-of-type(2) {
		margin: 0 0 0 10px;
	}

	label {
		${flexboxColumn('center', 'center')};
		width: 100%;
		height: 100%;
		color: ${(props) => props.theme.inputBorderFocus};
		cursor: pointer;

		p {
			${fontStyle15}
			font-weight: 400;
			color: ${(props) => props.theme.primary};
			margin: 25px 0 0 0;
		}
	}

	input[type='file'] {
		${visuallyHidden};
	}
`;

export const HelperText = styled.p`
	${fontStyle14};
	font-weight: 500;
	color: ${(props) => props.theme.red};
	margin: 5px 0 0 0;
`;

export const TextWrapper = styled.div`
	${fontStyle12};
	color: ${(props) => props.theme.grey};
	margin: 0 0 0 10px;
`;
