// 6-2-2.파트너스 상세 팝업
import { Table } from './styles';
import { PasswordBtn } from './styles';
import { LockOutlined } from '@ant-design/icons';
import { MouseEventHandler } from 'react';
import { PartnersDetail } from '../../../../model';
import moment from 'moment';

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  partner: PartnersDetail;
}

const PartnersDetailTable = (props: Props) => {
  return (
    <Table>
      <tbody>
        <tr>
          <th>
            <strong>병원명</strong>
          </th>
          <td colSpan={3}>{props.partner.hospitalName}</td>
        </tr>
        <tr>
          <th>
            <strong>병원명(영어)</strong>
          </th>
          <td colSpan={3}>{props.partner.hospitalNameEn}</td>
        </tr>
        <tr>
          <th>
            <strong>병원명(중국어)</strong>
          </th>
          <td colSpan={3}>{props.partner.hospitalNameZh}</td>
        </tr>
        <tr>
          <th>대표자</th>
          <td colSpan={3}>{props.partner.representative}</td>
        </tr>
        <tr>
          <th>담당자 이름</th>
          <td>{props.partner.managerName}</td>
          <th>담당자 번호</th>
          <td>{props.partner.managerContact}</td>
        </tr>
        <tr>
          <th>사업자번호</th>
          <td>{props.partner.businessNumber}</td>
          <th>등록일</th>
          <td>{moment(props.partner.createdAt).format('YYYY.MM.DD')}</td>
        </tr>
        <tr>
          <th>아이디</th>
          <td>{props.partner.loginName}</td>
          <th>비밀번호</th>
          <td>
            <PasswordBtn type="button" onClick={props.onClick}>
              <LockOutlined style={{ fontSize: '15px', margin: '0 1px 0 0' }} /> 비밀번호 변경
            </PasswordBtn>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default PartnersDetailTable;
