import React from 'react';
import { StyledTable } from "../TableDefault/styles";
import { TableProps } from "antd";

interface TableSortProps extends TableProps<any> {
	data: any[];
}

const TableSort = ({ columns, data, scroll, pagination, rowSelection }: TableSortProps) => {
	//  showSorterTooltip={false} 를 넣고, locale를 삭제하시면 tooltip이 제공되지 않습니다.
	return (
		<StyledTable
			rowSelection={rowSelection}
			columns={columns}
			dataSource={data}
			scroll={scroll}
			pagination={pagination ? pagination : false}
			locale={{
				triggerDesc: "내림차순",
				triggerAsc: "오름차순",
				cancelSort: "정렬 취소",
			}}
		/>
	);
};

export default React.memo(TableSort);
