import { InputProps } from 'antd';
import React, { ChangeEventHandler, ReactNode } from 'react';
import Input from '../Input';
import Label from '../Label';
import { FlexWrapper, HelperText, InputGroupWrapper, InputWrapper, StyledLabelInputTranslate } from './styles';

interface LabelInputTranslateProps extends InputProps {
  text: string;
  isRequiredEmpty?: boolean;
  defaultValue?: string;
  enValue?: string;
  zhValue?: string;
  onChangeKo?: ChangeEventHandler<HTMLInputElement>;
  onChangeEn?: ChangeEventHandler<HTMLInputElement>;
  onChangeZh?: ChangeEventHandler<HTMLInputElement>;
  hasHelperText?: boolean;
  children?: ReactNode;
  isPopup?: boolean;
}

const LabelInputTranslate = ({
  text,
  defaultValue,
  enValue,
  zhValue,
  onChangeKo,
  onChangeEn,
  onChangeZh,
  placeholder,
  isRequiredEmpty,
  isPopup,
}: LabelInputTranslateProps) => {
  return (
    <StyledLabelInputTranslate>
      <Label text={text} isPopup={isPopup} />
      <InputGroupWrapper>
        <FlexWrapper>
          <Label text="한국어" isRequired isSubMenu isPopup />
          <Input
            type="text"
            placeholder={placeholder}
            value={defaultValue}
            onChange={onChangeKo}
            maxLength={200}
            isPopup
            isRequiredEmpty={isRequiredEmpty}
          />
        </FlexWrapper>
        <FlexWrapper>
          <Label text="영어" isSubMenu isPopup />
          <InputWrapper>
            <Input
              type="text"
              placeholder={enValue ? '' : defaultValue}
              value={enValue}
              onChange={onChangeEn}
              maxLength={200}
              isPopup
            />
            <HelperText>글로벌용 미 입력 시 한국어와 동일하게 표시됩니다.</HelperText>
          </InputWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <Label text="중국어" isSubMenu isPopup />
          <InputWrapper>
            <Input
              type="text"
              placeholder={zhValue ? '' : defaultValue}
              value={zhValue}
              onChange={onChangeZh}
              maxLength={200}
              isPopup
            />
            <HelperText>글로벌용 미 입력 시 한국어와 동일하게 표시됩니다.</HelperText>
          </InputWrapper>
        </FlexWrapper>
      </InputGroupWrapper>
    </StyledLabelInputTranslate>
  );
};

export default LabelInputTranslate;
