import styled from 'styled-components';
import { flexbox, fontStyle12 } from '../../../styles/mixins';

export const FlexWrapper = styled.div<{ isPopup?: boolean }>`
	${flexbox('flex-start', 'flex-start')};
	margin: ${(props) => (props.isPopup ? '0 0 10px 0' : '0 0 15px 0')};
	position: relative;
`;

export const HelperText = styled.p`
	${fontStyle12};
	color: ${(props) => props.theme.grey};
	position: absolute;
	top: 25px;
	left: 0;
`;
