import styled from 'styled-components';
import 'antd/dist/antd.css';
import { buttonBase, flexbox, fontStyle18 } from '../../../../../styles/mixins';
import { Tabs } from 'antd';

export const StyledLayout = styled.div`
	@media (min-width: 821px) {
		margin: 0 30px;
	}

	@media screen and (min-width: 1025px) {
		margin: 0 65px;
	}
`;

export const Section = styled.section`
	margin: 0 0 150px 0;

	&:last-of-type {
		margin: 0 0 100px 0;
	}
`;

export const ImageWrapper = styled.div`
	display: flex;
	margin: 0 0 70px 0;
`;

export const ButtonWrapper = styled.div`
	${flexbox('center', 'center')};
`;

export const PopupSubHeading = styled.h2`
	${fontStyle18};
	font-weight: 500;
	margin: 0 0 18px 0;
`;

export const Button = styled.button<{ isFilled?: boolean; isBig?: boolean }>`
	${buttonBase};
	width: 260px;
	height: 52px;
`;
