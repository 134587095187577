import React from "react";
import { AuthContext, AuthContextType } from "../contexts/AuthContext";
import jwt_decode from "jwt-decode";

import Authentication from "../api/Authentication";
import { LoginRequest, LoginUser } from "../model";
import { useState } from "react";

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') ?? '');
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const [user, setUser] = useState<LoginUser>();

  const saveLocalStorage = (accessToken: string, refreshToken: string) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  };

  const emptyLocalStorage = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  let signin = async (user: LoginRequest, callback: () => void) => {
    try {
      const loginResult = (await Authentication.adminLogin(user)).data;

      if (loginResult.success) {
        setLoginFailed(false);

        setUser(jwt_decode<LoginUser>(loginResult.accessToken));
        setAccessToken(loginResult.accessToken);
        saveLocalStorage(loginResult.accessToken, loginResult.refreshToken);

        callback();
      } else {
        setLoginFailed(true);
      }
    } catch (err) {
      setLoginFailed(true);
    }
  };

  let signout = async (callback: VoidFunction) => {
    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      await Authentication.logout({refreshToken});

      emptyLocalStorage();
      setAccessToken('')
      setUser(undefined);
      setLoginFailed(false);
      callback();
    }
  };

  let resetLoginFailed = (flag: boolean) => {
    setLoginFailed(flag);
  };

  let value: AuthContextType = { user, setUser, accessToken, loginFailed, signin, signout, resetLoginFailed };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;

