import styled from "styled-components";
import "antd/dist/antd.css";
import { flexbox, fontStyle14 } from "../../../styles/mixins";

export const StyledLayout = styled.div`
	@media (min-width: 821px) {
		margin: 0 30px;
	}

	@media screen and (min-width: 1536px) {
		margin: 0 80px;
	}
`;

export const StyledTag = styled.div<{isRegistered?: boolean;}>`
	${fontStyle14};
	width: 50px;
	height: 26px;
	padding: 2px 5px;
	margin: 0 auto 3px;
	border-radius: 4px;
	text-align: center;
	color: ${(props) => props.theme.white};
	background-color: ${(props) => (props.isRegistered ? props.theme.tag_bg : props.theme.tagDisabled_bg)};
`;

export const FlexWrapper = styled.div`
	${flexbox("space-between", "flex-end")};
	margin: 0 0 10px 0;

	strong {
		font-weight: 700;
	}

	.ant-select {
		margin: 0;
	}
`;
