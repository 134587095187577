import styled from 'styled-components';
import { flexbox, fontStyle16 } from '../../../../styles/mixins';

export const ReservationDataWrapper = styled.div`
  ${fontStyle16}
  ${flexbox('space-between', '')}
  margin-bottom: 5px;

  strong {
    font-weight: 700;
  }
`;

export const TotalReservation = styled.div`
  ${flexbox('flex-start', 'center')}

  > strong {
    position: relative;
    margin-right: 20px;

    ::after {
      content: '';
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 70%;
      background-color: black;
    }
  }
`;

export const TotalNum = styled.div`
  color: ${props => props.theme.primary};
  > strong {
    > span {
      margin: 0 5px;
    }
  }
`;

export const TotalAmount = styled.div`
  > strong {
    margin-left: 10px;
  }
`;
