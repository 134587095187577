import {FormEvent, Key, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import TableHeading from "../../../components/TableHeading";
import TableDefault from "../../../components/Tables/TableDefault";
import UpDownButton from "../../../components/UpDownButton";
import DefaultImage from "../../../components/DefaultImage";
import Image from "../../../components/Image";
import DefaultPopup from "../../../components/Popup/DefaultPopup";
import Label from "../../../components/Inputs/Label";
import AddImageButton from "../../../components/AddImageButton";
import SelectInput from "../../../components/Inputs/SelectInput";
import TableSort from "../../../components/Tables/TableSort";
import InnerTdButton from "../../../components/InnerTdButton";
import ConfirmPopup from "../../../components/Popup/ConfirmPopup";
import { Section, ImageWrapper, InputWrapper, Button } from "./styles";

import { BannerItem, BannerRequest, ItemsResponse, ShiftDirection, TreatmentItemForAdmin, TreatmentSearchKeywordOption } from "../../../model";
import { useApi } from "../../../contexts/ApiContext";
import { TablePaginationConfig } from "antd";
import moment from 'moment';
import { TableRowSelection } from "antd/lib/table/interface";
import { fileToDataUri } from "../../../helpers/FileHelper";
// ----------------------------------------------------------------------------------------------------------------------------------

const popupText = {
	save:
		<>
			변경한 내용을 저장할까요? <br />
			확인을 누르면 앱에 바로 반영됩니다.
		</>
}

// 팝업 select의 option list
const optionList = ["전체", "병원명", "시술명"];

const Banner = () => {
	const api = useApi();

	const [popupContents, setPopupContents] = useState<string | ReactNode>();
	const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => { });
	const [popupHandleCancel, setPopupHandleCancel] = useState<VoidFunction | undefined>(() => () => { });
	const [isWithCancel, setIsWithCancel] = useState(false);

	// 배너 페이징
	const [bannerSize,] = useState<number>(10);
	const [bannerPage, setBannerPage] = useState<number>(0);

	// 팝업 시술 페이징
	const [treatSize,] = useState<number>(25);
	const [treatPage, setTreatPage] = useState<number>(0);

	// 배너 데이터
	const [bannerData, setBannerData] = useState<ItemsResponse<BannerItem>>();
	const [banners, setBanners] = useState<BannerItem[]>([]);

	// 편집을 위해 선택된 배너
	const [selectedBanner, setSelectedBanner] = useState<BannerItem>();

	// 배너 삭제 아이디
	const [deleteId, setDeleteId] = useState<string>('');

	// 팝업 시술 데이터
	const [treatmentsData, setTreatmentsData] = useState<ItemsResponse<TreatmentItemForAdmin>>();
	const [treatments, setTreatments] = useState<TreatmentItemForAdmin[]>([]);

	// 팝업 시술 검색
	const [keywordOption, setKeywordOption] = useState<TreatmentSearchKeywordOption>(TreatmentSearchKeywordOption.ALL);
	const [searchKeywordOption, setSearchKeywordOption] = useState<TreatmentSearchKeywordOption>(TreatmentSearchKeywordOption.ALL);
	const [keyword, setKeyword] = useState<string>('');
	const [searchKeyword, setSearchKeyword] = useState<string>('');

	// 팝업 시술 선택
	const [selectedTreatment, setSelectedTreatment] = useState<Key[]>([]);

	// 업로드할 이미지 파일
	const [imageUrl, setImageUrl] = useState<string>();
	const [file, setFile] = useState<File>();
	const [isEmptyFile, setIsEmptyFile] = useState(false);

	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [isSubmitCountPopupVisible, setIsSubmitCountPopupVisible] = useState(false);

	const showPopup = () => {
		setSelectedBanner(undefined);
		setImageUrl(undefined);
		setFile(undefined);
		setSelectedTreatment([]);
	
		setTreatPage(0);
		setIsEmptyFile(false);

		setIsPopupVisible(true);
	}

	const setPopupData = (contents: JSX.Element, isWithCancel: boolean, handleOk?: VoidFunction, handleCancel?: VoidFunction) => {
		setIsWithCancel(isWithCancel);
		setPopupContents(contents);
		setPopupHandleOk(handleOk);
		setPopupHandleCancel(handleCancel);
	};

	// 팝업에서 필수 입력 값을 넣지 않고 '저장'버튼을 누르는 경우 보여주는 팝업 (2-1-2 배너 컨텐츠 추가 팝업. 컴포넌트 4-1)
	const [isConfirmVisible, setIsConfirmVisible] = useState(false);

	// 배너 api 호출
	const getBanners = useCallback(async () => {
		const data = (await api.home.getHomeBanners(bannerPage, bannerSize)).data;
		setBannerData(data);
	}, [api.home, bannerPage, bannerSize]);

	// 시술 api 호출
	const getTreatments = useCallback(async () => {
		const data = (await api.treatment.getTreatmentsForSearch(searchKeywordOption, searchKeyword, treatPage, treatSize)).data;
		setTreatmentsData(data.data);
	}, [api.treatment, searchKeywordOption, searchKeyword, treatPage, treatSize]);

	// 삭제 팝업
	const [isDeleteVisible, setIsDeleteVisible] = useState(false);
	const onClickDelete = (bannerId: string) => {
		setDeleteId(bannerId);
		setIsDeleteVisible(true);
	};

	// 삭제 팝업 승인
	const onDelete = async () => {
		if (deleteId) {
			const data = (await api.home.deleteHomeBanner(deleteId)).data;
			
			if (data.deleted) {
				await getBanners();
			}

			setIsDeleteVisible(false);
			setDeleteId('');
		}
	};

	// 삭제 팝업 취소
	const onDeleteCancel = () => {
		setDeleteId('');
		setIsDeleteVisible(false);
	};

	//순서 변경
	const swapOrder = async (bannerId: string, direction: ShiftDirection) => {
		const data = (await api.home.swapBannerOrder(bannerId, direction)).data;

		if (data.moved) {
			await getBanners();
		}
	};

	// 시술 팝업 취소
	const onClickCancel = () => {
		setIsPopupVisible(false);
	};

	// 시술 컨텐츠 검색
	const onSearch = useCallback((e: FormEvent) => {
		e.preventDefault();
		setTreatPage(0);
		setSearchKeyword(keyword);
		setSearchKeywordOption(keywordOption)
	}, [keyword, keywordOption]);

	// 시술 컨텐츠 검색 카테고리 변경
	const onChangeSelect = (value: string) => {
		switch (value) {
			case '전체': setKeywordOption(TreatmentSearchKeywordOption.ALL); break;
			case '병원명': setKeywordOption(TreatmentSearchKeywordOption.HOSPITAL); break;
			case '시술명': setKeywordOption(TreatmentSearchKeywordOption.TREATMENT); break;
		}
	};

	// 시술 이미지 변경
	const onChangeFile = (file: File | undefined) => {
		setImageUrl(undefined);
		setFile(file);
	};

	// 유효성 검사
	const checkValidation = useCallback(() => {
		let validation = true;
		if (selectedTreatment.length <= 0) {
			validation = false;
		}

		if (!imageUrl && !file) {
			setIsEmptyFile(true);
			validation = false;
		} else {
			setIsEmptyFile(false);
		}

		if (validation) {
			setIsEmptyFile(false);
		} else {
			setIsConfirmVisible(true);
		}

		return validation;
	}, [file, imageUrl, selectedTreatment.length]);

	// 시술 저장
	const onSubmit = useCallback(async () => {
		if (checkValidation()) {
			// 시술 배너 사진 업로드
			let imageFileUrl: string = imageUrl ?? "";

			if (file) {
				const fileReader = await fileToDataUri(file);
				if (fileReader) {
					const data = (await api.file.getPresignedUrl(file.name)).data;
					await api.file.uploadFile(data.signedUrl, file);

					imageFileUrl = data.uploadTargetUrl;
				}
			}

			const submitData: BannerRequest = {
				treatmentId: selectedTreatment[0].toString(), 
				thumbnailUrl: imageFileUrl
			};

			if (selectedBanner !== undefined) {
				// 편집
				const data = (await api.home.modifyHomeBanner(selectedBanner.id, submitData)).data;

				if (data.modified) {
					await getBanners();
					setIsPopupVisible(false);
				}
			} else {
				// 저장
				const data = (await api.home.addHomeBanner(submitData)).data;

				if (data.created) {
					await getBanners();
					setIsPopupVisible(false);
				}
			}
		}
	}, [api.file, api.home, checkValidation, file, getBanners, imageUrl, selectedBanner, selectedTreatment]);

	const onConfirm = useCallback(() => {
		if (checkValidation()) {
			setPopupData(popupText.save, true, () => () => onSubmit());
			setIsSubmitCountPopupVisible(true);
		}
	}, [checkValidation, onSubmit]);

	// 시술 배너 편집
	const onClickEdit = useCallback((item: BannerItem) => {
		setSelectedBanner(item);

		setTreatPage(0);
		setSelectedTreatment([item.treatmentId]);
		setImageUrl(item.thumbnailUrl);

		setIsPopupVisible(true);
	}, []);

	// 페이지 테이블에 필요한 데이터
	const columns = [
		{
			title: "순서 변경",
			dataIndex: "change",
			key: "change",
			render: (value: any, record: BannerItem, index: number) => {
				return (
					<>
						<UpDownButton 
							onClick={() => {
								if (index > 0) {
									swapOrder(record.id, ShiftDirection.FORWARD);
								}
							}}
						/>
						<UpDownButton 
							onClick={() => {
								if (index < (banners.length - 1)) {
									swapOrder(record.id, ShiftDirection.BACKWARD)
								}
							}} 
							isDown 
						/>
					</>
				);
			},
		},
		{ title: "노출 순서", dataIndex: "number", key: "number", width: 130 },
		{
			title: "사진",
			dataIndex: "photo",
			key: "photo",
			render: (value: any, record: BannerItem) => {
				if (record.thumbnailUrl) {
					return <Image src={record.thumbnailUrl} />;
				} else {
					return <DefaultImage />
				}
			},
		},
		{ title: "병원명", dataIndex: "hospitalName", key: "hospitalName" },
		{ title: "시술명", dataIndex: "treatmentName", key: "treatmentName" },
		{
			title: "관리",
			dataIndex: "edit",
			key: "edit",
			render: (value: any, record: BannerItem) => {
				return <InnerTdButton isWithEdit onClickEdit={() => onClickEdit(record)} onClickDelete={() => onClickDelete(record.id)} />;
			},
		},
	];

	const popupColumns = [
		{
			title: "등록일",
			dataIndex: "createdAt",
			key: "createdAt",
			sorter: (a: TreatmentItemForAdmin, b: TreatmentItemForAdmin) => {
				const aDate = moment(a.createdAt);
				const bDate = moment(b.createdAt);

				return bDate.diff(aDate);
			},
		},
		{
			title: "수정일",
			dataIndex: "updatedAt",
			key: "updatedAt",
			sorter: (a: TreatmentItemForAdmin, b: TreatmentItemForAdmin) => {
				const aDate = moment(a.updatedAt);
				const bDate = moment(b.updatedAt);
				return bDate.diff(aDate);
			},
		},
		{ title: "진료과", dataIndex: "departmentName", key: "departmentName" },
		{ title: "병원명", dataIndex: "hospitalName", key: "hospitalName" },
		{ title: "시술명", dataIndex: "name", key: "name" },
	];

	//배너 페이지네이션
	const bannerPagination: TablePaginationConfig = { 
		position: ["bottomCenter"], 
		defaultPageSize: bannerSize,
		showSizeChanger: false,
		total: bannerData?.totalCount, 
		current: bannerPage + 1,
		onChange: (page: number, pageSize: number) => {
			setBannerPage(page - 1);
		},
	};

	//시술 페이지네이션
	const popupPagination: TablePaginationConfig = { 
		position: ["bottomCenter"], 
		defaultPageSize: treatSize,
		showSizeChanger: false,
		total: treatmentsData?.totalCount, 
		current: treatPage + 1,
		onChange: (page: number, pageSize: number) => {
			setTreatPage(page - 1);
		},
	};

	//시술 체크 박스
	const rowSelection: TableRowSelection<BannerItem> = useMemo(() => ({
		onChange: (keys) => {
			setSelectedTreatment(keys);
		},
		getCheckboxProps: (record) => {
			if (selectedTreatment.length > 0 && record.key !== selectedTreatment[0]) {
				return {
					disabled: true,
				}
			} else {
				return {
					disabled: false,
				}
			}
		},
		selectedRowKeys: selectedTreatment,
		hideSelectAll: true,
	}), [selectedTreatment]);

	// api 호출로 불러온 시술 데이터 가공
	useEffect(() => {
		const data = treatmentsData?.items.map((row) => {
			return { key: row.treatmentId, ...row};
		});

		if (data) {
			setTreatments(data);
		}
	}, [treatmentsData]);

	// 시술 호출
	useEffect(() => {
		getTreatments();
	}, [getTreatments]);

	// api 호출로 불러온 배너 데이터 가공
	useEffect(() => {
		const data = bannerData?.items.map((row) => {
			return { key: row.id, ...row };
		});

		if (data) {
			setBanners(data);
		}
	}, [bannerData]);
	
	// 배너 호출
	useEffect(() => {
		getBanners();
	}, [getBanners]);

	return (
		<>
			<Section>
				<TableHeading isRequired hasButton="컨텐츠 등록" onClick={showPopup}>
					배너
				</TableHeading>
				<TableDefault columns={columns} dataSource={banners} pagination={bannerPagination} />
				{/* 팝업 --- 기획서 2-1 오늘의 추천 관리 > 2-1-2.배너컨텐츠추가 팝업 */}
				{isPopupVisible && (
					<DefaultPopup title={selectedBanner ? "편집" : "추가"}
												isPopupVisible={isPopupVisible}
												setIsPopupVisible={setIsPopupVisible}
												onClickOk={onConfirm}
												onClickCancel={onClickCancel}
												okWithoutClose isWithCancel isWithSave>
						<ImageWrapper>
							<Label isRequired isPopup text="사진 등록" />
							<AddImageButton isRequiredEmpty={isEmptyFile} url={imageUrl} onChange={onChangeFile} />
						</ImageWrapper>
						<form onSubmit={onSearch}>
							<div>
								<Label isRequired isPopup text="시술 컨텐츠" />
								<InputWrapper>
									<SelectInput placeholder="검색어를 입력하세요." optionList={optionList} onChange={(e) => setKeyword(e.target.value)} onChangeSelect={onChangeSelect} />
									<Button isFilled isMiddle>
										검색
									</Button>
								</InputWrapper>
							</div>
						</form>
						<TableSort columns={popupColumns} data={treatments} scroll={{ y: 270 }} pagination={popupPagination} rowSelection={rowSelection} />
					</DefaultPopup>
				)}
			</Section>
			{/* 팝업 4-1*/}
			{isConfirmVisible && (
				<ConfirmPopup isPopupVisible={isConfirmVisible} setIsPopupVisible={setIsConfirmVisible}>
					필수 항목을 다시 확인해 주세요.
				</ConfirmPopup>
			)}
			{/* 팝업 4-9 */}
			{isDeleteVisible && (
				<ConfirmPopup isPopupVisible={isDeleteVisible} handleOk={onDelete} handleCancel={onDeleteCancel} setIsPopupVisible={setIsDeleteVisible} isWithCancel>
					삭제하시겠습니까?
				</ConfirmPopup>
			)}
			{/* 팝업 5-1 */}
			{isSubmitCountPopupVisible && (
				<ConfirmPopup isWithCancel={isWithCancel} handleOk={popupHandleOk} handleCancel={popupHandleCancel} isPopupVisible={isSubmitCountPopupVisible} setIsPopupVisible={setIsSubmitCountPopupVisible} >
					{popupContents}
				</ConfirmPopup>
			)}
		</>
	);
};

export default Banner;
