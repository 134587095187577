import styled from "styled-components";
import { flexbox, fontStyle14, fontStyle15 } from "../../../styles/mixins";

export const FlexWrapper = styled.div`
	${flexbox("flex-start", "flex-start")};
	margin: 0 0 40px 0;
`;

export const TextareaWrapper = styled.div`
	width: 100%;
	position: relative;
	display: flex;
    flex-direction: column;
`;

export const Textarea = styled.textarea<{isPopup?: boolean; isRequiredEmpty?: boolean;}>`
	${fontStyle15};
	display: block;
	width: ${(props) => (props.isPopup ? "100%" : "75%")};
	height: 200px;
	padding: 16px;
	border-radius: 4px;
	border-width: 1px;
	border-style: solid;
	border-color: ${(props) => (props.isRequiredEmpty ? props.theme.red : props.theme.inputBorder)};
	color: ${(props) => props.theme.primary};
	transition: border-color 100ms ease-in-out;
	resize: none;

	::placeholder {
		color: ${(props) => props.theme.placeholder};
	}

	:hover {
		border-width: 1px;
		border-style: solid;
		border-color: ${(props) => (props.isRequiredEmpty ? props.theme.red : props.theme.inputBorderHover)};
	}

	:focus,
	:active {
		border-width: 1px;
		border-style: solid;
		border-color: ${(props) => (props.isRequiredEmpty ? props.theme.red : props.theme.inputBorderFocus)};
	}

	:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}
`;

export const HelperText = styled.p`
	${fontStyle14};
	color: ${(props) => props.theme.red};
	position: absolute;
	bottom: 2px;
	left: 0;
`;

export const MaxLength = styled.div<{isPopup?: boolean;}>`
    align-self: end;
    margin: ${(props) => (props.isPopup ? "2px 0 0 0" : "2px 25% 0 0")};
	color: ${(props) => props.theme.primary};
	> span {
		color: ${(props) => props.theme.grey};
	}
`;
