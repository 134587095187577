import Select from "../Select";
import Input from "../Input";
import { FlexWrapper } from "./styles";
import React, { ChangeEventHandler } from "react";

interface SelectInputProps {
	optionList: any[];
	placeholder?: string;
	value?: string;
	onChangeSelect?: (value: any, option: any | any[]) => void;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	maxLength?: number;
	isRequiredEmpty?: boolean;
}

const SelectInput = ({ optionList, placeholder, value, onChange, onChangeSelect, maxLength, isRequiredEmpty }: SelectInputProps) => {
	return (
		<FlexWrapper>
			<Select optionList={optionList} onChange={onChangeSelect} style={{ margin: "0 8px 25px 0" }} />
			<Input placeholder={placeholder} value={value} onChange={onChange} maxLength={maxLength} isRequiredEmpty={isRequiredEmpty} />
		</FlexWrapper>
	);
};

export default React.memo(SelectInput);
