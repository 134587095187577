import styled from "styled-components";
import { Tabs } from "antd";
import { fontStyle16 } from "../../../styles/mixins";

export const StyledTabs = styled(Tabs)`
	color: ${(props) => props.theme.primary};

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
	.ant-tabs-tab-btn:hover {
		color: ${(props) => props.theme.accent};
		font-weight: 500;
	}

	&.ant-tabs-top > .ant-tabs-nav {
		margin: 0 0 35px 0;
	}

	&.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
	&.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
		color: ${(props) => props.theme.accent};
	}

	.ant-tabs-tab:hover {
		color: ${(props) => props.theme.accent};
	}
`;

export const TotalNum = styled.span`
	${fontStyle16};
	color: ${(props) => props.theme.primary};
	strong {
		font-weight: 700;
	}
`;
