import React from 'react';
import { StyledLabel } from './styles';

interface LabelProps {
	text: string;
	isRequired?: boolean;
	isPopup?: boolean;
	isSubMenu?: boolean;
}

const Label = ({ text, isRequired, isPopup, isSubMenu }: LabelProps) => {
	return (
		<StyledLabel isPopup={isPopup} isSubMenu={isSubMenu}>
			<label>{text}</label>
			{isRequired && <span>*</span>}
		</StyledLabel>
	);
};

export default React.memo(Label);
