import React, { MouseEventHandler, ReactNode } from "react";
import { Wrapper, HeadingWrapper, StyledTableHeading, HelperText, Button } from "./styles";

interface TableHeadingProps {
	isRequired?: boolean;
	hasButton?: string;
	onClick?: MouseEventHandler;
	isButtonDisabled?: boolean;
	children: ReactNode | ReactNode[];
}

const TableHeading = ({ isRequired, hasButton, onClick, isButtonDisabled, children }: TableHeadingProps) => {
	return (
		<Wrapper>
			<HeadingWrapper>
				<StyledTableHeading>{children}</StyledTableHeading>
				{isRequired && <HelperText>*필수입력 항목</HelperText>}
			</HeadingWrapper>
			{hasButton && (
				<Button type="button" isFilled isMiddle disabled={isButtonDisabled} onClick={onClick}>
					{hasButton}
				</Button>
			)}
		</Wrapper>
	);
};

export default React.memo(TableHeading);
