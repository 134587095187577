import "antd/dist/antd.css";
import React from 'react';
import { Select } from "antd";
import { StyledSelect } from "./styles";
import { DepartmentWithCategoryList } from "../../../../model";

const { Option, OptGroup } = Select;

interface Props {
	list: DepartmentWithCategoryList[];
	onChange?: (value: any) => void;
}

const SelectOptGroup = (props: Props) => {

	return (
		<StyledSelect defaultValue="전체 시술 카테고리" style={{ width: 200 }} onChange={props.onChange}>
			{(() => {
				if (props.list.length > 1) {
					let list: JSX.Element[] = [];
					list.push(<Option key="" value="전체 시술 카테고리">전체 시술 카테고리</Option>);
					list = list.concat(props.list.map(row => {
						return (
							<OptGroup key={row.departmentName} label={row.departmentName}>
								{row.categories.map((category) => {
									return <Option key={category.categoryId} value={category.categoryId}>{category.categoryName}</Option>
								})}
							</OptGroup>
						)
					}));

					return list;
				} else if (props.list.length === 1) {
					let list: JSX.Element[] = [<Option key="" value="전체 시술 카테고리">전체 시술 카테고리</Option>];
					props.list.forEach(row => {
						list = list.concat(row.categories.map((category) => {
							return <Option key={category.categoryId} value={category.categoryId}>{category.categoryName}</Option>
						}));
					});

					return list;
				}

				return null;
			})()}
		</StyledSelect>
	);
};

export default React.memo(SelectOptGroup);
