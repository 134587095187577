import { CloseOutlined } from "@ant-design/icons";
import React, { MouseEvent, ReactNode, useCallback } from "react";
import { StyledModal, CloseButton, FlexWrapper, Button } from "./styles";

interface DefaultPopupProps {
	title: string;
	isPopupVisible?: boolean;
	isWithCancel?: boolean | string;
	isWithOkay?: boolean;
	isWithEdit?: boolean;
	isWithSave?: boolean;
	isWide?: boolean;
	children: ReactNode | ReactNode[];
	okWithoutClose?: boolean;
	okWithoutCloseWhenCanceled?: boolean;
	setIsPopupVisible: (visible: boolean) => void;
	onClickCancel?: () => void;
	onClickOk?: () => void;
}

const DefaultPopup = (props: DefaultPopupProps) => {
	const onCancel = useCallback((e: MouseEvent) => {
		e.preventDefault();

		if (props.onClickCancel) {
			props.onClickCancel();
		}

		if (!props.okWithoutCloseWhenCanceled) {
			props.setIsPopupVisible(false);
		}

	}, [props]);

	const onOk = useCallback((e: MouseEvent) => {
		e.preventDefault();

		if (props.onClickOk) {
			props.onClickOk();
		}

		if (!props.okWithoutClose) {
			props.setIsPopupVisible(false);
		}
	}, [props]);

	return (
		<>
			<StyledModal centered footer={null} visible={props.isPopupVisible} onOk={onOk} onCancel={onCancel} isWide={props.isWide}>
				<div>
					<h1>{props.title}</h1>
					<CloseButton type="button" onClick={onCancel}>
						<CloseOutlined style={{ fontSize: "12px" }} />
					</CloseButton>
					{props.children}
				</div>

				<FlexWrapper>
					{props.isWithCancel && (
						<Button isBig isFilled={typeof props.isWithCancel === "string"} onClick={onCancel}>
							{typeof props.isWithCancel === "string" ? props.isWithCancel : "취소"}
						</Button>
					)}
					{props.isWithOkay && (
						<Button isBig isFilled onClick={onOk}>
							확인
						</Button>
					)}
					{props.isWithSave && (
						<Button isBig isFilled onClick={onOk}>
							저장
						</Button>
					)}
					{props.isWithEdit && (
						<Button isBig onClick={onOk}>
							편집
						</Button>
					)}
				</FlexWrapper>
			</StyledModal>
		</>
	);
};

export default React.memo(DefaultPopup);
