import { Table } from './styles';
import { PaymentDetails } from '../../../model';

export interface PaymentInformationProps {
  paymentDetails?: PaymentDetails;
}
const PaymentInformation = (props: PaymentInformationProps) => {
  return (
    <Table>
      <tbody>
        <tr>
          <th>
            <strong>결제/취소번호</strong>
          </th>
          <td>{props.paymentDetails?.paymentNo}</td>
          <th>결제방법</th>
          <td>{props.paymentDetails?.paymentMethod}</td>
        </tr>
        <tr>
          <th>결제/취소일</th>
          <td>{props.paymentDetails?.executionDate}</td>
          <th>금액</th>
          <td>{props.paymentDetails?.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default PaymentInformation;
