import styled from 'styled-components';
import { buttonBase, flexbox, fontStyle14, fontStyle24 } from '../../../../../../styles/mixins';

export const Layout = styled.div`
  @media (min-width: 821px) {
    margin: 0 30px;
  }

  @media screen and (min-width: 1025px) {
    margin: 0 70px;
  }

  h2 {
    ${fontStyle24};
    font-weight: 700;
    margin-top: -20px;
  }
`;

export const Section = styled.section`
  margin: 0 0 150px 0;

  &:last-of-type {
    margin: 0 0 100px 0;
  }

  > small {
    color: ${props => props.theme.grey};
  }
`;

export const ImageWrapper = styled.div`
  .ant-image.ant-image-error:nth-of-type(2) {
    margin: 0 0 0 10px;
  }
`;

export const ButtonWrapper = styled.div`
  ${flexbox('center', 'center')};
`;

export const LabelImageWrapper = styled.div`
  display: flex;
  margin: 0 0 70px 0;
`;

export const FlexWrapper = styled.div`
  ${flexbox};
`;

export const PopupImageWrapper = styled.div`
  margin: 0 0 40px 0;
`;
export const ImageTextWrapper = styled.div`
  > p {
    width: 100px;
    margin: 5px 13px 0;
    color: ${props => props.theme.primary};
    text-align: center;
  }
`;

export const HelperText = styled.p`
  ${fontStyle14};
  font-weight: 500;
  color: ${props => props.theme.red};
  margin: 7px 0 0 0;
`;

export const Button = styled.button<{ isBig?: boolean; isFilled?: boolean }>`
  ${buttonBase};
  width: 260px;
  height: 52px;
`;
