import { Layout, RadioChangeEvent } from 'antd';
import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import GNB from '../../layouts/GNB';
import LNB from '../../layouts/LNB';
import PageHeading from '../../components/PageHeading';
import TableHeading from '../../components/TableHeading';
import Table from './Table';
import ConfirmPopup from '../../components/Popup/ConfirmPopup';
import DefaultPopup from '../../components/Popup/DefaultPopup';
import LabelText from '../../components/Inputs/LabelText';
import { Radio } from 'antd';
import { StyledLayout, StyledContent, Wrapper, TableWrapper, StyledRadio } from './styles';
import {
  DepartmentItem,
  DepartmentRequest,
  ShiftDirection,
  TreatmentCategoryItem,
  TreatmentCategoryRequest,
} from '../../model';
import { useApi } from '../../contexts/ApiContext';
import LabelInputTranslate from '../../components/Inputs/LabelInputTranslate';

const popupText = {
  save: (
    <>
      입력한 내용을 저장할까요? <br />
      확인을 누르면 앱에 바로 반영됩니다.
    </>
  ),
  unuse: (
    <>
      미사용 처리 하시겠습니까?
      <br />
      <small>
        진료과 미사용시 하위 시술 카테고리에 <br />
        포함된 모든 시술 컨텐츠가 앱에 표시되지 않습니다.
      </small>
    </>
  ),
  departmentCannotBeDeactivated: (
    <>
      <small>
        홈 화면 배너 시술 컨텐츠에 해당 진료과가
        <br />
        지정되어 있어, 미사용이 불가능 합니다.
      </small>
    </>
  ),
  unuseCategory: (
    <>
      미사용 처리 하시겠습니까?
      <br />
      <small>
        미사용시 시술 카테고리에 포함된
        <br />
        모든 시술 컨텐츠가 앱에 표시되지 않습니다.
      </small>
    </>
  ),
  categoryCannotBeDeactivated: (
    <>
      <small>
        홈 화면 배너 시술 컨텐츠에 해당 시술 카테고리가
        <br />
        지정되어 있어, 미사용이 불가능 합니다.
      </small>
    </>
  ),
  validation: <>필수 항목을 다시 확인해 주세요.</>,
  duplicate: <>이미 등록된 진료과 입니다.</>,
  duplicateCategory: <>이미 등록된 카테고리 입니다.</>,
  empty: <>선택된 진료과가 없습니다.</>,
};

const Category = () => {
  const api = useApi();

  // 진료과 목록
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<DepartmentItem>();
  const [editDepartment, setEditDepartment] = useState<DepartmentItem>();

  // 진료과 이름
  const [deptName, setDeptName] = useState<string>('');
  const [isEmptyDeptName, setIsEmptyDeptName] = useState<boolean>(false);
  const [deptNameEn, setDeptNameEn] = useState<string>('');
  const [deptNameZh, setDeptNameZh] = useState<string>('');
  const onChangeDeptName = (e: ChangeEvent<HTMLInputElement>) => setDeptName(e.target.value);
  const onChangeDeptNameEn = (e: ChangeEvent<HTMLInputElement>) => setDeptNameEn(e.target.value);
  const onChangeDeptNameZh = (e: ChangeEvent<HTMLInputElement>) => setDeptNameZh(e.target.value);

  // 진료과 사용 여부
  const [isDeptDisplay, setIsDeptDisplay] = useState<boolean>(false);
  const onChangeDeptDisplay = useCallback(
    (e: RadioChangeEvent) => {
      if (e.target.value === false) {
        // 미사용 처리
        if (editDepartment?.cannotBeDeactivated === true) {
          setPopupData(popupText.departmentCannotBeDeactivated, false);
          setIsConfirmPopupVisible(true);
        } else {
          setPopupData(
            popupText.unuse,
            true,
            () => () => setIsDeptDisplay(e.target.value),
            () => () => e.preventDefault(),
          );
          setIsConfirmPopupVisible(true);
        }
      } else {
        setIsDeptDisplay(e.target.value);
      }
    },
    [editDepartment],
  );

  // 카테고리 목록
  const [categories, setCategories] = useState<TreatmentCategoryItem[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<TreatmentCategoryItem>();

  // 시술 카테고리 이름
  const [categoryName, setCategoryName] = useState<string>('');
  const [categoryNameEn, setCategoryNameEn] = useState<string>('');
  const [categoryNameZh, setCategoryNameZh] = useState<string>('');
  const [isEmptyCategoryName, setIsEmptyCategoryName] = useState<boolean>(false);
  const onChangeCategoryName = (e: ChangeEvent<HTMLInputElement>) => setCategoryName(e.target.value);
  const onChangeCategoryNameEn = (e: ChangeEvent<HTMLInputElement>) => setCategoryNameEn(e.target.value);
  const onChangeCategoryNameZh = (e: ChangeEvent<HTMLInputElement>) => setCategoryNameZh(e.target.value);

  // 시술 카테고리 사용 여부
  const [isCategoryDisplay, setIsCategoryDisplay] = useState<boolean>(false);
  const onChangeCategoryDisplay = useCallback(
    (e: RadioChangeEvent) => {
      if (e.target.value === false) {
        // 미사용 처리
        if (selectedCategory?.cannotBeDeactivated === true) {
          setPopupData(popupText.categoryCannotBeDeactivated, false);
          setIsConfirmPopupVisible(true);
        } else {
          setPopupData(
            popupText.unuseCategory,
            true,
            () => () => setIsCategoryDisplay(e.target.value),
            () => () => e.preventDefault(),
          );
          setIsConfirmPopupVisible(true);
        }
      } else {
        // 사용 처리
        setIsCategoryDisplay(e.target.value);
      }
    },
    [selectedCategory],
  );

  // 팝업 관리
  // 진료과
  const [isDeptVisible, setIsDeptVisible] = useState(false);
  const onClickDept = () => {
    setSelectedDepartment(undefined);
    setEditDepartment(undefined);
    setDeptName('');
    setDeptNameEn('');
    setDeptNameZh('');
    setIsDeptDisplay(false);
    setIsDeptVisible(true);
  };
  // 카테고리
  const [isTreatVisible, setIsTreatVisible] = useState(false);
  const onClickTreat = () => {
    if (selectedDepartment !== undefined) {
      setSelectedCategory(undefined);
      setCategoryName('');
      setCategoryNameEn('');
      setCategoryNameZh('');
      setIsCategoryDisplay(false);
      setIsTreatVisible(true);
    } else {
      setPopupData(popupText.empty, false);
      setIsConfirmPopupVisible(true);
    }
  };

  // Comfirm Popup
  const [popupContents, setPopupContents] = useState<string | ReactNode>();
  const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => {});
  const [popupHandleCancel, setPopupHandleCancel] = useState<VoidFunction | undefined>(() => () => {});
  const [isWithCancel, setIsWithCancel] = useState(false);
  const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState(false);

  // 진료과 데이터
  const getAllDepartments = useCallback(async () => {
    const data = (await api.category.getDepartments()).data;
    if (data.success) {
      setDepartments(data.result);
    }
  }, [api.category]);

  // 카테고리 데이터 api
  const getCategories = useCallback(
    async (departmentId: string) => {
      const data = (await api.category.getCategories(departmentId)).data;
      if (data.success) {
        setCategories(data.data);
      }
    },
    [api.category],
  );

  // 진료과 순서 조정 api
  const swapDepartmentOrder = useCallback(
    async (departmentId: string, direction: ShiftDirection) => {
      const data = (await api.category.swapDepartment(departmentId, direction)).data;

      if (data.modified) {
        getAllDepartments();
      }
    },
    [api.category, getAllDepartments],
  );

  // 카테고리 순서 조정 api
  const swapCategoryOrder = useCallback(
    async (departmentId: string, categoryId: string, direction: ShiftDirection) => {
      const data = (await api.category.swapCategory(departmentId, categoryId, direction)).data;

      if (data.modified) {
        getCategories(departmentId);
      }
    },
    [api.category, getCategories],
  );

  // 진료과 수정 api
  const modifyDepartment = useCallback(
    async (id: string, editData: DepartmentRequest) => {
      const data = (await api.category.modifyDepartment(id, editData)).data;

      if (data.modified) {
        setEditDepartment(undefined);
        getAllDepartments();
      }
    },
    [api.category, getAllDepartments],
  );

  // 진료과 추가 api
  const addDepartment = useCallback(
    async (addData: DepartmentRequest) => {
      const data = (await api.category.addDepartment(addData)).data;

      if (data.created) {
        getAllDepartments();
        setSelectedDepartment(departments.find(row => row.id === data.departmentId));
      }
    },
    [api.category, departments, getAllDepartments],
  );

  // 카테고리 수정 api
  const modifyCategory = useCallback(
    async (deparmtentId: string, categoryId: string, editData: TreatmentCategoryRequest) => {
      console.log(editData)
      const data = (await api.category.modifyCategory(deparmtentId, categoryId, editData)).data;

      if (data.modified) {
        getCategories(deparmtentId);
      }
    },
    [api.category, getCategories],
  );

  // 카테고리 추가 api
  const addCategory = useCallback(
    async (departmentId: string, addData: TreatmentCategoryRequest) => {
      const data = (await api.category.addCategory(departmentId, addData)).data;

      if (data.created) {
        getCategories(departmentId);
      }
    },
    [api.category, getCategories],
  );

  // 진료과 선택
  const onClickDepartment = useCallback((item: DepartmentItem) => {
    setSelectedDepartment(item);
  }, []);

  // 카테고리 타입 여부 체크
  const instanceOfCategory = useCallback((object: any): object is TreatmentCategoryItem => {
    if (typeof object === 'object') {
      return 'categoryId' in object;
    } else {
      return false;
    }
  }, []);

  // 팝업 설정
  const setPopupData = (
    contents: JSX.Element,
    isWithCancel: boolean,
    handleOk?: VoidFunction,
    handleCancel?: VoidFunction,
  ) => {
    setIsWithCancel(isWithCancel);
    setPopupContents(contents);
    setPopupHandleOk(handleOk);
    setPopupHandleCancel(handleCancel);
  };

  // 편집 버튼 클릭
  const onClickEdit = useCallback(
    (item: DepartmentItem | TreatmentCategoryItem) => {
      if (instanceOfCategory(item)) {
        setSelectedCategory(item);
        setIsTreatVisible(true);
        setCategoryName(item.categoryName);
        setCategoryNameEn(item.categoryNameEn);
        setCategoryNameZh(item.categoryNameZh);
        setIsCategoryDisplay(item.enabled);
      } else {
        setEditDepartment(item);
        setDeptName(item.name);
        setDeptNameEn(item.nameEn);
        setDeptNameZh(item.nameZh);
        setIsDeptDisplay(item.enabled);
        setIsDeptVisible(true);
      }
    },[instanceOfCategory]);

  // 진료과 유효성 검사
  const checkValidationDept = useCallback((): boolean => {
    let validation = true;
    if (!deptName) {
      setIsEmptyDeptName(true);
      validation = false;
    } else {
      setIsEmptyDeptName(false);
    }

    if (validation) {
      setIsEmptyDeptName(false);
    } else {
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }

    return validation;
  }, [deptName]);

  // 진료과 submit
  const onSubmitDept = useCallback(async () => {
    if (checkValidationDept()) {
      const submitData: DepartmentRequest = {
        departmentName: deptName,
        departmentNameEn: deptNameEn,
        departmentNameZh: deptNameZh,
        enabled: isDeptDisplay,
      };

      if (departments.find(row => row.name === deptName && row.id !== editDepartment?.id)) {
        setPopupData(popupText.duplicate, false);
        setIsConfirmPopupVisible(true);
      } else {
        if (editDepartment) {
          // 수정
          await modifyDepartment(editDepartment.id, submitData);
        } else {
          // 등록
          await addDepartment(submitData);
        }

        setIsDeptVisible(false);
      }
    }
  }, [addDepartment, checkValidationDept, departments, deptName, deptNameEn, deptNameZh,
    editDepartment, isDeptDisplay, modifyDepartment]);

  // 진료과 유효성 검사
  const checkValidationCategory = useCallback((): boolean => {
    let validation = true;
    if (!categoryName) {
      setIsEmptyCategoryName(true);
      validation = false;
    } else {
      setIsEmptyCategoryName(false);
    }

    if (validation) {
      setIsEmptyCategoryName(false);
    } else {
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }

    return validation;
  }, [categoryName]);

  // 카테고리 submit
  const onSubmitCategory = useCallback(async () => {
    if (checkValidationCategory() && selectedDepartment) {
      const submitData: TreatmentCategoryRequest = {
        categoryName: categoryName,
        categoryNameEn: categoryNameEn,
        categoryNameZh: categoryNameZh,
        enabled: isCategoryDisplay,
      };

      if (
        categories.find(row => row.categoryName === categoryName && row.categoryId !== selectedCategory?.categoryId)
      ) {
        setPopupData(popupText.duplicateCategory, false);
        setIsConfirmPopupVisible(true);
      } else {
        if (selectedCategory) {
          // 수정
          await modifyCategory(selectedDepartment.id, selectedCategory.categoryId, submitData);
        } else {
          // 등록
          await addCategory(selectedDepartment.id, submitData);
        }

        setIsTreatVisible(false);
      }
    }
  }, [
    addCategory,
    categories,
    categoryName,
    categoryNameEn,
    categoryNameZh,
    checkValidationCategory,
    isCategoryDisplay,
    modifyCategory,
    selectedCategory,
    selectedDepartment,
  ]);

  // 진료과 데이터 호출
  useEffect(() => {
    getAllDepartments();
  }, [getAllDepartments]);

  // 진료과 선택 되면 카테고리 데이터 호출
  useEffect(() => {
    if (selectedDepartment) {
      getCategories(selectedDepartment.id);
    } else {
      setCategories([]);
    }
  }, [getCategories, selectedDepartment]);

  return (
    <>
      <Layout>
        <GNB />
        <Layout>
          <LNB defaultMenu={['6']} />
          <StyledLayout>
            <PageHeading>카테고리 관리</PageHeading>
            <StyledContent>
              <Wrapper>
                <TableWrapper>
                  <TableHeading hasButton="추가" onClick={onClickDept}>
                    진료과
                  </TableHeading>
                  <Table
                    list={departments}
                    onClick={onClickDepartment}
                    onClickEdit={onClickEdit}
                    swapDepartmentOrder={swapDepartmentOrder}
                    selectedId={selectedDepartment?.id}
                  />
                </TableWrapper>
                <TableWrapper>
                  <TableHeading hasButton="추가" onClick={onClickTreat}>
                    시술 카테고리
                  </TableHeading>
                  <Table
                    list={categories}
                    onClickEdit={onClickEdit}
                    parentEnable={selectedDepartment?.enabled}
                    swapCategoryOrder={swapCategoryOrder}
                  />
                </TableWrapper>
              </Wrapper>
            </StyledContent>
          </StyledLayout>
        </Layout>
      </Layout>
      {/*  5-1.카테고리 관리 - 진료과 추가 팝업  */}
      {isDeptVisible && (
        <DefaultPopup
          title={selectedDepartment ? '편집' : '추가'}
          isPopupVisible={isDeptVisible}
          setIsPopupVisible={setIsDeptVisible}
          onClickOk={onSubmitDept}
          okWithoutClose={true}
          isWithCancel
          isWithSave
        >
          <form onSubmit={onSubmitDept}>
            <LabelInputTranslate text="진료과"
                                 defaultValue={deptName}
                                 enValue={deptNameEn}
                                 zhValue={deptNameZh}
                                 onChangeKo={onChangeDeptName}
                                 onChangeEn={onChangeDeptNameEn}
                                 onChangeZh={onChangeDeptNameZh}
                                 isRequiredEmpty={isEmptyDeptName}
                                 isPopup />
            <Radio.Group onChange={onChangeDeptDisplay} value={isDeptDisplay}>
              <StyledRadio value={true}>사용</StyledRadio>
              <StyledRadio value={false}>미사용</StyledRadio>
            </Radio.Group>
          </form>
        </DefaultPopup>
      )}

      {/*  5-1.카테고리 관리 - 시술 카테고리 추가팝업  */}
      {isTreatVisible && (
        <DefaultPopup
          title={selectedCategory ? '편집' : '추가'}
          isPopupVisible={isTreatVisible}
          setIsPopupVisible={setIsTreatVisible}
          onClickOk={onSubmitCategory}
          okWithoutClose={true}
          isWithCancel
          isWithSave
        >
          <form onSubmit={onSubmitCategory}>
            <LabelText text="진료과">
              <LabelText text="한국어" isPopup isSubMenu>
                {selectedDepartment?.name}
              </LabelText>
              <LabelText text="영어" isPopup isSubMenu>
                {selectedDepartment?.nameEn}
              </LabelText>
              <LabelText text="중국어" isPopup isSubMenu>
                {selectedDepartment?.nameZh}
              </LabelText>
            </LabelText>
            <LabelInputTranslate
              text="시술 카테고리"
              defaultValue={categoryName}
              enValue={categoryNameEn}
              zhValue={categoryNameZh}
              onChangeKo={onChangeCategoryName}
              onChangeEn={onChangeCategoryNameEn}
              onChangeZh={onChangeCategoryNameZh}
              isRequiredEmpty={isEmptyCategoryName}
            />
            <Radio.Group onChange={onChangeCategoryDisplay} value={isCategoryDisplay}>
              <StyledRadio value={true}>사용</StyledRadio>
              <StyledRadio value={false}>미사용</StyledRadio>
            </Radio.Group>
          </form>
        </DefaultPopup>
      )}

      {/* 5-1.카테고리 관리 - 진료과 편집 팝업 > 팝업 2.4.2,  5-1.카테고리 관리 - 시술 카테고리 편집(팝업) 3-4-2*/}
      {isConfirmPopupVisible && (
        <ConfirmPopup
          isWithCancel={isWithCancel}
          handleOk={popupHandleOk}
          handleCancel={popupHandleCancel}
          isPopupVisible={isConfirmPopupVisible}
          setIsPopupVisible={setIsConfirmPopupVisible}>
          {popupContents}
        </ConfirmPopup>
      )}
    </>
  );
};

export default Category;
