import { IssuedSignedUrl } from '../model';
import api from './index';
import axios from 'axios';

const File = {
  getPresignedUrl: (fileName: string) => {
    return api.get<IssuedSignedUrl>(`/file/presigned-url?fileName=${fileName}`);
  },
  uploadFile: (signedUrl: string, file: File) => {
    return axios.put(signedUrl, file, {headers: {"Content-Type": file.type}});
  },
};

export default File;