import React, { useCallback, useMemo } from 'react';
import { StyledSelect, StyledOption, HelperWrapper, HelperText } from "./styles";

export interface ItemDefault {
	value: string;
	text: string;
}

interface SelectProps<T> {
	optionList: T[];
	value?: any;
	style?: any;
	isRequiredEmpty?: boolean;
	onChange?: (value: any, option: any | any[]) => void;
	disabled?: boolean;
}

const Select = <ItemType extends ItemDefault | string>(props: SelectProps<ItemType>) => {
	const instanceOfItem = useCallback((object: any): object is ItemDefault => {
		if (typeof object === 'object') {
			return 'value' in object && 'text' in object;
		} else {
			return false;
		}
	}, []);

	const defaultValue = useMemo(() => {
		if (props.optionList?.length > 0 && props.optionList) {
			if (instanceOfItem(props.optionList?.[0])) {
				return props.optionList[0].value;
			} else {
				return props.optionList[0];
			}
		} else {
			return "";
		}
	}, [instanceOfItem, props.optionList]);

	const options = useMemo(() => {
		const list = props.optionList?.map((option, i) => {
			if (instanceOfItem(option)) {
				return (
					<StyledOption key={i} value={option.value}>
						{option.text}
					</StyledOption>
				);
			} else {
				return (
					<StyledOption key={i} value={option}>
						{option}
					</StyledOption>
				);
			}
		})

		return list;
	}, [instanceOfItem, props.optionList]);

	return (
		<>
			<StyledSelect 
				value={props.value} 
				defaultValue={defaultValue} 
				style={props.style} 
				onChange={props.onChange} 
				dropdownStyle={{ borderRadius: "4px" }}
				disabled={props.disabled}
			>
				{options}
			</StyledSelect>
			{props.isRequiredEmpty &&
				<HelperWrapper>
					<HelperText>필수 입력 값입니다.</HelperText>
				</HelperWrapper>
			}
		</>
	);
};

export default React.memo(Select);
