import { DataResponse, ItemsResponse, MemberDetail, MemberInventoryItem, MemberSearchOptions, ResignedMemberSearchOptions } from '../model';
import api from './index';
import Qs from 'qs';

const Member = {
   getActivatedMembers: (
    page: number = 0, 
    size: number = 25, 
    keywordOption: MemberSearchOptions = MemberSearchOptions.ALL,
    keyword?: string,
    start?: string,
    end?: string,
  ) => {
    return api.get<DataResponse<ItemsResponse<MemberInventoryItem>>>(
      '/activated-members', 
      {
        params: {
          pageNumber: page,
          pageSize: size,
          keywordOption: keywordOption,
          keyword: keyword,
          start: start,
          end: end,
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    );
  },
  getResignedMembers: (
    page: number = 0, 
    size: number = 25, 
    keywordOption: ResignedMemberSearchOptions = ResignedMemberSearchOptions.ALL,
    keyword?: string,
    start?: string,
    end?: string,
  ) => {
    return api.get<DataResponse<ItemsResponse<MemberInventoryItem>>>(
      '/resigned-members', 
      {
        params: {
          pageNumber: page,
          pageSize: size,
          keywordOption: keywordOption,
          keyword: keyword,
          start: start,
          end: end,
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    );
  },
  getMember: (memberId: string) => {
    return api.get<DataResponse<MemberDetail>>(`/members/${memberId}`);
  },
};

export default Member;