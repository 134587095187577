import { ChangeEvent, FormEvent, Key, ReactNode, useCallback, useEffect, useState } from "react";
import { Breadcrumb, TablePaginationConfig } from "antd";
import ContentHeading from "../../../components/ContentHeading";
import SearchBox from "../../../components/SearchBox";
import Select from "../../../components/Inputs/Select";
import TableDefault from "../../../components/Tables/TableDefault";
import TableSort from "../../../components/Tables/TableSort";
import InnerTdButton from "../../../components/InnerTdButton";
import ToggleButton from "../../../components/ToggleButton";
import SelectOptGroup from "./SelectOptGroup";
import DefaultPopup from "../../../components/Popup/DefaultPopup";
import ConfirmPopup from "../../../components/Popup/ConfirmPopup";
import Input from "../../../components/Inputs/Input";
import { StyledLayout, FlexWrapper, InputWrapper, SelectWrapper, Button } from "./styles";
import { useApi } from "../../../contexts/ApiContext";
import { DepartmentItem, DepartmentWithCategoryList, HospitalInventory, HospitalInventoryItem, ItemsResponse, SearchPeriodOption, TreatmentItemForAdmin, TreatmentSearchKeywordOption } from "../../../model";
import { EventValue, RangeValue } from "rc-picker/lib/interface";
import moment, { Moment } from "moment";
import { ColumnsType } from "antd/lib/table";
import { useNavigate } from "react-router-dom";
import { TableRowSelection } from "antd/lib/table/interface";

const optionList = ["전체", "시술명", "병원명"];
const optionListUnder = ["등록일", "수정일"];
const dateFormat = "YYYY-MM-DD";
const popupText = {
	hide: 
		<>
			미노출시 앱에 표시되지 않습니다. <br />
			미노출 처리 하시겠습니까?
		</>,
	unhideable:
		<>
			홈 화면 배너 시술 컨텐츠로 등록되어 있어<br />
			미노출이 불가능 합니다.
		</>,
	delete:
		<>
			삭제 하시겠습니까?<br />
			삭제된 정보는 복구가 불가능하며<br />
			앱에 바로 반영됩니다.
		</>,
};

const Treat = () => {
	const navigate = useNavigate();
	const api = useApi();

	// 진료과 목록
	const [departments ,setDepartments] = useState<DepartmentItem[]>([]);
	const [selectedDepartment, setSelectedDepartment] = useState<DepartmentItem>();

	// 전체 시술 카테고리 목록
	const [allCategories, setAllCategories] = useState<DepartmentWithCategoryList[]>([]);

	// 노출 카테고리 (진료과에 따라 달라짐)
	const [showCategories, setShowCategories] = useState<DepartmentWithCategoryList[]>([]);
	const [selectedCategoryId, setSelectedCategoryId] = useState<string>();

	// 시술 목록
	const [treatsData, setTreatsData] = useState<ItemsResponse<TreatmentItemForAdmin>>();
	const [treats, setTreats] = useState<TreatmentItemForAdmin[]>([]);

	// 시술 페이징
	const [page, setPage] = useState<number>(0);
	const [size,] = useState<number>(25);

	// 시술 검색
	const [type, setType] = useState<TreatmentSearchKeywordOption>(TreatmentSearchKeywordOption.ALL);
	const [searchType, setSearchType] = useState<TreatmentSearchKeywordOption>(TreatmentSearchKeywordOption.ALL);

	const [keyword, setKeyword] = useState<string>();
	const [searchKeyword, setSearchKeyword] = useState<string>();

	const [dateType, setDateType] = useState<SearchPeriodOption>(SearchPeriodOption.CREATED);
	const [searchDateType, setSearchDateType] = useState<SearchPeriodOption>();

	const [startDate, setStartDate] = useState<EventValue<Moment> | undefined>(moment('2022-01-01'));
	const [endDate, setEndDate] = useState<EventValue<Moment> | undefined>(moment());
	const [searchStartDate, setSearchStartDate] = useState<EventValue<Moment>>(moment('2022-01-01'));
	const [searchEndDate, setSearchEndDate] = useState<EventValue<Moment>>(moment());

	// 병원 목록
	const [hospitalData, setHospitalData] = useState<HospitalInventory>();
	const [hospitals, setHospitals] = useState<HospitalInventoryItem[]>([]);
	const [selectedHospital, setSelectedHospital] = useState<Key[]>([]);

	// 병원 검색
	const [hospitalKeyword, setHospitalKeyword] = useState<string>();
	const [searchHospitalKeyword, setSearchHospitalKeyword] = useState<string>();

	// 병원 페이징
	const [hospitalPage, setHospitalPage] = useState<number>(0);
	const [hospitalSize,] = useState<number>(10);

	// 팝업 관리
	const [popupContents, setPopupContents] = useState<string | ReactNode>();
	const [popupHandleOk, setPopupHandleOk] = useState(() => () => {});
	const [popupHandleCancel, setPopupHandleCancel] = useState(() => () => {});
	const [isWithCancel, setIsWithCancel] = useState(false);
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [isConfirmVisible, setIsConfirmVisible] = useState(false);

	// 병원 데이터
	const getAllHospitals = useCallback(async () => {
		const data = (await api.hospital.getAllHospitals(hospitalPage, hospitalSize, searchHospitalKeyword, selectedDepartment?.id)).data;
		setHospitalData(data);
	}, [api.hospital, hospitalPage, hospitalSize, searchHospitalKeyword, selectedDepartment?.id]);

	// 진료과 데이터
	const getAllDepartments = useCallback(async () => {
		const data = (await api.category.getDepartments()).data;
		if (data.success) {
			setDepartments(data.result);
		}
	}, [api.category]);

	// 시술 카테고리
	const getAllCategories = useCallback(async () => {
		const data = (await api.category.getAllCategories()).data;
		if (data.status) {
			setAllCategories(data.data);
		}
	}, [api.category]);

	// 시술 목록
	const getTreatments = useCallback(async () => {
		const data = (await api.treatment.getTreatments(
			searchType,
			searchStartDate?.format(dateFormat) ?? moment().subtract(6, "month").format(dateFormat), 
			searchEndDate?.format(dateFormat) ?? moment().format(dateFormat),
			searchKeyword,
			searchDateType,
			selectedDepartment?.id,
			selectedCategoryId,
			page,
			size,
		)).data;
		setTreatsData(data.data);
	}, [api.treatment, page, searchDateType, searchEndDate, searchKeyword, searchStartDate, searchType, selectedCategoryId, selectedDepartment?.id, size]);

	// 시술 노출 여부 변경
	const changeState = useCallback(async (treatmentId: string, state: boolean) => {
		let result: boolean;
		if (state) {
			result = (await api.treatment.activateTreatment(treatmentId)).data.activated;
		} else {
			result = (await api.treatment.deactivateTreatment(treatmentId)).data.deactivated;
		}

		if (result) {
			getTreatments();
		}
	}, [api.treatment, getTreatments]);

	// 시술 삭제
	const deleteTreatment = useCallback(async (treatmentId: string) => {
		const data = (await api.treatment.deleteTreatment(treatmentId)).data;

		if(data.deleted) {
			getTreatments();
		}
	}, [api.treatment, getTreatments]);

	// 팝업 설정
	const setPopupData = (contents: JSX.Element, isWithCancel: boolean, handleOk: VoidFunction, handleCancel: VoidFunction) => {
		setIsWithCancel(isWithCancel);
		setPopupContents(contents);
		setPopupHandleOk(handleOk);
		setPopupHandleCancel(handleCancel);
	};

	// 시술 노출 가능 여부 확인
	const checkDeactivation = async (item: TreatmentItemForAdmin) => {
		if(item.enabled) {
			const data = (await api.treatment.checkBannerTreatment(item.treatmentId)).data;
			if(data.assigned) {
				deactivationPopup();
			} else {
				onChangeToggle(item);
			}
		} else {
			onChangeToggle(item);
		}		
	};

	// 미노출 불가 Popup
	const deactivationPopup = () => {
		let isWithCancel = false;
		let popupContents = popupText.unhideable;
		let handleOk = () => () => setIsConfirmVisible(false);
		let handleCancel = () => () => setIsConfirmVisible(false);

		setPopupData(popupContents, isWithCancel, handleOk, handleCancel);
		setIsConfirmVisible(true);
	};

	// 노출 여부 토글
	const onChangeToggle = useCallback(async (item: TreatmentItemForAdmin) => {
		let isWithCancel = false;
		let popupContents = popupText.hide;
		let handleOk = () => () => setIsConfirmVisible(false);
		let handleCancel = () => () => setIsConfirmVisible(false);

		if (item.enabled) {
			// 숨김
			popupContents = popupText.hide;
			handleOk = () => async () => await changeState(item.treatmentId, false);
			isWithCancel = true;

			setPopupData(popupContents, isWithCancel, handleOk, handleCancel);
			setIsConfirmVisible(true);
		} else {
			// 노출 - 팝업 없이 바로 변경
			await changeState(item.treatmentId, true)
		}
	}, [changeState]);

	// 삭제
	const onDelete = useCallback((item: TreatmentItemForAdmin) => {
		const handleOk = () => async () => deleteTreatment(item.treatmentId);
		const handleCancel = () => async () => setIsConfirmVisible(false);

		setPopupData(popupText.delete, true, handleOk, handleCancel);
		setIsConfirmVisible(true);
	}, [deleteTreatment]);

	// 검색어 검색 기준
	const onChangeOption = (e: any) => {
		switch (e) {
			case "전체": setType(TreatmentSearchKeywordOption.ALL); break;
			case "시술명": setType(TreatmentSearchKeywordOption.TREATMENT); break;
			case "병원명": setType(TreatmentSearchKeywordOption.HOSPITAL); break;
		}
	};
	// 날짜 검색 기준
	const onChangeUnderOption = (e: any) => {
		switch (e) {
			case "등록일": setDateType(SearchPeriodOption.CREATED); break;
			case "수정일": setDateType(SearchPeriodOption.UPDATED); break;
		}
	};

	// 검색 키워드
	const onChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
		setKeyword(e.target.value);
	};

	// 날짜 변경
	const onChangeDate = (values: RangeValue<Moment>) => {
		setStartDate(values?.[0]);
		setEndDate(values?.[1]);
	};

	// 검색 submit
	const onSubmit = useCallback((e: FormEvent) => {
		e.preventDefault();

		setPage(0);

		setSearchType(type);
		setSearchKeyword(keyword);

		setSearchDateType(dateType);
		setSearchStartDate(startDate ?? moment().subtract(6, "month"));
		setSearchEndDate(endDate ?? moment());
	}, [dateType, endDate, keyword, startDate, type]);

	// 진료과 변경
	const onChangeDepartments = useCallback((value: string) => {
		const department = departments.find(row => row.name === value);
		setSelectedDepartment(department);
	}, [departments]);

	// 노출 시술 카테고리 결정
	const getShowCategories = useCallback(() => {
		if (selectedDepartment) {
			setShowCategories(
				allCategories.filter(row => row.departmentId === selectedDepartment.id)
			);
		} else {
			setShowCategories(allCategories);
		}
	},[allCategories, selectedDepartment]);

	// 시술 카테고리 변경
	const onChangeCategories = useCallback((value: string) => {
		if (value !== "전체 시술 카테고리") {
			setSelectedCategoryId(value);
		} else {
			setSelectedCategoryId(undefined);
		}
	}, []);

	// 등록 (병원 선택 팝업)
	const onWrite = () => {
		setIsPopupVisible(true);
	};

	// 병원 검색어
	const onChangeHospitalKeyword = (e: ChangeEvent<HTMLInputElement>) => {
		setHospitalKeyword(e.target.value);
	};

	// 병원 검색
	const onSubmitHospital = useCallback((e: FormEvent) => {
		e.preventDefault();

		setSearchHospitalKeyword(hospitalKeyword);
	}, [hospitalKeyword]);

	// 병원 팝업 확인 (의사 등록)
	const onClickOkHospital = useCallback(() => {
		if (selectedHospital.length > 0) {
			const key = selectedHospital[0];
			navigate(`/contents/treat/write/${key}`);
		}
	}, [navigate, selectedHospital]);
	// 병원 확인 취소
	const onClickCancelHospital = useCallback(() => setIsPopupVisible(false), []);

	// 시술 목록 칼럼
	const columns: ColumnsType<TreatmentItemForAdmin> = [
		{
			title: "번호",
			dataIndex: "number",
			key: "number",
			render: (value: any, record: TreatmentItemForAdmin, index: number) => {
				return (size * page) + (index + 1);
			}
		},
		{
			title: "등록일",
			dataIndex: "createdAt",
			key: "createdAt",
			sorter: (a, b) => {
				const aDate = moment(a.createdAt);
				const bDate = moment(b.createdAt);
				return bDate.diff(aDate);
			},
		},
		{
			title: "수정일",
			dataIndex: "updatedAt",
			key: "updatedAt",
			sorter: (a, b) => {
				const aDate = moment(a.updatedAt);
				const bDate = moment(b.updatedAt);
				return bDate.diff(aDate);
			},
		},
		{ title: "진료과", dataIndex: "departmentName", key: "departmentName" },
		{ title: "시술 카테고리", dataIndex: "treatmentCategoryName", key: "treatmentCategoryName" },
		{ title: "시술명", dataIndex: "name", key: "name" },
		{
			title: "병원명",
			dataIndex: "hospitalName",
			key: "hospitalName",
			sorter: (a, b) => {
				return a.hospitalName < b.hospitalName ? -1 : a.hospitalName === b.hospitalName ? 0 : 1;
			},
		},
		{ 
			title: "관리", 
			dataIndex: "doctor",
			key: "doctor", 
			render: (value, record) => 
				<InnerTdButton 
					isWithEdit 
					isEditLink 
					onClickDelete={() => {onDelete(record)}} 
					link={`/contents/treat/${record.treatmentId}`} 
				/> 
			},
		{ 
			title: "노출", 
			dataIndex: "exposure", 
			key: "exposure", 
			render: (value, record) => 
				<ToggleButton checked={record.enabled} onChange={() => checkDeactivation(record)} /> 
		},
	];

	// 시술 목록 페이지네이션
	const pagination: TablePaginationConfig = { 
		position: ["bottomCenter"], 
		defaultPageSize: size,
		showSizeChanger: false,
		total: treatsData?.totalCount, 
		current: page + 1,
		onChange: (page: number, pageSize: number) => {
			setPage(page - 1);
		},
	};

	// 병원 목록 칼럼
	const popupColumns = [
		{
			title: "등록일",
			dataIndex: "registerDate",
			key: "registerDate",
			sorter: (a: HospitalInventoryItem, b: HospitalInventoryItem) => {
				const aDate = moment(a.registerDate, "YYYY.MM.DD");
				const bDate = moment(b.registerDate, "YYYY.MM.DD");
				return bDate.diff(aDate);
			},
		},
		{ title: "진료과", dataIndex: "departmentName", key: "departmentName" },
		{ title: "병원명", dataIndex: "hospitalName", key: "hospitalName" },
	];

	// 병원 목록 페이지네이션
	const hospitalPagination: TablePaginationConfig = { 
		position: ["bottomCenter"], 
		defaultPageSize: hospitalSize,
		showSizeChanger: false,
		total: hospitalData?.totalElements, 
		current: hospitalPage + 1,
		onChange: (page: number, pageSize: number) => {
			setHospitalPage(page - 1);
		},
	};

	// 병원 선택 체크 박스
	const rowSelection: TableRowSelection<HospitalInventoryItem> = {
		onChange: (keys) => {
			setSelectedHospital(keys);
		},
		getCheckboxProps: (record) => {
			if (selectedHospital.length > 0 && record.key !== selectedHospital[0]) {
				return {
					disabled: true,
				}
			} else {
				return {
					disabled: false,
				}
			}
		},
		
		hideSelectAll: true,
	};
	
	// 병원 목록 호출
	useEffect(() => {
		getAllHospitals();
	}, [getAllHospitals]);

	// 병원 목록 데이터 가공
	useEffect(() => {
		const data = hospitalData?.hospitals.map((row) => {
			return { key: row.hospitalId, ...row };
		});

		if (data) {
			setHospitals(data);
		}
	}, [hospitalData]);

	// 진료과 목록 호출
	useEffect(() => {
		getAllDepartments();
	}, [getAllDepartments]);

	// 전체 시술 카테고리
	useEffect(() => {
		getAllCategories();
	}, [getAllCategories]);

	// 노출할 시술 카테고리 선택
	useEffect(() => {
		getShowCategories();
	}, [getShowCategories]);

	// 시술 목록 호출
	useEffect(() => {
		getTreatments();
	}, [getTreatments]);

	// 시술 목록 데이터 가공
	useEffect(() => {
		const data = treatsData?.items.map((row) => {
			return { key: row.treatmentId, ...row };
		});

		if (data) {
			setTreats(data);
		}
	}, [treatsData]);

	return (
		<>
			<Breadcrumb separator=">">
				<Breadcrumb.Item>컨텐츠 관리</Breadcrumb.Item>
				<Breadcrumb.Item href="">시술 관리</Breadcrumb.Item>
			</Breadcrumb>

			<ContentHeading hasButton onClick={onWrite}>
				시술 관리
			</ContentHeading>

			<StyledLayout>
				<SearchBox 
					optionList={optionList} 
					optionListUnder={optionListUnder}
					searchValue={keyword}
					onChangeSearch={onChangeKeyword}
					onChangeOption={onChangeOption}
					onChangeUnderOption={onChangeUnderOption}
					startDate={startDate}
					endDate={endDate}
					onChangeDate={onChangeDate}
					onSubmit={onSubmit}
					allowClear={false}
				/>
				<FlexWrapper>
					<span>
						총 <strong>{treatsData?.totalCount ?? 0}</strong>개
					</span>
					<SelectWrapper>
						<Select value={selectedDepartment?.name ?? '전체 진료과'} onChange={onChangeDepartments} optionList={["전체 진료과", ...(departments.map((row) => (row.name)))]} />
						<SelectOptGroup list={showCategories} onChange={onChangeCategories} />
					</SelectWrapper>
				</FlexWrapper>
				<TableDefault columns={columns} dataSource={treats} pagination={pagination} />
			</StyledLayout>

			{/* 병원 선택 팝업 */}
			{isPopupVisible && (
				<DefaultPopup title="등록" isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} onClickOk={onClickOkHospital} onClickCancel={onClickCancelHospital} isWithCancel isWithOkay>
				<form onSubmit={onSubmitHospital}>
					<InputWrapper>
						<Input type="text" placeholder="검색어를 입력하세요." onChange={onChangeHospitalKeyword} />
						<Button isFilled isSmall>
							검색
						</Button>
					</InputWrapper>
				</form>
				<TableSort columns={popupColumns} data={hospitals} scroll={{ y: 275 }} pagination={hospitalPagination} rowSelection={rowSelection} />
			</DefaultPopup>
			)}

			{isConfirmVisible && (
				<ConfirmPopup isWithCancel={isWithCancel} handleOk={popupHandleOk} handleCancel={popupHandleCancel} isPopupVisible={isConfirmVisible} setIsPopupVisible={setIsConfirmVisible}>
					{popupContents}
				</ConfirmPopup>
			)}

			{/* 4-1 팝업 */}
			{/* {isConfirmVisible && (
				<ConfirmPopup isPopupVisible={isConfirmVisible} setIsPopupVisible={setIsConfirmVisible}>
					필수 항목을 다시 확인해 주세요.
				</ConfirmPopup>
			)} */}

			{/* 3-11-1 팝업 */}
			{/* {isDeleteVisible && (
				<ConfirmPopup isPopupVisible={isDeleteVisible} setIsPopupVisible={setIsDeleteVisible} isWithCancel>
					삭제 하시겠습니까?
					<br />
					삭제된 정보는 복구가 불가능하며
					<br />
					앱에 바로 반영됩니다.
				</ConfirmPopup>
			)} */}

			{/* 3-12-1 팝업 */}
			{/* {isTogglePopupVisible && (
				<ConfirmPopup isPopupVisible={isTogglePopupVisible} setIsPopupVisible={setIsTogglePopupVisible} isWithCancel>
					미노출시 앱에 표시되지 않습니다. <br />
					미노출 처리 하시겠습니까?
				</ConfirmPopup>
			)} */}

			{/* 3-12-2 팝업 */}
			{/* {isTogglePopupVisible && (
				<ConfirmPopup isPopupVisible={isTogglePopupVisible} setIsPopupVisible={setIsTogglePopupVisible}>
					홈 화면 배너 시술 컨텐츠로 등록되어 있어
					<br />
					미노출이 불가능 합니다.
				</ConfirmPopup>
			)} */}
		</>
	);
};

export default Treat;
