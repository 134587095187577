import styled from "styled-components";
import { Layout } from "antd";
import { buttonBase, flexbox } from "../../styles/mixins";

export const StyledLayout = styled(Layout)`
	margin: 64px 0 0 260px; // for fixed gnb and lnb
	padding: 0 30px 24px;

	@media screen and (max-width: 1535px) {
		margin: 64px 0 0 150px;
	}
`;

export const StyledContent = styled(Layout.Content)`
	padding: 24px;
	margin: 0 0 0 24px;
	min-height: 280px;
`;

export const Section = styled.div`
	margin: 0 0 150px 0;

	&:last-of-type {
		margin: 0 0 100px 0;
	}
`;

export const FlexWrapper = styled.div`
	${flexbox("space-between", "center")};
	margin: 35px 0;
`;

export const SelectInputWrapper = styled.div`
	${flexbox};
`;

export const Button = styled.button<{isFilled?: boolean; isBig?: boolean}>`
	${buttonBase};
	width: 260px;
	height: 52px;
	margin: 0 auto;
`;

export const SmallButton = styled.button<{isFilled?: boolean; isMiddle?: boolean}>`
	${buttonBase};
	height: 36px;
`;

export const PopupButton = styled.button<{isFilled?: boolean; isSmall?: boolean}>`
	${buttonBase};
	height: 42px;
	margin: 0 0 0 8px;
`;
