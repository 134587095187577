import styled from "styled-components";
import { fontStyle16, fontStyle18, flexbox } from "../../../styles/mixins";

export const TotalNum = styled.span`
	${fontStyle16};
	color: ${(props) => props.theme.primary};
	strong {
		font-weight: 700;
	}
`;

export const DeleteButtonWrapper = styled.div`
	${flexbox("center", "center")};
	width: 100%;
	position: relative;
	top: 4px;
`;

export const InputWrapper = styled.div`
	margin: 0 0 20px 0;

	&:last-of-type {
		margin: 0 0 15px 0;
	}
`;

export const PopupSubHeading = styled.h2`
	${fontStyle18};
	font-weight: 500;
	margin: 0 0 18px 0;
`;
