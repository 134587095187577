import { useEffect, useState } from "react";
import { Layout } from "antd";
import GNB from "../../layouts/GNB";
import LNB from "../../layouts/LNB";
import PageHeading from "../../components/PageHeading";
import Tab from "./Tabs";
import ConfirmPopup from "../../components/Popup/ConfirmPopup";
import { StyledLayout, StyledContent, ButtonWrapper, Button } from "./styles";

import { useApi } from "../../contexts/ApiContext";
import { HomeDepartmentItem } from "../../model";

// 홈 화면 관리 페이지 (기획서 2-1)
const Home = () => {
	const api = useApi();
	
	const [tabs, setTabs] = useState<HomeDepartmentItem[]>();
	const [isPopupVisible, setIsPopupVisible] = useState(false);

	const showPopup = () => setIsPopupVisible(true);

	useEffect(() => {
		const getTabs = async () => {
			let data = (await api.home.getHomeTabs()).data;
			setTabs(data.items);
		};

		getTabs();
	}, [api.home]);

	return (
		<>
			<Layout>
				<GNB />
				<Layout>
					<LNB defaultMenu={["1"]} />
					<StyledLayout>
						<PageHeading>홈 화면 관리</PageHeading>
						<StyledContent>
							<Tab tabs={tabs} />
							{/*
							<ButtonWrapper>
								<Button isFilled isBig onClick={showPopup}>
									저장
								</Button>
							</ButtonWrapper>
							*/}
						</StyledContent>
					</StyledLayout>
				</Layout>
			</Layout>

			{isPopupVisible && (
				// 팝업 9-1
				<ConfirmPopup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} isWithCancel>
					변경한 내용을 저장할까요? <br />
					확인을 누르면 앱에 바로 반영됩니다.
				</ConfirmPopup>
				// 팝업 9-2
				// <ConfirmPopup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible}>
				// 	필수 항목을 다시 확인해 주세요
				// </ConfirmPopup>
				//팝업 9-3
				// <ConfirmPopup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible}>
				// 	변경 사항이 없습니다
				// </ConfirmPopup>
				// 팝업 9-4
				// <ConfirmPopup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} isWithCancel>
				// 				저장되지 않은 변경 내용이 있습니다. <br/>
				// 저장하지 않고 나가려면 확인을 눌러주세요.
				// </ConfirmPopup>
			)}
		</>
	);
};

export default Home;
