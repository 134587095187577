import { ChangeEvent, FormEvent, Key, ReactNode, useCallback, useEffect, useState } from "react";
import { Breadcrumb, TablePaginationConfig } from "antd";
import ContentHeading from "../../../components/ContentHeading";
import SearchBox from "../../../components/SearchBox";
import Select from "../../../components/Inputs/Select";
import TableDefault from "../../../components/Tables/TableDefault";
import TableSort from "../../../components/Tables/TableSort";
import InnerTdButton from "../../../components/InnerTdButton";
import ToggleButton from "../../../components/ToggleButton";
import DefaultPopup from "../../../components/Popup/DefaultPopup";
import ConfirmPopup from "../../../components/Popup/ConfirmPopup";
import Input from "../../../components/Inputs/Input";
import { StyledLayout, FlexWrapper, InputWrapper, Button } from "./styles";
import { DepartmentItem, DoctorInventory, DoctorItem, DoctorSearchTermsOption, HospitalInventory, HospitalInventoryItem, SearchType } from "../../../model";
import { useApi } from "../../../contexts/ApiContext";
import { EventValue, RangeValue } from "rc-picker/lib/interface";
import moment, { Moment } from "moment";
import { TableRowSelection } from "antd/lib/table/interface";
import { useNavigate } from "react-router-dom";

const dateFormat = "YYYYMMDD";
const optionList = ["병원명", "의사"];
const optionListUnder = ["등록일", "수정일"];
const popupText = {
	hide: 
		<>
			미노출시 앱에 표시되지 않습니다.<br />
			미노출 처리 하시겠습니까?
		</>,
	unhideable:
		<>
			홈 화면 컨텐츠에 등록되어 있어 <br />
			미노출이 불가능 합니다.
		</>,
	display:
		<>
			노출 처리 하시겠습니까? <br />
			<small>노출 시 앱에 바로 반영됩니다.</small>
		</>,
	delete:
		<>
			삭제 하시겠습니까?<br />
			삭제된 정보는 복구가 불가능하며<br />
			앱에 바로 반영됩니다.
		</>,
	required:
		<>
			필수 항목을 다시 확인해 주세요.
		</>
};

const Doctor = () => {
	const api = useApi();
	const navigate = useNavigate();

	// 진료과 목록
	const [departments ,setDepartments] = useState<DepartmentItem[]>([]);
	const [selectedDepartment, setSelectedDepartment] = useState<DepartmentItem>();

	// 의사 목록
	const [doctorsData, setDoctorsData] = useState<DoctorInventory>();
	const [doctors, setDoctors] = useState<DoctorItem[]>([]);

	// 의사 검색
	const [type, setType] = useState<DoctorSearchTermsOption>(DoctorSearchTermsOption.HOSPITAL);
	const [searchType, setSearchType] = useState<DoctorSearchTermsOption>();

	const [keyword, setKeyword] = useState<string>();
	const [searchKeyword, setSearchKeyword] = useState<string>();

	const [dateType, setDateType] = useState<SearchType>(SearchType.REG);
	const [searchDateType, setSearchDateType] = useState<SearchType>();

	const [startDate, setStartDate] = useState<EventValue<Moment> | undefined>(moment('2022-01-01'));
	const [endDate, setEndDate] = useState<EventValue<Moment> | undefined>(moment());
	const [searchStartDate, setSearchStartDate] = useState<EventValue<Moment>>();
	const [searchEndDate, setSearchEndDate] = useState<EventValue<Moment>>();

	// 의사 페이징
	const [page, setPage] = useState<number>(0);
	const [size,] = useState<number>(25);

	// 병원 목록
	const [hospitalData, setHospitalData] = useState<HospitalInventory>();
	const [hospitals, setHospitals] = useState<HospitalInventoryItem[]>([]);
	const [selectedHospital, setSelectedHospital] = useState<Key[]>([]);

	// 병원 검색
	const [hospitalKeyword, setHospitalKeyword] = useState<string>();
	const [searchHospitalKeyword, setSearchHospitalKeyword] = useState<string>();

	// 병원 페이징
	const [hospitalPage, setHospitalPage] = useState<number>(0);
	const [hospitalSize,] = useState<number>(10);

	// 팝업 관리
	const [popupContents, setPopupContents] = useState<string | ReactNode>();
	const [popupHandleOk, setPopupHandleOk] = useState(() => () => {});
	const [popupHandleCancel, setPopupHandleCancel] = useState(() => () => {});
	const [isWithCancel, setIsWithCancel] = useState(false);
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [isConfirmVisible, setIsConfirmVisible] = useState(false);

	// 병원 데이터
	const getAllHospitals = useCallback(async () => {
		const data = (await api.hospital.getAllHospitals(hospitalPage, hospitalSize, searchHospitalKeyword, selectedDepartment?.id)).data;
		setHospitalData(data);
	}, [api.hospital, hospitalPage, hospitalSize, searchHospitalKeyword, selectedDepartment?.id]);
	
	// 진료과 데이터
	const getAllDepartments = useCallback(async () => {
		const data = (await api.category.getDepartments()).data;
		if (data.success) {
			setDepartments(data.result);
		}
	}, [api.category]);
	
	// 의사 목록 데이터
	const getDoctors = useCallback(async () => {
		const data = (await api.doctor.getDoctors(page, size, selectedDepartment?.id, searchType, searchKeyword, searchDateType, searchStartDate?.format(dateFormat), searchEndDate?.format(dateFormat))).data;
		setDoctorsData(data);
	}, [api.doctor, page, searchDateType, searchEndDate, searchKeyword, searchStartDate, searchType, selectedDepartment?.id, size]);

	// 노출 api
	const changeState = useCallback(async (item: DoctorItem) => {
		const data = (await api.doctor.modifyDoctorDisplay(item.doctorId, !item.doctorDisplay)).data;

		if (data.changed) {
			setIsConfirmVisible(false);
			getDoctors();
		}
	}, [api.doctor, getDoctors]);

	const deleteDoctor = useCallback(async (item: DoctorItem) => {
		const data = (await api.doctor.deleteDoctor(item.doctorId)).data;

		if (data.deleted) {
			setIsConfirmVisible(false);
			getDoctors();
		}
	}, [api.doctor, getDoctors]);

	// 검색어 검색 기준
	const onChangeOption = (e: any) => {
		switch (e) {
			case "병원명": setType(DoctorSearchTermsOption.HOSPITAL); break;
			case "의사": setType(DoctorSearchTermsOption.DOCTOR); break;
		}
	};
	// 날짜 검색 기준
	const onChangeUnderOption = (e: any) => {
		switch (e) {
			case "등록일": setDateType(SearchType.REG); break;
			case "수정일": setDateType(SearchType.MOD); break;
		}
	};

	// 검색 키워드
	const onChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
		setKeyword(e.target.value);
	};

	// 날짜 변경
	const onChangeDate = (values: RangeValue<Moment>) => {
		setStartDate(values?.[0]);
		setEndDate(values?.[1]);
	};

	// 검색 submit
	const onSubmit = useCallback((e: FormEvent) => {
		e.preventDefault();

		setPage(0);

		setSearchType(type);
		setSearchKeyword(keyword);

		setSearchDateType(dateType);
		setSearchStartDate(startDate);
		setSearchEndDate(endDate);
	}, [dateType, endDate, keyword, startDate, type]);

	// 진료과 변경
	const onChangeDepartments = useCallback((value: string) => {
		const department = departments.find(row => row.name === value);
		setSelectedDepartment(department);
	}, [departments]);

	// 팝업 설정
	const setPopupData = (contents: JSX.Element, isWithCancel: boolean, handleOk: VoidFunction, handleCancel: VoidFunction) => {
		setIsWithCancel(isWithCancel);
		setPopupContents(contents);
		setPopupHandleOk(handleOk);
		setPopupHandleCancel(handleCancel);
	};

	// 미노출 가능 여부 확인
	const checkDeactivation = async (item: DoctorItem) => {
		if (item.doctorDisplay) {
			const data = (await api.doctor.checkRecommendedDoctor(item.doctorId)).data;
			if(data.recommended) {
				deactivationPopup();
			} else {
				onChangeToggle(item);
			}
		} else {
			onChangeToggle(item);
		}
	};

	// 미노출 불가 Popup
	const deactivationPopup = () => {
		let isWithCancel = false;
		let popupContents = popupText.unhideable;
		let handleOk = () => () => setIsConfirmVisible(false);
		let handleCancel = () => () => setIsConfirmVisible(false);

		setPopupData(popupContents, isWithCancel, handleOk, handleCancel);
		setIsConfirmVisible(true);
	};

	const requiredPopup = () => {
		let isWithCancel = false;
		let popupContents = popupText.required;
		let handleOk = () => () => setIsConfirmVisible(false);
		let handleCancel = () => () => setIsConfirmVisible(false);

		setPopupData(popupContents, isWithCancel, handleOk, handleCancel);
		setIsConfirmVisible(true);
	};

	// 노출 여부 토글
	const onChangeToggle = useCallback((item: DoctorItem) => {
		let isWithCancel: boolean;
		let popupContents: JSX.Element;
		let handleOk: () => () => void;
		let handleCancel = () => () => setIsConfirmVisible(false);

		if (item.doctorDisplay) {
			// 숨김
			popupContents = popupText.hide;
			handleOk = () => async () => await changeState(item);
			isWithCancel = true;
		} else {
			// 노출
			popupContents = popupText.display;
			handleOk = () => async () => await changeState(item);
			isWithCancel = true;
		}

		setPopupData(popupContents, isWithCancel, handleOk, handleCancel);
		setIsConfirmVisible(true);
	}, [changeState]);

	// 삭제
	const onDelete = useCallback((item: DoctorItem) => {
		const handleOk = () => async () => deleteDoctor(item);
		const handleCancel = () => async () => setIsConfirmVisible(false);

		setPopupData(popupText.delete, true, handleOk, handleCancel);
		setIsConfirmVisible(true);
	}, [deleteDoctor]);

	// 등록 (병원 선택 팝업)
	const onWrite = () => {
		setIsPopupVisible(true);
	};

	// 병원 검색어
	const onChangeHospitalKeyword = (e: ChangeEvent<HTMLInputElement>) => {
		setHospitalKeyword(e.target.value);
	};

	// 병원 검색
	const onSubmitHospital = useCallback((e: FormEvent) => {
		e.preventDefault();

		setSearchHospitalKeyword(hospitalKeyword);
	}, [hospitalKeyword]);

	// 병원 팝업 확인 (의사 등록)
	const onClickOkHospital = useCallback(() => {
		if (selectedHospital.length === 0) {
			requiredPopup()
		} else if (selectedHospital.length > 0) {
			const key = selectedHospital[0];
			navigate(`/contents/doctor/write/${key}`);
		}
	}, [navigate, requiredPopup, selectedHospital]);

	// 병원 확인 취소
	const onClickCancelHospital = useCallback(() => setIsPopupVisible(false), []);

	// 의사 목록 칼럼
	const columns = [
		{
			title: "번호",
			dataIndex: "number",
			key: "number",
			render: (value: any, record: DoctorItem, index: number) => {
				return (size * page) + (index + 1);
			},
		},
		{
			title: "등록일",
			dataIndex: "registerDate",
			key: "registerDate",
			sorter: (a: DoctorItem, b: DoctorItem) => {
				const aDate = moment(a.updateDate, "YYYY.MM.DD");
				const bDate = moment(b.updateDate, "YYYY.MM.DD");
				return bDate.diff(aDate);
			},
		},
		{
			title: "수정일",
			dataIndex: "updateDate",
			key: "updateDate",
			sorter: (a: DoctorItem, b: DoctorItem) => {
				const aDate = moment(a.updateDate, "YYYY.MM.DD");
				const bDate = moment(b.updateDate, "YYYY.MM.DD");
				return bDate.diff(aDate);
			},
		},
		{ title: "진료과", dataIndex: "departmentName", key: "departmentName" },
		{ title: "병원명", dataIndex: "hospitalName", key: "hospitalName" },
		{ title: "의사", dataIndex: "doctorName", key: "doctorName" },
		{ 
			title: "관리", 
			dataIndex: "edit", 
			key: "edit", 
			render: (value: any, record: DoctorItem) => 
				<InnerTdButton isWithEdit onClickDelete={() => onDelete(record)} isEditLink link={`/contents/doctor/${record.doctorId}`} /> 
		},
		{ 
			title: "노출", 
			dataIndex: "exposure", 
			key: "exposure", 
			render: (value: any, record: DoctorItem) => 
				<ToggleButton checked={record.doctorDisplay} onChange={() => checkDeactivation(record)} />
		},
	];

	// 의사 목록 페이지네이션
	const pagination: TablePaginationConfig = { 
		position: ["bottomCenter"], 
		defaultPageSize: size,
		showSizeChanger: false,
		total: doctorsData?.totalElements, 
		current: page + 1,
		onChange: (page: number, pageSize: number) => {
			setPage(page - 1);
		},
	};

	// 병원 목록 칼럼
	const popupColumns = [
		{
			title: "등록일",
			dataIndex: "registerDate",
			key: "registerDate",
			sorter: (a: HospitalInventoryItem, b: HospitalInventoryItem) => {
				const aDate = moment(a.registerDate, "YYYY.MM.DD");
				const bDate = moment(b.registerDate, "YYYY.MM.DD");
				return bDate.diff(aDate);
			},
		},
		{ title: "진료과", dataIndex: "departmentName", key: "departmentName" },
		{ title: "병원명", dataIndex: "hospitalName", key: "hospitalName" },
	];

	// 병원 목록 페이지네이션
	const hospitalPagination: TablePaginationConfig = { 
		position: ["bottomCenter"], 
		defaultPageSize: hospitalSize,
		showSizeChanger: false,
		total: hospitalData?.totalElements, 
		current: hospitalPage + 1,
		onChange: (page: number, pageSize: number) => {
			setHospitalPage(page - 1);
		},
	};

	// 병원 선택 체크 박스
	const rowSelection: TableRowSelection<HospitalInventoryItem> = {
		onChange: (keys) => {
			setSelectedHospital(keys);
		},
		getCheckboxProps: (record) => {
			if (selectedHospital.length > 0 && record.key !== selectedHospital[0]) {
				return {
					disabled: true,
				}
			} else {
				return {
					disabled: false,
				}
			}
		},
		
		hideSelectAll: true,
	};

	// 진료과 목록 호출
	useEffect(() => {
		getAllDepartments();
	}, [getAllDepartments]);

	// 의사 목록 호출
	useEffect(() => {
		getDoctors();
	}, [getDoctors]);

	// 의사 목록 데이터 가공
	useEffect(() => {
		const data = doctorsData?.doctors.map((row) => {
			return { key: row.doctorId, ...row };
		});

		if (data) {
			setDoctors(data);
		}
	}, [doctorsData]);

	// 병원 목록 호출
	useEffect(() => {
		getAllHospitals();
	}, [getAllHospitals]);

	// 병원 목록 데이터 가공
	useEffect(() => {
		const data = hospitalData?.hospitals.map((row) => {
			return { key: row.hospitalId, ...row };
		});

		if (data) {
			setHospitals(data);
		}
	}, [hospitalData]);

	return (
		<>
			<Breadcrumb separator=">">
				<Breadcrumb.Item>컨텐츠 관리</Breadcrumb.Item>
				<Breadcrumb.Item href="">의료진 관리</Breadcrumb.Item>
			</Breadcrumb>
			<ContentHeading hasButton onClick={onWrite}>
				의료진 관리
			</ContentHeading>
			<StyledLayout>
				<SearchBox 
					title="" 
					searchValue={keyword}
					onChangeSearch={onChangeKeyword}
					optionList={optionList} 
					onChangeOption={onChangeOption}
					optionListUnder={optionListUnder}
					onChangeUnderOption={onChangeUnderOption}
					startDate={startDate}
					endDate={endDate}
					onChangeDate={onChangeDate}
					onSubmit={onSubmit}
					allowClear={true}
				/>
				<FlexWrapper>
					<span>
						총 <strong>{doctorsData?.totalElements ?? 0}</strong>개
					</span>
					<Select value={selectedDepartment?.name ?? '전체 진료과'} onChange={onChangeDepartments} optionList={["전체 진료과", ...(departments.map((row) => (row.name)))]} />
				</FlexWrapper>

				<TableDefault columns={columns} dataSource={doctors} pagination={pagination} />
			</StyledLayout>
			{/* 3-2-2.병원 선택 팝업 */}
			{isPopupVisible && (
				<DefaultPopup title="등록" isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible}
											onClickOk={onClickOkHospital}
											onClickCancel={onClickCancelHospital}
											isWithCancel isWithOkay okWithoutClose>
					<form onSubmit={onSubmitHospital}>
						<InputWrapper>
							<Input type="text" placeholder="검색어를 입력하세요." onChange={onChangeHospitalKeyword} />
							<Button isFilled isSmall>
								검색
							</Button>
						</InputWrapper>
					</form>
					<TableSort columns={popupColumns} data={hospitals} scroll={{ y: 275 }} pagination={hospitalPagination} rowSelection={rowSelection} />
				</DefaultPopup>
			)}
			{/* 3-9-1 팝업 */}
			{/* isDeleteVisible && (
				<ConfirmPopup isPopupVisible={isDeleteVisible} setIsPopupVisible={setIsDeleteVisible} isWithCancel>
					삭제 하시겠습니까?
					<br />
					삭제된 정보는 복구가 불가능하며
					<br />
					앱에 바로 반영됩니다.
				</ConfirmPopup>
			)*/}
			{/* 4-1 팝업 */}
			{/* isConfirmVisible && (
				<ConfirmPopup isPopupVisible={isConfirmVisible} setIsPopupVisible={setIsConfirmVisible}>
					필수 항목을 다시 확인해 주세요.
				</ConfirmPopup>
			)*/ }
			{/* 3-10-1 팝업 */}
			{/* {isTogglePopupVisible && (
				<ConfirmPopup isPopupVisible={isTogglePopupVisible} setIsPopupVisible={setIsTogglePopupVisible} isWithCancel>
					미노출시 앱에 표시되지 않습니다.
					<br />
					미노출 처리 하시겠습니까?
				</ConfirmPopup>
			)} */}
			{/* 3-10-2 팝업 */}
			{/* isTogglePopupVisible && (
				<ConfirmPopup isPopupVisible={isTogglePopupVisible} setIsPopupVisible={setIsTogglePopupVisible}>
					홈 화면 컨텐츠에 등록되어 있어 <br />
					미노출이 불가능 합니다.
				</ConfirmPopup>
			)*/ }
			{isConfirmVisible && (
				<ConfirmPopup isWithCancel={isWithCancel} handleOk={popupHandleOk} handleCancel={popupHandleCancel} isPopupVisible={isConfirmVisible} setIsPopupVisible={setIsConfirmVisible}>
					{popupContents}
				</ConfirmPopup>
			)}
		</>
	);
};

export default Doctor;
