import styled from "styled-components";
import { flexbox, fontStyle14, buttonBase } from "../../../styles/mixins";

export const Section = styled.section`
	@media (min-width: 821px) {
		margin: 0 30px 100px;
	}

	@media screen and (min-width: 1025px) {
		margin: 0 70px 200px;
	}
`;

export const ImageWrapper = styled.div`
	display: flex;
	margin: 0 0 70px 0;

	h3 {
		${fontStyle14};
		font-weight: 700;
		margin: 0 50px 0 0;
	}
`;

export const InputWrapper = styled.div`
	display: flex;
	margin: 10px 0 0;
`;

export const ButtonWrapper = styled.div`
	${flexbox("flex-end", "center")};
	margin: 0 0 30px 0;
`;

export const Button = styled.button<{isFilled?: boolean; isMiddle?: boolean;}>`
	${buttonBase};
	height: 36px;
`;

export const PopupButton = styled.button<{isFilled?: boolean; isMiddle?: boolean;}>`
	${buttonBase};
	height: 42px;
	margin: 0 0 0 10px;
`;
