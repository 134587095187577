import { ChangeEvent, ReactNode, FormEvent, useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Tabs } from 'antd';
import ContentHeading from '../../../../components/ContentHeading';
import Label from '../../../../components/Inputs/Label';
import LabelToggle from '../../../../components/Inputs/LabelToggle';
import LabelInput from '../../../../components/Inputs/LabelInput';
import LabelText from '../../../../components/Inputs/LabelText';
import AddImageButton from '../../../../components/AddImageButton';
import LabelTextarea from '../../../../components/Inputs/LabelTextarea';
import LabelRadioTime from '../../../../components/Inputs/LabelRadioTime';
import ConfirmPopup from '../../../../components/Popup/ConfirmPopup';
import { Divider, SubDivider } from '../../../../components/Divider';
import { StyledLayout, ImageWrapper, Section, Button, StyledTabs } from './styles';
import { useNavigate, useParams } from 'react-router';
import { useApi } from '../../../../contexts/ApiContext';
import { HospitalDetail, ModifyHospitalRequest, TreatmentPeriodItem } from '../../../../model';
import { fileToDataUri } from '../../../../helpers/FileHelper';
import HospitalEditTranslate from './HosipitalEditTranslate';

type URLParams = {
	id?: string;
};

const popupText = {
	validation: <>필수 항목을 다시 확인해 주세요.</>,
	save: (
		<>
			입력한 내용을 저장할까요? <br />
			확인을 누르면 앱에 바로 반영됩니다.
		</>
	),
	unchanged: (
		<>
			변경 사항이 없습니다.
		</>
	),
};

const HospitalEdit = () => {
	const { TabPane } = Tabs;
	// Comfirm Popup
	const [popupContents, setPopupContents] = useState<string | ReactNode>();
	const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => {});
	const [popupHandleCancel, setPopupHandleCancel] = useState<VoidFunction | undefined>(() => () => {});
	const [isWithCancel, setIsWithCancel] = useState(false);

	const api = useApi();
	const navigate = useNavigate();

	const { id } = useParams<URLParams>();
	const [region, setRegion] = useState<string>('ko');
	const [isNotModified, setIsNotModified] = useState<boolean>(true);
	const [changedRegion, setChangedRegion] = useState<boolean>(false);

	const [hospital, setHospital] = useState<HospitalDetail>();

	const [treatmentCategories, setTreatmentCategories] = useState<string[]>([]);
	const [departments, setDepartments] = useState<string[]>([]);

	const [display, setDisplay] = useState(false);
	const onChangeDisplay = (checked: boolean) => setDisplay(checked);
	const [name, setName] = useState('');
	const onChangeName = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
	const [tel, setTel] = useState('');
	const [isEmptyTel, setIsEmptyTel] = useState(false);
	const onChangeTel = (e: ChangeEvent<HTMLInputElement>) => setTel(e.target.value);
	const [address, setAddress] = useState('');
	const [isEmptyAddress, setIsEmptyAddress] = useState(false);
	const onChangeAddress = (e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);
	const [intro, setIntro] = useState('');
	const [isEmptyIntro, setIsEmptyIntro] = useState(false);
	const onChangeIntro = (e: ChangeEvent<HTMLInputElement>) => setIntro(e.target.value);
	const [introLong, setIntroLong] = useState('');
	const [isEmptyIntroLong, setIsEmptyIntroLong] = useState(false);
	const onChangeIntroLong = (e: ChangeEvent<HTMLInputElement>) => setIntroLong(e.target.value);
	const [isPopupVisible, setIsPopupVisible] = useState(false);

	const [map, setMap] = useState<string>();
	const [mapFile, setMapFile] = useState<File>();
	const [isEmptyMapFile, setIsEmptyMapFile] = useState(false);
	const onChangeMapFile = (file?: File) => {
		setMap(undefined);
		setMapFile(file);
	};

	const [representative, setRepresentative] = useState<string>();
	const [representativeFile, setRepresentativeFile] = useState<File>();
	const [isEmptyRepresentativeFile, setIsEmptyRepresentativeFile] = useState(false);
	const onChangeRepresentativeFile = (file?: File) => {
		setRepresentative(undefined);
		setRepresentativeFile(file);
	};

	const [weekdays, setWeekdays] = useState<TreatmentPeriodItem[]>([]);
	const onChangeWeekdays = (index: number, item: TreatmentPeriodItem) => {
		setWeekdays((prev) => {
			prev[index] = item;
			return [...prev];
		});
	};
	const [weekend, setWeekend] = useState<TreatmentPeriodItem[]>([]);
	const onChangeWeekend = (index: number, item: TreatmentPeriodItem) => {
		setWeekend((prev) => {
			prev[index] = item;
			return [...prev];
		});
	};
	const [breakTime, setBreakTime] = useState<TreatmentPeriodItem[]>([]);
	const onChangeBreakTime = (index: number, item: TreatmentPeriodItem) => {
		setBreakTime((prev) => {
			prev[index] = item;
			return [...prev];
		});
	};

	const [significant, setSignificant] = useState<string>('');
	const onChangeSignificant = (e: ChangeEvent<HTMLInputElement>) => setSignificant(e.target.value);

	// 병원 데이터 호출 및 아이디 유효성 체크
	const getHospital = useCallback(async () => {
		try {
			const data = (await api.hospital.getHospital(id ?? '', region)).data;
			setHospital(data);
		} catch (e) {
			navigate(-1);
		}
	}, [api.hospital, id, navigate, region]);

	const setHospitalData = useCallback(() => {
		if (hospital) {
			setDisplay(hospital.show);
			setName(hospital.name);
			setDepartments(hospital.departments);
			setTreatmentCategories(hospital.treatmentCategories);
			setTel(hospital.tel);
			setAddress(hospital.address);
			setMap(hospital.mapUrl);

			setIntro(hospital.introductionSummary);
			setIntroLong(hospital.introductionDescription);
			setRepresentative(hospital.representativeImageUrl);

			setWeekdays([
				{ ...hospital.monTreatmentPeriod, dayOfWeek: '월요일' },
				{ ...hospital.tueTreatmentPeriod, dayOfWeek: '화요일' },
				{ ...hospital.wedTreatmentPeriod, dayOfWeek: '수요일' },
				{ ...hospital.thuTreatmentPeriod, dayOfWeek: '목요일' },
				{ ...hospital.friTreatmentPeriod, dayOfWeek: '금요일' },
			]);

			setWeekend([
				{ ...hospital.satTreatmentPeriod, dayOfWeek: '토요일' },
				{ ...hospital.sunDayOffTreatmentPeriod, dayOfWeek: '일요일, 공휴일' },
			]);

			setBreakTime([{ ...hospital.lunchTreatmentPeriod, dayOfWeek: '점심시간' }]);

			setSignificant(hospital.significant);
		}
	}, [hospital]);

	const checkValidation = useCallback((): boolean => {
		let validation = true;
		if (!tel) {
			setIsEmptyTel(true);
			validation = false;
		} else {
			setIsEmptyTel(false);
		}

		if (!address) {
			setIsEmptyAddress(true);
			validation = false;
		} else {
			setIsEmptyAddress(false);
		}

		if (!map && !mapFile) {
			setIsEmptyMapFile(true);
			validation = false;
		} else {
			setIsEmptyMapFile(false);
		}

		if (!intro) {
			setIsEmptyIntro(true);
			validation = false;
		} else {
			setIsEmptyIntro(false);
		}

		if (!introLong) {
			setIsEmptyIntroLong(true);
			validation = false;
		} else {
			setIsEmptyIntroLong(false);
		}

		if (!representative && !representativeFile) {
			setIsEmptyRepresentativeFile(true);
			validation = false;
		} else {
			setIsEmptyRepresentativeFile(false);
		}

		if (validation) {
			setIsEmptyAddress(false);
			setIsEmptyIntro(false);
			setIsEmptyIntroLong(false);
			setIsEmptyMapFile(false);
			setIsEmptyRepresentativeFile(false);
			setIsEmptyTel(false);
		} else {
			setPopupData(popupText.validation, false);
			setIsPopupVisible(true);
		}

		return validation;
	}, [address, intro, introLong, map, mapFile, representative, representativeFile, tel]);

	const onRegionChange = useCallback((key: any) => {
		if(key === "1") {
			setRegion('ko');
		}
		if(key === "2") {
			setRegion('en');
		}
		if(key === "3") {
			setRegion('zh');
		}
		setChangedRegion(true)
	}, []);

	const setPopupData = (contents: JSX.Element, isWithCancel: boolean, handleOk?: VoidFunction, handleCancel?: VoidFunction) => {
		setIsWithCancel(isWithCancel);
		setPopupContents(contents);
		setPopupHandleOk(handleOk);
		setPopupHandleCancel(handleCancel);
	};

	const onSubmit = useCallback(async () => {
		let mapFileUrl: string | undefined;
		let representativeFileUrl: string | undefined;

		if (mapFile) {
			const fileReader = await fileToDataUri(mapFile);
			if (fileReader) {
				const data = (await api.file.getPresignedUrl(mapFile.name)).data;
				await api.file.uploadFile(data.signedUrl, mapFile);

				mapFileUrl = data.uploadTargetUrl;
			}
		}

		if (representativeFile) {
			const fileReader = await fileToDataUri(representativeFile);
			if (fileReader) {
				const data = (await api.file.getPresignedUrl(representativeFile.name)).data;
				await api.file.uploadFile(data.signedUrl, representativeFile);

				representativeFileUrl = data.uploadTargetUrl;
			}
		}

		const modifyData: ModifyHospitalRequest = {
			hospitalId: hospital?.id ?? '',
			show: display,
			hospitalName: name,
			tel: tel,
			address: address,
			mapUrl: mapFileUrl ?? hospital?.mapUrl ?? '',
			introductionSummary: intro,
			introductionDescription: introLong,
			representativeImageUrl: representativeFileUrl ?? hospital?.representativeImageUrl ?? '',
			treatmentMon: weekdays[0]?.treatment,
			monOpensAt: weekdays[0]?.opensAt,
			monClosesAt: weekdays[0]?.closesAt,
			treatmentTue: weekdays[1]?.treatment,
			tueOpensAt: weekdays[1]?.opensAt,
			tueClosesAt: weekdays[1]?.closesAt,
			treatmentWed: weekdays[2]?.treatment,
			wedOpensAt: weekdays[2]?.opensAt,
			wedClosesAt: weekdays[2]?.closesAt,
			treatmentThu: weekdays[3]?.treatment,
			thuOpensAt: weekdays[3]?.opensAt,
			thuClosesAt: weekdays[3]?.closesAt,
			treatmentFri: weekdays[4]?.treatment,
			friOpensAt: weekdays[4]?.opensAt,
			friClosesAt: weekdays[4]?.closesAt,
			treatmentSat: weekend[0]?.treatment,
			satOpensAt: weekend[0]?.opensAt,
			satClosesAt: weekend[0]?.closesAt,
			treatmentSunAndOff: weekend[1]?.treatment,
			sunAndOffOpensAt: weekend[1]?.opensAt,
			sunAndOffClosesAt: weekend[1]?.closesAt,
			treatmentLunch: breakTime[0]?.treatment,
			lunchStartsAt: breakTime[0]?.opensAt,
			lunchEndsAt: breakTime[0]?.closesAt,
			significant: significant,
		};

		const data = (await api.hospital.modifyHospital(modifyData, region)).data;
		if (data.modified) {
			navigate(`/contents/hospital`);
		}
	}, [
		address,
		api.file,
		api.hospital,
		breakTime,
		checkValidation,
		display,
		hospital?.id,
		hospital?.mapUrl,
		hospital?.representativeImageUrl,
		intro,
		introLong,
		mapFile,
		name,
		navigate,
		representativeFile,
		significant,
		tel,
		weekdays,
		weekend,
	]);

	// 저장 클릭
	const onClickSubmit = useCallback(
		(e: FormEvent) => {
			e.preventDefault();

			if(isNotModified) {
				setPopupData(popupText.unchanged, false);
				setIsPopupVisible(true);
			} else if (checkValidation()) {
				setPopupData(popupText.save, true, () => () => onSubmit());
				setIsPopupVisible(true);
			}
		},
		[checkValidation, isNotModified, onSubmit]
	);

	// 병원 아이디 변경시 유효성 체크하고 병원 데이터 호출
	useEffect(() => {
		getHospital();
	}, [getHospital]);

	// 병원 데이터 변경시
	useEffect(() => {
		setHospitalData();
	}, [setHospitalData]);

	useEffect(() => {
		if(changedRegion) {
			return () => {
				setIsNotModified(true)
				setChangedRegion(false)
			}
		} else if(hospital !== undefined) {
			return () => {
				setIsNotModified(false);
			}
		}
	}, [display, name, tel, address, intro, introLong, map, representative, weekdays, weekend, breakTime, significant, changedRegion]);

	return (
		<>
			<Breadcrumb separator=">">
				<Breadcrumb.Item>컨텐츠 관리</Breadcrumb.Item>
				<Breadcrumb.Item href="">병원 관리</Breadcrumb.Item>
				<Breadcrumb.Item href="">병원 추가</Breadcrumb.Item>
			</Breadcrumb>
			<ContentHeading>병원 추가</ContentHeading>

			<StyledLayout>
				<form onSubmit={onClickSubmit}>
					<StyledTabs type="card" defaultActiveKey="1" onTabClick={(key) => onRegionChange(key)}>
						<TabPane tab="한국어" key="1">
							<ContentHeading isRequired>기본 정보</ContentHeading>
							<Section>
								<LabelToggle text="노출" checked={display} onChange={onChangeDisplay} />
								<LabelInput text="병원명" value={name} onChange={onChangeName} />
								{/* data가 있는 경우와 없는 경우, 폰트색이 다릅니다. 'nodata'라는 props를 사용했습니다. */}
								<LabelText text="진료과" nodata={departments.length <= 0}>
									{departments.length <= 0 ? <>등록된 진료과가 없습니다. 시술을 등록해주세요</> : departments.toString()}
								</LabelText>
								{/* <LabelText text="시술 카테고리" nodata>등록된 시술 카테고리가 없습니다. 시술을 등록해주세요.</ LabelText> */}
								<LabelText text="시술 카테고리" nodata={treatmentCategories.length <= 0}>
									{treatmentCategories.length <= 0 ? <>등록된 시술 카테고리가 없습니다. 시술을 등록해주세요.</> : treatmentCategories.toString()}
								</LabelText>
								<LabelInput text="대표 전화" placeholder="(-)없이 숫자만 입력해주세요." value={tel} onChange={onChangeTel} isRequired isRequiredEmpty={isEmptyTel} maxLength={200} />
								<LabelInput text="주소" placeholder="주소를 입력해주세요." value={address} onChange={onChangeAddress} isRequired isRequiredEmpty={isEmptyAddress} maxLength={200} />
								<ImageWrapper>
									<Label text="주소 지도 사진" isRequired />
									<AddImageButton url={map} onChange={onChangeMapFile} isRequiredEmpty={isEmptyMapFile} />
								</ImageWrapper>
							</Section>
							<Divider />
							<Section>
								<ContentHeading isRequired>병원 소개</ContentHeading>
								<LabelInput text="한줄 소개" placeholder="한줄 소개를 입력해주세요." value={intro} onChange={onChangeIntro} isRequired isRequiredEmpty={isEmptyIntro} maxLength={200} />
								<LabelTextarea text="소개 내용" placeholder="소개 내용을 입력해주세요." value={introLong} onChange={onChangeIntroLong} isRequired isRequiredEmpty={isEmptyIntroLong} maxLength={2000} />
								<ImageWrapper>
									<Label text="대표 사진" isRequired />
									<AddImageButton url={representative} onChange={onChangeRepresentativeFile} isRequiredEmpty={isEmptyRepresentativeFile} isDisabled={false} />
								</ImageWrapper>
							</Section>
							<Divider />
							<Section>
								<ContentHeading isRequired>진료 시간</ContentHeading>
								{weekdays.map((item, i) => (
									<LabelRadioTime
										key={i}
										text={item.dayOfWeek}
										value={item.treatment}
										start={item.opensAt}
										end={item.closesAt}
										onChange={(e) => {
											item.treatment = e.target.value;
											onChangeWeekdays(i, item);
										}}
										onChangeTime={(values) => {
											item.opensAt = values?.[0]?.format('HH:mm:ss') ?? item.opensAt;
											item.closesAt = values?.[1]?.format('HH:mm:ss') ?? item.opensAt;
											onChangeWeekdays(i, item);
										}}
										isRequired
									/>
								))}
								<SubDivider />
								{weekend.map((item, i) => (
									<LabelRadioTime
										key={i + 5}
										text={item.dayOfWeek}
										value={item.treatment}
										start={item.opensAt}
										end={item.closesAt}
										onChange={(e) => {
											item.treatment = e.target.value;
											onChangeWeekend(i, item);
										}}
										onChangeTime={(values) => {
											item.opensAt = values?.[0]?.format('HH:mm:ss') ?? item.opensAt;
											item.closesAt = values?.[1]?.format('HH:mm:ss') ?? item.opensAt;
											onChangeWeekend(i, item);
										}}
										isRequired
									/>
								))}
								<SubDivider />

								{breakTime.map((item, i) => (
									<LabelRadioTime
										key={i + 7}
										text={item.dayOfWeek}
										value={item.treatment}
										start={item.opensAt}
										end={item.closesAt}
										onClick={() => {
											item.treatment = !item.treatment;
											onChangeBreakTime(i, item);
										}}
										onChangeTime={(values) => {
											item.opensAt = values?.[0]?.format('HH:mm:ss') ?? item.opensAt;
											item.closesAt = values?.[1]?.format('HH:mm:ss') ?? item.opensAt;
											onChangeBreakTime(i, item);
										}}
										isBreaktime
									/>
								))}
								<SubDivider />

								<LabelInput text="진료특이사항(선택)" value={significant} onChange={onChangeSignificant} placeholder="예) 매주 첫째주 월요일 휴무입니다." maxLength={200} />
								<Divider />
							</Section>
							<Button type="submit" isFilled isBig>
								저장
							</Button>
						</TabPane>
						<TabPane tab="영어" key="2">
							<HospitalEditTranslate
								onChangeAddress={onChangeAddress}
								onChangeIntro={onChangeIntro}
								onChangeIntroLong={onChangeIntroLong}
								onChangeSignificant={onChangeSignificant}
								display={display}
								tel={tel}
								address={address}
								defaultValue={name}
								departments={departments}
								treatmentCategories={treatmentCategories}
								intro={intro}
								introLong={introLong}
								map={map}
								representative={representative}
								weekend={weekend}
								breaktime={breakTime}
								weekdays={weekdays}
								significant={significant}
							/>
						</TabPane>
						<TabPane tab="중국어" key="3">
							<HospitalEditTranslate
								onChangeAddress={onChangeAddress}
								onChangeIntro={onChangeIntro}
								onChangeIntroLong={onChangeIntroLong}
								onChangeSignificant={onChangeSignificant}
								display={display}
								tel={tel}
								address={address}
								defaultValue={name}
								departments={departments}
								treatmentCategories={treatmentCategories}
								intro={intro}
								introLong={introLong}
								map={map}
								representative={representative}
								weekend={weekend}
								breaktime={breakTime}
								weekdays={weekdays}
								significant={significant}
							/>
						</TabPane>
					</StyledTabs>
				</form>
			</StyledLayout>
			{/* 팝업 24-1 */}
			{isPopupVisible && (
				<ConfirmPopup isWithCancel={isWithCancel} handleOk={popupHandleOk} handleCancel={popupHandleCancel} isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible}>
					{popupContents}
				</ConfirmPopup>
			)}
			{/* 팝업 24-2 */}
			{/* {isPopupVisible && (
				<ConfirmPopup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} isWithCancel>
					입력한 내용을 저장할까요? <br />
					확인을 누르면 앱에 바로 반영됩니다.
				</ConfirmPopup>
			)} */}
			{/* 팝업 24-4 */}
			{/* {isPopupVisible && (
				<ConfirmPopup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible}>
					변경 사항이 없습니다.
				</ConfirmPopup>
			)} */}
			{/* 팝업 24-5 */}
			{/* {isPopupVisible && (
				<ConfirmPopup isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} isWithCancel>
					저장되지 않은 변경 내용이 있습니다. <br />
					저장하지 않고 나가려면 확인을 눌러주세요.
				</ConfirmPopup>
			)} */}
		</>
	);
};

export default HospitalEdit;
