import { SearchType, HospitalInventory, HospitalDetail, ModifyHospitalRequest, ModifyResponse, HospitalTreatmentsResponse, ActivateResponse, DisabledResponse } from '../model';
import api from './index';
import Qs from 'qs';

const Hospital = {
  getHospital: (hospitalId: string, region: string) => {
    return api.get<HospitalDetail>(`/admin/hospitals/${hospitalId}/${region}`);
  },
  getAllHospitals: (page: number = 1, size: number = 25, keyword?: string, departmentId?: string, searchType?: SearchType, start?: string, end?: string) => {
    return api.get<HospitalInventory>(
      '/admin/hospitals',
      {
        params: {
          page: page,
          size: size,
          keyword: keyword,
          searchType: searchType,
          start: start,
          end: end,
          departmentId: departmentId,
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    );
  },
  getSearchHospitals: (pageNumber: number = 1, pageSize: number = 25, hospitalName?: string) => {
    return api.get<HospitalInventory>(
      '/admin/hospitals.search',
      {
        params: {
          pageNumber: pageNumber,
          pageSize: pageSize,
          hospitalName: hospitalName
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    );
  },
  getHospitalsTreatments: (hospitalId: string) => {
    return api.get<HospitalTreatmentsResponse>(`/admin/hospitals/${hospitalId}/treatments`);
  },
  activateHospitalDisplay: (hospitalId: string) => {
    return api.put<ActivateResponse>(`/admin/hospitals/${hospitalId}/state.activate`);
  },
  deactivateHospitalDisplay: (hospitalId: string) => {
    return api.put<ActivateResponse>(`/admin/hospitals/${hospitalId}/state.deactivate`);
  },
  modifyHospital: (data: ModifyHospitalRequest, region: string) => {
    return api.put<ModifyResponse>(`/admin/hospitals/${region}`, data);
  },
  checkRecommendedHospital: (hospitalId: string) => {
    return api.get<DisabledResponse>(`/admin/hospitals/${hospitalId}/recommended/state`);
  }
};

export default Hospital;