import styled from "styled-components";
import { buttonBase, flexbox, fontStyle12 } from "../../styles/mixins";

export const Wrapper = styled.div`
	${flexbox("space-between", "center")};
	margin: 0 0 35px 0;
	position: relative;

	small {
		position: absolute;
		top: 32px;
		left: 0;
		${fontStyle12};
		color: ${(props) => props.theme.grey};
		opacity: 0.5;
	}
`;

export const HeadingWrapper = styled.div`
	${flexbox("flex-start", "flex-end")};
`;
export const StyledTableHeading = styled.h3`
	font-size: 21px;
	line-height: 1.5em;
	font-weight: 500;
`;

export const HelperText = styled.span`
	${fontStyle12};
	font-weight: 500;
	color: ${(props) => props.theme.red};
	margin: 0 0 0 5px;
`;

export const Button = styled.button<{isFilled?: boolean; isMiddle?: boolean;}>`
	${buttonBase};
	height: 36px;
	min-width: 110px;
`;
