import styled from "styled-components";
import { flexbox, fontStyle15 } from "../../../styles/mixins";

export const FlexWrapper = styled.div<{nodata?: any}>`
	${flexbox("flex-start", "flex-start")};
	margin: 0 0 40px 0;

	p {
		${fontStyle15};
		width: 100%;
		color: ${(props) => (props.nodata ? props.theme.grey : props.theme.primary)};
		opacity: ${(props) => (props.nodata ? 0.6 : 1)};
	}
`;
