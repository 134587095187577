import styled from "styled-components";
import { tableBase } from "../../../../styles/mixins";

export const Table = styled.table`
	${tableBase};
`;

export const PasswordBtn = styled.button`
	display: block;
	height: 35px;
	padding: 5px 7px;
	border-radius: 4px;
	background-color: ${(props) => props.theme.accentLight};
	color: ${(props) => props.theme.white};
	transition: background-color 300ms ease-in-out;

	:hover {
		background-color: ${(props) => props.theme.accent};
	}

	:active {
		background-color: ${(props) => props.theme.accentDark};
	}
`;
