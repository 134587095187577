import api from './index';

import {
  DataResponse,
  DetailResponse,
  PaymentDetails,
  PaymentHistoryItem,
  PaymentItem,
  PaymentListResponse,
} from '../model';
import Qs from 'qs';

const Payment = {
  getPaymentForMember: (memberId: string, page: number, size: number) => {
    return api.get<PaymentListResponse<PaymentItem>>(
  `/admin/appointments/members/${memberId}/payments`,
{
        params: {
          pageNo: page,
          pageSize: size,
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    );
  },
  downloadPaymentList: () => {
    return api.request({
      url: `/admin/appointments/payments/download`,
      responseType: 'arraybuffer'
    });
  },
  getAllPaymentHistories: (page: number,
                           size: number,
                           searchOption: string,
                           searchKeyword: string,
                           startDate?: string,
                           endDate?: string) => {
    return api.get<PaymentListResponse<PaymentHistoryItem>>(`/admin/appointments/payments`,
      {
        params: {
          searchType: searchOption,
          searchKeyword: searchKeyword,
          startDate: startDate,
          endDate: endDate,
          pageNo: page,
          pageSize: size,
        },
        paramsSerializer: (params) => Qs.stringify(params),
      }
    );
  },
  getPaymentDetails: (paymentId: string) => {
    return api.get<DetailResponse<PaymentDetails>>(`/admin/appointments/payments/${paymentId}`)
  }
};

export default Payment