import React, { useMemo } from "react";
import { LoginRequest, LoginUser } from "../model";

export interface AuthContextType {
  accessToken: string;
  loginFailed: boolean;
  user?: LoginUser;
  setUser: (user: LoginUser) => void;
  signin: (user: LoginRequest, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
  resetLoginFailed: (flag: boolean) => void;
}

export let AuthContext = React.createContext<AuthContextType>(null!);

export const useAuth = () => {
  return React.useContext(useMemo(() => AuthContext, []));
}