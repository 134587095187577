import {FormEvent, Key, ReactNode, useCallback, useEffect, useState} from "react";
import InnerTdButton from "../../../components/InnerTdButton";
import TableHeading from "../../../components/TableHeading";
import TableDefault from "../../../components/Tables/TableDefault";
import Label from "../../../components/Inputs/Label";
import AddImageButton from "../../../components/AddImageButton";
import DefaultPopup from "../../../components/Popup/DefaultPopup";
import TableSort from "../../../components/Tables/TableSort";
import SelectInput from "../../../components/Inputs/SelectInput";
import ConfirmPopup from "../../../components/Popup/ConfirmPopup";
import { Section, ImageWrapper, InputWrapper, ButtonWrapper, Button, PopupButton } from "./styles";

import TabInterface from "../TabInterface"
import { TablePaginationConfig } from "antd";
import { DoctorInventory, DoctorItem, DoctorSearchTermsOption, RecommendedDoctorItem } from "../../../model";
import { useApi } from "../../../contexts/ApiContext";
import { TableRowSelection } from "antd/lib/table/interface";
import moment from "moment";
import { fileToDataUri } from "../../../helpers/FileHelper";

const optionList = ["전체", "병원명", "의사"];
const popupText = {
	save:
		<>
			변경한 내용을 저장할까요? <br />
			확인을 누르면 앱에 바로 반영됩니다.
		</>
}
const RecDoctor = (props: TabInterface) => {
	const api = useApi();

	const [popupContents, setPopupContents] = useState<string | ReactNode>();
	const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => { });
	const [popupHandleCancel, setPopupHandleCancel] = useState<VoidFunction | undefined>(() => () => { });
	const [isWithCancel, setIsWithCancel] = useState(false);

	// 추천 의사
	const [recommendedDoctor, setRecommendedDoctor] = useState<RecommendedDoctorItem>();

	// 의사 목록
	const [doctorData, setDoctorData] = useState<DoctorInventory>();
	const [doctors, setDoctors] = useState<DoctorItem[]>([]);
	const [page, setPage] = useState<number>(0);
	const [pageSize,] = useState<number>(25);

	// 의사 검색
	const [searchType, setSearchType] = useState<DoctorSearchTermsOption>();
	const [searchKeyword, setSearchKeyword] = useState<string>();
	const [keyword, setKeyword] = useState<string>('');

	// 선택된 의사
	const [selectedDoctors, setSelectedDoctors] = useState<Key[]>([]);

	// 의사 사진
	const [file, setFile] = useState<File>();
	const [doctorImageUrl, setDoctorImageUrl] = useState<string>();
	const [isEmptyDoctorFile, setIsEmptyDoctorFile] = useState(false);

	// 추천 의사 칼럼
	const columns = [
		{ title: "병원명", dataIndex: "hospitalName", key: "hospitalName" },
		{ title: "의사", dataIndex: "doctorName", key: "doctorName" },
		{ title: "", dataIndex: "edit", key: "edit" },
		{
			title: "",
			dataIndex: "edit",
			key: "edit",
			render: () => <InnerTdButton onClickDelete={onClickDelete} />,
		},
	];

	// 팝업 의사 칼럼
	const popupColumns = [
		{
			title: "등록일",
			dataIndex: "registerDate",
			key: "registerDate",
			sorter: (a: DoctorItem, b: DoctorItem) => {
				const aDate = moment(a.registerDate);
				const bDate = moment(b.registerDate);

				return bDate.diff(aDate);
			},
		},
		{
			title: "수정일",
			dataIndex: "updateDate",
			key: "updateDate",
			sorter: (a: DoctorItem, b: DoctorItem) => {
				const aDate = moment(a.updateDate);
				const bDate = moment(b.updateDate);

				return bDate.diff(aDate);
			},
		},
		{ title: "진료과", dataIndex: "departmentName", key: "departmentName" },
		{ title: "병원명", dataIndex: "hospitalName", key: "hospitalName" },
		{ title: "의사", dataIndex: "doctorName", key: "doctorName" },
	];

	const setPopupData = (contents: JSX.Element, isWithCancel: boolean, handleOk?: VoidFunction, handleCancel?: VoidFunction) => {
		setIsWithCancel(isWithCancel);
		setPopupContents(contents);
		setPopupHandleOk(handleOk);
		setPopupHandleCancel(handleCancel);
	};

	//시술 페이지네이션
	const popupPagination: TablePaginationConfig = { 
		position: ["bottomCenter"], 
		defaultPageSize: pageSize,
		showSizeChanger: false,
		total: doctorData?.totalElements, 
		current: page + 1,
		onChange: (page: number, pageSize: number) => {
			setPage(page - 1);
		},
	};
	
	//의사 체크 박스
	const rowSelection: TableRowSelection<any> = {
		onChange: (keys) => {
			setSelectedDoctors(keys);
		},
		getCheckboxProps: (record) => {
			if (selectedDoctors.length > 0 && record.key !== selectedDoctors[0]) {
				return {
					disabled: true,
				}
			} else {
				return {
					disabled: false,
				}
			}
		},
		
		hideSelectAll: true,
	};
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [isSubmitCountPopupVisible, setIsSubmitCountPopupVisible] = useState(false);

	const showPopup = useCallback(() => {
		if (file) {
			setIsPopupVisible(true);
		} else {
			setIsConfirmVisible(true);
		}
	}, [file]);

	// 팝업에서 필수 입력 값을 넣지 않고 '저장'버튼을 누르는 경우 보여주는 팝업
	const [isConfirmVisible, setIsConfirmVisible] = useState(false);

	const [isDeleteVisible, setIsDeleteVisible] = useState(false);
	const onClickDelete = () => setIsDeleteVisible(true);

	//삭제 요청 시
	const handleOk = useCallback(async () => {
		const result = (await api.home.deleteRecommendedDoctor(props.department?.id)).data;

		if (result.removed) {
			setRecommendedDoctor(undefined);
			setDoctorImageUrl(undefined);
		}

		setIsDeleteVisible(false);
	}, [api.home, props.department?.id]);
	const handleCancel = () => setIsDeleteVisible(false);

	// 시술 이미지 변경
	const onChangeFile = (file: File | undefined) => {
		setDoctorImageUrl(undefined);
		setFile(file);
	};

	// 의사 컨텐츠 검색
	const onSearch = useCallback((e: FormEvent) => {
		e.preventDefault();
		setSearchKeyword(keyword);
	}, [keyword]);

	// 의사 컨텐츠 검색 카테고리 변경
	const onChangeSelect = (value: string) => {
		switch (value) {
			case '전체': setSearchType(undefined); break;
			case '병원명': setSearchType(DoctorSearchTermsOption.HOSPITAL); break;
			case '의사': setSearchType(DoctorSearchTermsOption.DOCTOR); break;
		}
	};

	//추천 의사
	const getRecommendedDoctor = useCallback(async () => {
		const data = (await api.home.getRecommendedDoctor(props.department?.id)).data;
		setRecommendedDoctor({key: data?.item?.doctorId ?? "rec-doctor", ...data.item});
		setDoctorImageUrl(data.item.imageUrl)
	}, [api.home, props.department?.id]);

	// 의사 목록
	const getDoctorData = useCallback(async () => {
		const data = (await api.doctor.getActiveDoctors(page, pageSize, searchType, searchKeyword)).data;
		setDoctorData(data);
	}, [api.doctor, page, pageSize, searchKeyword, searchType]);
	

	// 의사 이미지 파일 저장
	const onSaveImage = useCallback(async () => {
		if (file) {
			const fileReader = await fileToDataUri(file);
			if (fileReader) {
				const data = (await api.file.getPresignedUrl(file.name)).data;
				await api.file.uploadFile(data.signedUrl, file);

				return data;
			}
		}

		throw new Error("Empty File");
	}, [api.file, file]);

	// 의사 팝업 저장
	const onClickSave = useCallback(async () => {
		const savedFile = await onSaveImage();
		const doctorKey = selectedDoctors[0].toString();
		const data = (await api.home.addRecommendedDoctor({doctorId: doctorKey, imageUrl: savedFile.uploadTargetUrl}, props.department?.id)).data;

		setIsPopupVisible(false);
		setSelectedDoctors([]);

		if (data.assigned) {
			getRecommendedDoctor();
		}
	}, [api.home, file, getRecommendedDoctor, onSaveImage, props.department?.id, selectedDoctors]);

	const onConfirmSave = useCallback(async () => {
		if (selectedDoctors.length <= 0 || !file) {
			setIsConfirmVisible(true);
		} else {
			setPopupData(popupText.save, true, () => () => onClickSave());
			setIsSubmitCountPopupVisible(true);
		}
	}, [onClickSave]);

	// 의사 팝업 취소
	const onClickCancel = () => {
		setIsPopupVisible(false);
	};

	// 추천 의사 호출
	useEffect(() => {
		getRecommendedDoctor();
	}, [getRecommendedDoctor]);

	// 의사 목록 호출
	useEffect(() => {
		getDoctorData();
	}, [getDoctorData]);

	// api 호출로 불러온 의사 목록 가공
	useEffect(() => {
		const data = doctorData?.doctors.map((row) => {
			return { key: row.doctorId, ...row };
		});

		if (data) {
			setDoctors(data);
		}
	}, [doctorData]);

	return (
		<>
			<Section>
				<TableHeading>
					추천 의사
					<small>사진 추가 후 컨텐츠 등록이 가능합니다.</small>
				</TableHeading>
				<ImageWrapper>
					<Label text="사진 등록" />					
					<AddImageButton url={doctorImageUrl} onChange={onChangeFile} isRequiredEmpty={isEmptyDoctorFile} />
				</ImageWrapper>
				<ButtonWrapper>
					{/* 등록된 컨텐츠가 있는 경우, 버튼에 'disabled' props를 추가합니다. */}
					<Button isFilled isMiddle disabled={recommendedDoctor !== undefined} onClick={showPopup}>
						컨텐츠 등록
					</Button>
				</ButtonWrapper>
				<TableDefault columns={columns} dataSource={recommendedDoctor ? [recommendedDoctor] : []} />
			</Section>
			{/* 2-1.홈화면 관리 > 2-1-4 의사 컨텐츠 추가 팝업 */}
			{isPopupVisible && (
				<DefaultPopup title="컨텐츠 등록"
											isPopupVisible={isPopupVisible}
											setIsPopupVisible={setIsPopupVisible}
											onClickOk={onConfirmSave}
											onClickCancel={onClickCancel}
											okWithoutClose isWithCancel isWithSave>
					<form onSubmit={onSearch}>
						<InputWrapper>
							<SelectInput optionList={optionList} onChange={(e) => setKeyword(e.target.value)} onChangeSelect={onChangeSelect} />
							<PopupButton isFilled isMiddle>
								검색
							</PopupButton>
						</InputWrapper>
					</form>
					<TableSort columns={popupColumns} data={doctors} scroll={{ y: 270 }} pagination={popupPagination} rowSelection={rowSelection} />
				</DefaultPopup>
			)}
			{/* 팝업  4-1 */}
			{isConfirmVisible && (
				<ConfirmPopup isPopupVisible={isConfirmVisible} setIsPopupVisible={setIsConfirmVisible}>
					필수 항목을 다시 확인해 주세요.
				</ConfirmPopup>
			)}
			{/* 팝업 4-9 */}
			{isDeleteVisible && (
				<ConfirmPopup isPopupVisible={isDeleteVisible} setIsPopupVisible={setIsDeleteVisible} handleOk={handleOk} handleCancel={handleCancel} isWithCancel>
					삭제하시겠습니까?
				</ConfirmPopup>
			)}
			{/* 팝업 5-1 */}
			{isSubmitCountPopupVisible && (
				<ConfirmPopup isWithCancel={isWithCancel} handleOk={popupHandleOk} handleCancel={popupHandleCancel} isPopupVisible={isSubmitCountPopupVisible} setIsPopupVisible={setIsSubmitCountPopupVisible} >
					{popupContents}
				</ConfirmPopup>
			)}
		</>
	);
};

export default RecDoctor;
