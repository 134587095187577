import { useState } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Wrapper, LogoWrapper, Form, InputWrapper, Input, HelperWrapper, Helper, Button } from "./styles";
import { ExclamationCircleOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";

import { useAuth } from '../../contexts/AuthContext'

// 로그인 페이지 (기획서 1-1. 로그인)
const LogIn = () => {
	let auth = useAuth();

	let location = useLocation();
	let navigate = useNavigate();

	const [userId, setuserId] = useState("");
	const [password, setPassword] = useState("");
	const [hasUserId, setHasUserId] = useState(true);
	const [hasPassword, setHasPassword] = useState(true);
	const onChangeUserId = (e) => setuserId(e.target.value);
	const onChangePassword = (e) => setPassword(e.target.value);

	const onSubmit = (e) => {
		e.preventDefault();

		auth.resetLoginFailed(false);
		setHasUserId(true);
		setHasPassword(true);

		//사용자가 아이디를 입력하지 않고 submit한 경우
		// 사용자가 아이디와 비밀번호 모두를 입력하지 않고 submit한 경우
		if (!userId) {
			setHasUserId(false);
			return;
		}

		//사용자가 비밀번호를 입력하지 않고 submit한 경우
		if (!password) {
			setHasPassword(false);
			return;
		}

		//홈 페이지로
		auth.signin({ loginName: userId, loginPassword: password }, () => {
			navigate("/")
		})
	};

	if (!auth.user) {

		return (
			<Wrapper>
				<LogoWrapper />

				<Form onSubmit={onSubmit}>
					<InputWrapper>
						<Input type="text" value={userId} onChange={onChangeUserId} placeholder="아이디를 입력해주세요." />
						<UserOutlined style={{ fontSize: "24px" }} />
					</InputWrapper>
					<InputWrapper>
						<Input type="password" value={password} onChange={onChangePassword} placeholder="비밀번호를 입력해주세요." />
						<LockOutlined style={{ fontSize: "24px" }} />
					</InputWrapper>
					<HelperWrapper>
						{!hasUserId && (
							<Helper>
								<ExclamationCircleOutlined />
								<span>아이디를 입력해 주세요.</span>
							</Helper>
						)}

						{!hasPassword && (
							<Helper>
								<ExclamationCircleOutlined />
								<span>비밀번호를 입력해 주세요.</span>
							</Helper>
						)}
						{auth.loginFailed && (
							<Helper>
								<ExclamationCircleOutlined />
								<span>
									아이디 또는 비밀번호를 잘못 입력했습니다. <br /> 입력하신 내용을 다시 확인해주세요.
								</span>
							</Helper>
						)}
					</HelperWrapper>
					<Button type="submit" isFilled isBig>
						로그인
					</Button>
				</Form>
			</Wrapper>
		);
	} else {
		return <Navigate to="/" state={{ from: location }} replace />;
	}
};

export default LogIn;
