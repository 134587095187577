import React, { MouseEventHandler, ReactNode } from 'react';
import { Wrapper, HeadingWrapper, Heading, HelperText, Button } from './styles';

interface HeadingProps {
	isRequired?: boolean;
	hasButton?: boolean;
	onClick?: MouseEventHandler;
	children?: ReactNode;
	isSub?: boolean;
}

const ContentHeading = ({ isRequired, hasButton, onClick, children, isSub }: HeadingProps) => {
	return (
		<Wrapper>
			<HeadingWrapper isSub={isSub}>
				<Heading isSub={isSub}>{children}</Heading>
				{isRequired && <HelperText>*필수입력 항목</HelperText>}
			</HeadingWrapper>
			{hasButton && (
				<Button isFilled isMiddle onClick={onClick}>
					등록
				</Button>
			)}
		</Wrapper>
	);
};

export default React.memo(ContentHeading);
