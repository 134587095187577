import { ExclamationCircleOutlined } from "@ant-design/icons";

import { StyledModal, Content, TextWrapper, Text, ButtonWrapper, Button } from "./styles";
import { useAuth } from "../../../contexts/AuthContext";
import React, { ReactNode } from "react";

interface ConfirmPopupProps {
	isPopupVisible?: boolean;
	setIsPopupVisible: (visible: boolean) => void;
	isLogout?: boolean;
	isWithCancel?: boolean;
	children: ReactNode;
	handleOk?: () => void;
	handleCancel?: () => void;
}

const ConfirmPopup = ({ isPopupVisible, setIsPopupVisible, isLogout, isWithCancel, children, handleOk, handleCancel }: ConfirmPopupProps) => {
	const auth = useAuth();

	const onCancel = () => {
		if (handleCancel) {
			handleCancel();
		}
		setIsPopupVisible(false);
	};

	const onOk = () => {
		if (handleOk) {
			handleOk();
		}else if (isLogout) {
			auth.signout(() => {});
		}
		
		setIsPopupVisible(false);
	};

	return (
		<>
			<StyledModal centered visible={isPopupVisible} onOk={onOk} onCancel={onCancel} footer={null}>
				<Content>
					<TextWrapper>
						<ExclamationCircleOutlined style={{ fontSize: "50px", color: "#D0A56C" }} />
						<Text>{children}</Text>
					</TextWrapper>

					<ButtonWrapper>
						{isWithCancel && (
							<Button isBig onClick={onCancel}>
								취소
							</Button>
						)}
						<Button isFilled isBig onClick={onOk}>
							확인
						</Button>
					</ButtonWrapper>
				</Content>
			</StyledModal>
		</>
	);
};

export default React.memo(ConfirmPopup);
