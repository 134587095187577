import React, { MouseEventHandler, ReactNode } from "react";
import { Wrapper, HeadingWrapper, StyledPageHeading, HelperText, Button } from "./styles";

interface PageHeadingProps {
	hasButton?: string;
	onClick?: MouseEventHandler;
	isRequired?: boolean;
	children: ReactNode;
}

const PageHeading = ({ hasButton, onClick, isRequired, children }: PageHeadingProps) => {
	return (
		<Wrapper>
			<HeadingWrapper>
				<StyledPageHeading>{children}</StyledPageHeading>
				{isRequired && <HelperText>*필수입력 항목</HelperText>}
			</HeadingWrapper>
			{hasButton && (
				<Button isFilled isMiddle onClick={onClick}>
					{hasButton}
				</Button>
			)}
		</Wrapper>
	);
};

export default React.memo(PageHeading);
