import { AssignResponse, AssignSearchContentsRequest, SearchContentsReadResponse } from '../model';
import api from './index';

const Search = {
  getSearchContents: (region: string) => {
    return api.get<SearchContentsReadResponse>(`/search/contents/${region}`);
  },
  modifySearchContents: (data: AssignSearchContentsRequest, region:string) => {
    return api.put<AssignResponse>(`/search/contents/${region}`, data);
  },
};

export default Search;