import React from 'react';
import Label from "../Label";
import ToggleButton from "../../ToggleButton";
import { FlexWrapper } from "./styles";
import { SwitchProps } from "antd";

interface LabelToggleProps extends SwitchProps {
	text: string;
	isRequired?: boolean;
}

const LabelToggle = (props: LabelToggleProps) => {
	return (
		<FlexWrapper>
			<Label text={props.text} isRequired={props.isRequired} />
			<ToggleButton {...props} />
		</FlexWrapper>
	);
};

export default React.memo(LabelToggle);
