import { PartnersHospitalRequest, AppendPartnersHospitalResponse, AssignNewlyOpenedHospitalRequest, AssignNewlyOpenedHospitalResponse, AssignPremiumCertificationRequest, AssignRecommendedDoctorRequest, AssignRecommendedDoctorResponse, AssignRecommendedHospitalRequest, AssignRecommendedHospitalResponse, AssignResponse, BannerItem, CounterReponse, BannerRequest, CreateBannerResponse, DeleteResponse, HomeDepartmentItem, ItemResponse, ItemsResponse, MoveResponse, NewlyOpenedHospitalItem, PartnersHospitalListItem, PremiumCertificationCounter, RecommendedDoctorItem, RecommendedHospitalItem, RemoveResponse, ShiftDirection, ModifyResponse } from '../model';
import api from './index';

const Home = {
  getHomeTabs: () => {
    return api.get<ItemsResponse<HomeDepartmentItem>>('/home/department-menu-items');
  },
  getHomeBanners: (page: number = 0, size: number = 10) => {
    return api.get<ItemsResponse<BannerItem>>(`/home/banners?page=${page}&size=${size}`);
  },
  swapBannerOrder: (bannerId: string, shiftDirection: ShiftDirection) => {
    return api.put<MoveResponse>(`/home/banners/${bannerId}/display-order`, {direction: shiftDirection});
  },
  addHomeBanner: (data: BannerRequest) => {
    return api.post<CreateBannerResponse>('/home/banners', data);
  },
  modifyHomeBanner: (bannerId: string, data: BannerRequest) => {
    return api.put<ModifyResponse>(`/home/banners/${bannerId}`, data);
  },
  deleteHomeBanner: (bannerId: string) => {
    return api.delete<DeleteResponse>(`/home/banners/${bannerId}`);
  },
  getRecommendedHospital: (departmentId?: string) => {
    if (departmentId) {
      return api.get<ItemResponse<RecommendedHospitalItem>>(`/home/departments/${departmentId}/recommended-hospital`);
    } else {
      return api.get<ItemResponse<RecommendedHospitalItem>>('/home/recommended-hospital');
    }
  },
  addRecommendedHospital: (data: AssignRecommendedHospitalRequest,departmentId?: string) => {
    if (departmentId) {
      return api.post<AssignRecommendedHospitalResponse>(`/home/departments/${departmentId}/recommended-hospital`, data);
    } else {
      return api.post<AssignRecommendedHospitalResponse>('/home/recommended-hospital', data);
    }
  },
  deleteRecommendedHospital: (departmentId?: string) => {
    if (departmentId) {
      return api.delete<RemoveResponse>(`/home/departments/${departmentId}/recommended-hospital`);
    } else {
      return api.delete<RemoveResponse>('/home/recommended-hospital');
    }
  },
  getRecommendedDoctor: (departmentId?: string) => {
    if (departmentId) {
      return api.get<ItemResponse<RecommendedDoctorItem>>(`/home/departments/${departmentId}/recommended-doctor`);
    } else {
      return api.get<ItemResponse<RecommendedDoctorItem>>('/home/recommended-doctor');
    }
  },
  addRecommendedDoctor: (data: AssignRecommendedDoctorRequest, departmentId?: string) => {
    if (departmentId) {
      return api.post<AssignRecommendedDoctorResponse>(`/home/departments/${departmentId}/recommended-doctor`, data);
    } else {
      return api.post<AssignRecommendedDoctorResponse>('/home/recommended-doctor', data);
    }
  },
  deleteRecommendedDoctor: (departmentId?: string) => {
    if (departmentId) {
      return api.delete<RemoveResponse>(`/home/departments/${departmentId}/recommended-doctor`);
    } else {
      return api.delete<RemoveResponse>('/home/recommended-doctor');
    }
  },
  getNewlyOpenedHospital: (departmentId?: string) => {
    if (departmentId) {
      return api.get<ItemResponse<NewlyOpenedHospitalItem>>(`/home/departments/${departmentId}/newly-opened-hospital`);
    } else {
      return api.get<ItemResponse<NewlyOpenedHospitalItem>>('/home/newly-opened-hospital');
    }
  },
  addNewlyOpenedHospital: (data: AssignNewlyOpenedHospitalRequest, departmentId?: string) => {
    if (departmentId) {
      return api.post<AssignNewlyOpenedHospitalResponse>(`/home/departments/${departmentId}/newly-opened-hospital`, data);
    } else {
      return api.post<AssignNewlyOpenedHospitalResponse>('/home/newly-opened-hospital', data);
    }
  },
  deleteNewlyOpenedHospital: (departmentId?: string) => {
    if (departmentId) {
      return api.delete<RemoveResponse>(`/home/departments/${departmentId}/newly-opened-hospital`);
    } else {
      return api.delete<RemoveResponse>('/home/newly-opened-hospital');
    }
  },
  getPremiumCertificationCount: () => {
    return api.get<CounterReponse<PremiumCertificationCounter>>('/home/premium-certification.count');
  },
  modifyPremiumCertificationCount: (data: AssignPremiumCertificationRequest) => {
    return api.put<AssignResponse>('/home/premium-certification.count', data);
  },
  getPartnesrHospitals: () => {
    return api.get<ItemsResponse<PartnersHospitalListItem>>('/home/partners-hospitals');
  },
  addPartnersHospital: (data: PartnersHospitalRequest) => {
    return api.post<AppendPartnersHospitalResponse>('/home/partners-hospitals', data);
  },
  modifyPartnersHospital: (hospitalId: string, data: PartnersHospitalRequest) => {
    return api.put<ModifyResponse>(`/home/partners-hospitals/${hospitalId}`, data)
  },
  swapPartnersHospitalOrder: (hospitalId: string, shiftDirection: ShiftDirection) => {
    return api.put<ModifyResponse>(`/home/partners-hospitals/${hospitalId}/display-order`, {direction: shiftDirection});
  },
  deletePartnersHospital: (id: string) => {
    return api.delete<RemoveResponse>(`/home/partners-hospitals/${id}`);
  },
};

export default Home;