import styled from 'styled-components';
import { Layout } from 'antd';

export const StyledLayout = styled(Layout)`
  margin: 64px 0 0 260px; // for fixed gnb and lnb
  padding: 0 30px 24px;

  @media screen and (max-width: 1535px) {
    margin: 64px 0 0 150px;
  }
`;

export const StyledContent = styled(Layout.Content)`
  padding: 24px;
  margin: 0;
  min-height: 280px;

  @media (min-width: 821px) {
    margin: 0 30px;
  }

  @media screen and (min-width: 1536px) {
    margin: 0 80px;
  }
`;
