import { useCallback } from 'react';
import EditButton from '../../../components/InnerTdButton/EditButton';
import UpDownButton from '../../../components/UpDownButton';
import { CategoryItem, DepartmentItem, ShiftDirection, TreatmentCategoryItem } from '../../../model';

interface Props<T> {
	list: T[];
	parentEnable?: boolean;
	selectedId?: string;
	onClick?: (item: T) => void;
	onClickEdit?: (item: DepartmentItem | TreatmentCategoryItem) => void;
	swapDepartmentOrder?: (departmentId: string, direction: ShiftDirection) => void;
	swapCategoryOrder?: (departmentId: string, categoryId: string, direction: ShiftDirection) => void;
}

const Table = <TableItem extends DepartmentItem | TreatmentCategoryItem>(props: Props<TableItem>) => {
	const instanceOfCategory = useCallback((object: any): object is TreatmentCategoryItem => {
		if (typeof object === 'object') {
			return 'categoryId' in object;
		} else {
			return false;
		}
	}, []);

	const multilingualDepartmentName = (item: DepartmentItem, region: string) => {
		if (region === 'en' && item.nameEn !== '' && item.nameEn !== undefined) {
			return item.nameEn;
		}
		if (region === 'zh' && item.nameZh !== '' && item.nameZh !== undefined) {
			return item.nameZh;
		}
		return item.name;
	}

	const multilingualCategoryName = (item: TreatmentCategoryItem, region: string) => {
		if (region === 'en' && item.categoryNameEn !== '' && item.categoryNameEn !== undefined) {
			return item.categoryNameEn;
		}
		if (region === 'zh' && item.categoryNameZh !== '' && item.categoryNameZh !== undefined) {
			return item.categoryNameZh;
		}
		return item.categoryName;
	}

	return (
		<table>
			<tbody>
				{props.list.map((item, i) => {
					const classNames: string[] = [];

					if (!instanceOfCategory(item)) {
						// 진료과일 때
						if (item.id === props.selectedId) {
							classNames.push('selected');
						}
					}

					return (
						// 2-7. 진료과 미사용의 경우 -> 기존 td가 아닌 아래의 tr에 'unused' 클래스명 추가 부탁드립니다. (22.07.29)
						<tr key={i} className={classNames.join(' ').concat(!(item.enabled ?? true) || props.parentEnable === false ? ' unused' : '')}
								onClick={() => props.onClick?.(item)}>
							<td>
								<UpDownButton
									onClick={() => {
										if (i > 0) {
											if (instanceOfCategory(item) && props.swapCategoryOrder) {
												props.swapCategoryOrder(item.departmentId, item.categoryId, ShiftDirection.FORWARD);
											} else if (!instanceOfCategory(item) && props.swapDepartmentOrder) {
												props.swapDepartmentOrder(item.id, ShiftDirection.FORWARD);
											}
										}
									}}
								/>
								<UpDownButton
									onClick={() => {
										if (i < props.list.length - 1) {
											if (instanceOfCategory(item) && props.swapCategoryOrder) {
												props.swapCategoryOrder(item.departmentId, item.categoryId, ShiftDirection.BACKWARD);
											} else if (!instanceOfCategory(item) && props.swapDepartmentOrder) {
												props.swapDepartmentOrder(item.id, ShiftDirection.BACKWARD);
											}
										}
									}}
									isDown
								/>
							</td>
							<td style={{ width: '50%' }}>
								<p style={{ textAlign: 'left' }}>
									{instanceOfCategory(item) ? item.categoryName : item.name}</p>
								<p style={{ textAlign: 'left' }}>
									{instanceOfCategory(item) ? multilingualCategoryName(item, 'en') : multilingualDepartmentName(item, 'en')}</p>
								<p style={{ textAlign: 'left' }}>
									{instanceOfCategory(item) ? multilingualCategoryName(item, 'zh') : multilingualDepartmentName(item, 'zh')}</p>
							</td>

							<td>
								<EditButton
									onClick={(e) => {
										e.preventDefault();
										props.onClickEdit?.(item);
									}}
								/>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default Table;
