import { ChangeEvent, FormEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Tabs } from 'antd';
import ContentHeading from '../../../../components/ContentHeading';
import LabelToggle from '../../../../components/Inputs/LabelToggle';
import LabelText from '../../../../components/Inputs/LabelText';
import Label from '../../../../components/Inputs/Label';
import LabelInput from '../../../../components/Inputs/LabelInput';
import LabelSelect from '../../../../components/Inputs/LabelSelect';
import AddImageButton from '../../../../components/AddImageButton';
import TableHeading from '../../../../components/TableHeading';
import TableDefault from '../../../../components/Tables/TableDefault';
import TableSort from '../../../../components/Tables/TableSort';
import ConfirmPopup from '../../../../components/Popup/ConfirmPopup';
import DefaultPopup from '../../../../components/Popup/DefaultPopup';
import UpDownButton from '../../../../components/UpDownButton';
import InnerTdButton from '../../../../components/InnerTdButton';
import { Divider } from '../../../../components/Divider';
import { StyledLayout, Section, ImageWrapper, ButtonWrapper, PopupSubHeading, Button } from './styles';
import DefaultImage from '../../../../components/DefaultImage';
import { useApi } from '../../../../contexts/ApiContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DetailItemImage,
  DetailItemTreatment,
  DoctorCreateImage,
  DoctorCreateTreatment,
  DoctorDetailItem, HospitalDetail,
  HospitalTreatmentsResponseItem,
  RequestDoctorCreate,
  RequestDoctorModify,
  ShiftDirection,
} from '../../../../model';
import Image from '../../../../components/Image';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import { fileToDataUri } from '../../../../helpers/FileHelper';
import { StyledTabs } from '../../../Home/Tabs/styles';
import DoctorEditTranslate from './DoctorEditTranslate';

const { TabPane } = Tabs;

type URLParams = {
  id?: string;
};

const optionList = ['선택', '대표원장', '원장'];
const popupText = {
  delete: <>삭제 하시겠습니까?</>,
  unsave: (
    <>
      저장되지 않은 변경 내용이 있습니다. <br />
      저장하지 않고 나가려면 확인을 눌러주세요.
    </>
  ),
  validation: <>필수 항목을 다시 확인해 주세요.</>,
  save: (
    <>
      입력한 내용을 저장할까요? <br />
      확인을 누르면 앱에 바로 반영됩니다.
    </>
  ),
  unchanged: <>변경 사항이 없습니다.</>,
};

const DoctorEdit = () => {
  const api = useApi();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { id } = useParams<URLParams>();
  const [region, setRegion] = useState<string>('ko');
  const [isNotModified, setIsNotModified] = useState<boolean>(true);
  const [changedRegion, setChangedRegion] = useState<boolean>(false);

  // 의사 정보 (수정)
  const [doctor, setDoctor] = useState<DoctorDetailItem>();

  // 병원 데이터 및 시술 정보
  const [hospital, setHospital] = useState<HospitalDetail>();
  const [hospitalsTreats, setHospitalTreats] = useState<HospitalTreatmentsResponseItem[]>([]);

  // 의료진 기본 정보 입력 항목
  const [display, setDisplay] = useState(false);
  const onChangeDisplay = (checked: boolean) => setDisplay(checked);
  const [name, setName] = useState('');
  const [hospitalId, setHospitalId] = useState('');
  const [hospitalName, setHospitalName] = useState('');
  const [isEmptyName, setIsEmptyName] = useState(false);
  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const [position, setPosition] = useState('');
  const [isEmptyPosition, setIsEmptyPosition] = useState(false);
  const onChangePosition = (value: any) => {
    setPosition(value !== '선택' ? value : '');
  };
  const [intro, setIntro] = useState('');
  const [isEmptyIntro, setIsEmptyIntro] = useState(false);
  const onChangeIntro = (e: ChangeEvent<HTMLInputElement>) => setIntro(e.target.value);

  const [representative, setRepresentative] = useState<string>();
  const [representativeFile, setRepresentativeFile] = useState<File>();
  const [isEmptyRepresentativeFile, setIsEmptyRepresentativeFile] = useState(false);
  const onChangeRepresentativeFile = (file?: File) => {
    setRepresentative(undefined);
    setRepresentativeFile(file);
  };

  // 소개 사진 목록
  const [images, setImages] = useState<DetailItemImage[]>([]);

  // 소개 사진 추가 팝업
  const [introduceFile, setIntroduceFile] = useState<File>();
  const [isEmptyIntroduceFile, setIsEmptyIntroduceFile] = useState(false);
  const onChangeIntroduceFile = (file?: File) => setIntroduceFile(file);

  // 소개 사진 수정
  const [editIndex, setEditIndex] = useState<number>();

  // 진료 시술
  const [selectedTreats, setSelectedTreats] = useState<HospitalTreatmentsResponseItem[]>([]);
  const [treats, setTreats] = useState<DetailItemTreatment[]>([]);

  // Confirm Popup
  const [popupContents, setPopupContents] = useState<string | ReactNode>();
  const [popupHandleOk, setPopupHandleOk] = useState<VoidFunction | undefined>(() => () => {});
  const [popupHandleCancel, setPopupHandleCancel] = useState<VoidFunction | undefined>(() => () => {});
  const [isWithCancel, setIsWithCancel] = useState(false);
  const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState(false);

  // 팝업 관리
  const [isAddPopupVisible, setIsAddPopupVisible] = useState(false);
  const showAddPopup = () => setIsAddPopupVisible(true);
  const [isAddDoctorVisible, setIsAddDoctorVisible] = useState(false);
  const showAddDoctorPopup = () => {
    setEditIndex(undefined);
    setIntroduceFile(undefined);
    setIsAddDoctorVisible(true);
  };

  // 수정 시 의사 데이터 호출
  const getDoctor = useCallback(async () => {
    if (!pathname.includes('write')) {
      try {
        const data = (await api.doctor.getDoctor(id ?? '', region)).data;
        setDoctor(data);
      } catch (e) {
        navigate(-1);
      }
    }
  }, [api.doctor, id, navigate, pathname, region]);

  // 병원 데이터 호출
  const getHospital = useCallback(async () => {
    try {
      const hospitalId = id === undefined ? '' : id;
      const data = (await api.hospital.getHospital(hospitalId, region)).data;
      setHospital(data);
      setHospitalId(data.id);
      setHospitalName(data.name);
    } catch (e) {
      navigate(-1);
    }
  }, [api.hospital, doctor?.hospitalId, id, navigate, pathname, region]);

  // 병원 시술 데이터 호출
  const getHospitalsTreats = useCallback(async () => {
    const hospitalId = doctor?.hospitalId === undefined ? '' : doctor.hospitalId;
    if(hospitalId !== '') {
      const data = (await api.hospital.getHospitalsTreatments(hospitalId)).data;
      setHospitalTreats(data.treatments.map(row => ({ key: row.treatmentId, ...row })));
    }
  }, [api.hospital, doctor]);

  // 수정 시 의사 데이터 반영
  const setDoctorData = useCallback(() => {
    if (doctor) {
      setDisplay(doctor.doctorDisplay);
      setName(doctor.doctorName);
      setHospitalId(doctor.hospitalId);
      setHospitalName(doctor.hospitalName);
      setPosition(doctor.position);
      setIntro(doctor.introductionSummary);
      setRepresentative(doctor.representativeImageUrl);
      setImages(
        doctor.introductionImageUrls?.map((row, index) => ({
          key: index,
          imageUrl: row.imageUrl,
          displayOrder: index + 1,
        })),
      );
      setTreats(
        doctor.treatments?.map((row, index) => ({
          key: index,
          treatmentId: row.treatmentId,
          treatmentName: row.treatmentName,
          displayOrder: index + 1,
        })),
      );
    }
  }, [doctor]);

  const onRegionChange = useCallback((key: any) => {
      if (key === '1') {
        setRegion('ko');
      }
      if (key === '2') {
        setRegion('en');
      }
      if (key === '3') {
        setRegion('zh');
      }
      setChangedRegion(true)
    },[getHospital]);

  // 팝업 설정
  const setPopupData = (
    contents: JSX.Element,
    isWithCancel: boolean,
    handleOk?: VoidFunction,
    handleCancel?: VoidFunction,
  ) => {
    setIsWithCancel(isWithCancel);
    setPopupContents(contents);
    setPopupHandleOk(handleOk);
    setPopupHandleCancel(handleCancel);
  };

  // 소개 사진 추가
  const onSaveIntroImage = useCallback(() => {
    if (introduceFile) {
      setIsEmptyIntroduceFile(false);

      if (editIndex !== undefined) {
        const imageData = {
          ...images[editIndex],
          imageUrl: URL.createObjectURL(introduceFile),
          file: introduceFile,
        };

        setImages(prev => {
          const copy = [...prev];
          copy[editIndex] = imageData;

          return copy;
        });
      } else {
        const imageData: DetailItemImage = {
          imageUrl: URL.createObjectURL(introduceFile),
          displayOrder: images.length + 1,
          key: images.length,
          file: introduceFile,
        };

        setImages(prev => {
          return [...prev].concat(imageData);
        });
      }
      setIsAddDoctorVisible(false);
    } else {
      setIsEmptyIntroduceFile(true);
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }
  }, [editIndex, images, introduceFile]);

  // 소개 사진 삭제
  const onDeleteImage = useCallback((index: number) => {
    setImages(prev => {
      if (index > -1) {
        const copy = [...prev];
        copy.splice(index, 1);

        for (let i = 0; i < copy.length; i++) {
          copy[i].displayOrder = i + 1;
        }

        return copy;
      }

      return prev;
    });
  }, []);

  // 소개 사진 수정 버튼
  const onPressEditImage = useCallback((index: number, data: DetailItemImage) => {
    setEditIndex(index);
    onChangeIntroduceFile(data.file);
    setIsAddDoctorVisible(true);
  }, []);

  // 소개 사진 삭제 버튼
  const onPressDeleteImage = useCallback(
    (index: number) => {
      setPopupData(popupText.delete, true, () => () => onDeleteImage(index));
      setIsConfirmPopupVisible(true);
    },
    [onDeleteImage],
  );

  // 진료 시술 저장 버튼
  const onSaveTreats = useCallback(() => {
    if (selectedTreats.length > 0) {
      setIsAddPopupVisible(false);
      setTreats(
        selectedTreats.map((row, index) => ({
          key: index,
          treatmentId: row.treatmentId,
          treatmentName: row.treatmentName,
          displayOrder: index + 1,
        })),
      );
    } else {
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }
  }, [selectedTreats]);

  // 진료 시술 삭제
  const onDeleteTreats = useCallback((index: number) => {
    setTreats(prev => {
      if (index > -1) {
        const copy = [...prev];
        copy.splice(index, 1);

        return copy;
      }

      return prev;
    });
  }, []);

  // 진료 시술 삭제 버튼
  const onPressDeleteTreats = useCallback(
    (index: number) => {
      setPopupData(popupText.delete, true, () => () => onDeleteTreats(index));
      setIsConfirmPopupVisible(true);
    },
    [onDeleteTreats],
  );

  // 진료 시술 순서 변경
  const swapTreatsOrder = useCallback(
    async (target: DetailItemTreatment, index: number, direction: ShiftDirection) => {
      let swapTarget;
      let swapIndex;
      if (treats.length >= 2) {
        switch (direction) {
          case ShiftDirection.FORWARD: {
            swapIndex = index - 1;
            swapTarget = treats.at(swapIndex);
            break;
          }
          case ShiftDirection.BACKWARD: {
            swapIndex = index + 1;
            swapTarget = treats.at(swapIndex);
            break;
          }
        }

        if (swapTarget) {
          const key = swapTarget.key;
          const order = swapTarget.displayOrder;

          swapTarget.key = target.key;
          swapTarget.displayOrder = target.displayOrder;
          target.key = key;
          target.displayOrder = order;

          const copy = [...treats];
          copy[swapIndex] = swapTarget;
          copy[index] = target;
          copy.sort((a, b) => a.displayOrder - b.displayOrder);

          setTreats(copy);
        }
      }
    },
    [treats],
  );

  // 유효성 검사
  const checkValidation = useCallback((): boolean => {
    let validation = true;
    if (!name) {
      setIsEmptyName(true);
      validation = false;
    } else {
      setIsEmptyName(false);
    }

    if (!position) {
      setIsEmptyPosition(true);
      validation = false;
    } else {
      setIsEmptyPosition(false);
    }

    if (!intro) {
      setIsEmptyIntro(true);
      validation = false;
    } else {
      setIsEmptyIntro(false);
    }

    if (!representative && !representativeFile) {
      setIsEmptyRepresentativeFile(true);
      validation = false;
    } else {
      setIsEmptyRepresentativeFile(false);
    }

    if (validation) {
      setIsEmptyName(false);
      setIsEmptyPosition(false);
      setIsEmptyIntro(false);
      setIsEmptyRepresentativeFile(false);
    } else {
      setPopupData(popupText.validation, false);
      setIsConfirmPopupVisible(true);
    }

    return validation;
  }, [intro, name, position, representative, representativeFile]);

  // 취소
  const onReset = useCallback(() => {
    setPopupData(popupText.unsave, true, () => () => navigate('/contents/doctor/'));
    setIsConfirmPopupVisible(true);
  }, [navigate]);

  // 저장
  const onSubmit = useCallback(async () => {
    // 대표 사진 업로드
    let representativeFileUrl: string = representative ?? '';

    if (representativeFile) {
      const fileReader = await fileToDataUri(representativeFile);
      if (fileReader) {
        const data = (await api.file.getPresignedUrl(representativeFile.name)).data;
        await api.file.uploadFile(data.signedUrl, representativeFile);

        representativeFileUrl = data.uploadTargetUrl;
      }
    }

    // 소개 사진 목록 업로드
    const introductionImages: DoctorCreateImage[] = await Promise.all(
      images.map(async row => {
        let url = '';
        if (row.file) {
          const fileReader = await fileToDataUri(row.file);
          if (fileReader) {
            const data = (await api.file.getPresignedUrl(row.file.name)).data;
            await api.file.uploadFile(data.signedUrl, row.file);

            url = data.uploadTargetUrl;
          }
        } else {
          url = row.imageUrl;
        }

        return {
          imageUrl: url,
          displayOrder: row.displayOrder,
        };
      }),
    );

    // 진료 시술 목록 구성
    const treatments: DoctorCreateTreatment[] = treats.map(row => ({
      treatmentId: row.treatmentId,
      displayOrder: row.displayOrder,
    }));

    if (!doctor) {
      // 등록 시
      const submitData: RequestDoctorCreate = {
        show: display,
        hospitalId: hospitalId,
        hospitalName: hospitalName,
        doctorName: name,
        position: position,
        introductionSummary: intro,
        representativeImageUrl: representativeFileUrl,
        introductionImages: introductionImages,
        treatments: treatments,
      };

      const data = (await api.doctor.addDoctor(submitData)).data;
      if (data.created) {
        navigate('/contents/doctor');
      }
    } else {
      // 수정 시
      const submitData: RequestDoctorModify = {
        show: display,
        hospitalName: hospitalName,
        doctorName: name,
        position: position,
        introductionSummary: intro,
        representativeImageUrl: representativeFileUrl,
        introductionImages: introductionImages,
        treatments: treatments,
      };

      const data = (await api.doctor.modifyDoctor(doctor.doctorId, submitData, region)).data;
      if (data.modified) {
        navigate('/contents/doctor/');
      }
    }
  }, [
    api.doctor,
    api.file,
    checkValidation,
    display,
    doctor,
    images,
    intro,
    name,
    navigate,
    position,
    representative,
    representativeFile,
    treats,
  ]);

  const onClickSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if(isNotModified) {
        setPopupData(popupText.unchanged, false);
        setIsConfirmPopupVisible(true);
      } else if (checkValidation()) {
        setPopupData(popupText.save, true, () => () => onSubmit());
        setIsConfirmPopupVisible(true);
      }
    },
    [checkValidation, isNotModified, onSubmit],
  );

  // 소개 사진 칼럼
  const doctorColumns: ColumnsType<DetailItemImage> = [
    { title: '번호', dataIndex: 'displayOrder', key: 'displayOrder' },
    {
      title: '사진',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (value: any, record: DetailItemImage) => {
        if (record.imageUrl) {
          return <Image src={record.imageUrl} />;
        } else {
          return <DefaultImage />;
        }
      },
    },
    {
      title: '관리',
      dataIndex: 'edit',
      key: 'edit',
      render: (value: any, record: DetailItemImage, index: number) => (
        <InnerTdButton
          isWithEdit
          onClickEdit={() => onPressEditImage(index, record)}
          onClickDelete={() => onPressDeleteImage(index)}
        />
      ),
    },
  ];

  // 진료 시술 칼럼
  const treatColumns: ColumnsType<DetailItemTreatment> = [
    {
      title: '순서 변경',
      dataIndex: 'change',
      key: 'change',
      width: 220,
      render: (value: any, record: DetailItemTreatment, index: number) => {
        return (
          <div>
            <UpDownButton
              onClick={() => {
                if (index > 0) {
                  swapTreatsOrder(record, index, ShiftDirection.FORWARD);
                }
              }}
            />
            <UpDownButton
              onClick={() => {
                if (index < treats.length - 1) {
                  swapTreatsOrder(record, index, ShiftDirection.BACKWARD);
                }
              }}
              isDown
            />
          </div>
        );
      },
    },
    {
      title: '노출 순서',
      dataIndex: 'displayOrder',
      key: 'displayOrder',
      width: 120,
    },
    { title: '시술명', dataIndex: 'treatmentName', key: 'treatmentName' },
    {
      title: '관리',
      dataIndex: 'edit',
      key: 'edit',
      render: (value: any, record: DetailItemTreatment, index: number) => (
        <InnerTdButton onClickDelete={() => onPressDeleteTreats(index)} />
      ),
    },
  ];

  // 병원 시술 목록 칼럼
  const popupColumns = [
    { title: '시술명', dataIndex: 'treatmentName', key: 'treatmentName' },
    { title: '진료과', dataIndex: 'departmentName', key: 'departmentName' },
    { title: '시술 카테고리', dataIndex: 'treatmentCategoryName', key: 'treatmentCategoryName' },
  ];

  // 병원 시술 체크 목록
  const selectedKeys = useMemo(() => {
    const selected = hospitalsTreats.filter(row => treats.find(selected => selected.treatmentId === row.treatmentId));

    return selected.map(row => row.key);
  }, [hospitalsTreats, treats]);

  // 병원 시술 체크 박스
  const rowSelection: TableRowSelection<HospitalTreatmentsResponseItem> = {
    onChange: (keys, rows) => {
      setSelectedTreats(rows);
    },
    defaultSelectedRowKeys: selectedKeys,
  };

  // 의사 호출
  useEffect(() => {
    getDoctor().then().catch();;
  }, [getDoctor]);

  // 병원 호출
  useEffect(() => {
    if (pathname.includes('write')) {
      getHospital().then().catch();
    }
  }, [getHospital]);

  // 병원 시술 호출
  useEffect(() => {
      getHospitalsTreats().then().catch();
  }, [getHospitalsTreats]);

  // 수정 시 불러온 데이터 적용
  useEffect(() => {
    setDoctorData();
  }, [setDoctorData]);

  useEffect(() => {
    if(changedRegion) {
      return () => {
        setIsNotModified(true)
        setChangedRegion(false)
      }
    } else if(doctor !== undefined) {
      return () => {
        setIsNotModified(false)
      }
    } else {
      return () => {
        setIsNotModified(false)
      }
    }
  }, [display, name, position, intro, representative, images, treats, changedRegion]);

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>컨텐츠 관리</Breadcrumb.Item>
        <Breadcrumb.Item href="/contents/doctor">의료진 관리</Breadcrumb.Item>
        <Breadcrumb.Item href="">의료진 {pathname.includes('write') ? '추가' : '편집'}</Breadcrumb.Item>
      </Breadcrumb>
      <ContentHeading>의료진 {pathname.includes('write') ? '추가' : '편집'}</ContentHeading>
      <StyledLayout>
        <form onSubmit={onClickSubmit}>
          <StyledTabs type="card" onTabClick={key => onRegionChange(key)}>
            <TabPane tab="한국어" key="1">
              <Section>
                <ContentHeading isRequired>의료진 기본 정보</ContentHeading>
                <LabelToggle text="노출" checked={display} onChange={onChangeDisplay} />
                <LabelText text="병원명">{hospitalName}</LabelText>
                <LabelInput
                  text="의사 성명"
                  placeholder="성명을 입력해주세요."
                  value={name}
                  onChange={onChangeName}
                  isRequired
                  isRequiredEmpty={isEmptyName}
                  maxLength={200}
                />
                <LabelSelect
                  text="직위"
                  value={position === '' ? '선택' : position}
                  optionList={optionList}
                  onChange={onChangePosition}
                  isRequired
                  isRequiredEmpty={isEmptyPosition}
                />
                <LabelInput
                  text="한줄 소개"
                  placeholder="한줄 소개를 입력해주세요."
                  value={intro}
                  onChange={onChangeIntro}
                  isRequired
                  isRequiredEmpty={isEmptyIntro}
                  maxLength={200}
                />
                <ImageWrapper>
                  <Label text="대표 사진" isRequired />
                  <AddImageButton
                    url={representative}
                    onChange={onChangeRepresentativeFile}
                    isRequiredEmpty={isEmptyRepresentativeFile}
                  />
                </ImageWrapper>
              </Section>
              <Divider />
              <Section>
                <ContentHeading>의료진 소개</ContentHeading>
                <TableHeading hasButton="추가" onClick={showAddDoctorPopup}>
                  소개 사진(선택)
                </TableHeading>
                <TableDefault dataSource={images} columns={doctorColumns} />
              </Section>
              <Divider />
              <Section>
                <TableHeading hasButton="추가" onClick={showAddPopup}>
                  진료 시술(선택)
                </TableHeading>
                <TableDefault columns={treatColumns} dataSource={treats} />
              </Section>
              <Divider />
              <ButtonWrapper>
                <Button type="button" isBig onClick={() => onReset()}>
                  취소
                </Button>
                <Button type="submit" isFilled isBig>
                  저장
                </Button>
              </ButtonWrapper>
            </TabPane>
            <TabPane tab="영어" key="2">
              <DoctorEditTranslate
                display={display}
                hospitalName={hospitalName}
                defaultName={name}
                position={position}
                defaultIntro={intro}
                representative={representative}
                treats={treats}
                images={images}
                doctorColumns={doctorColumns}
                onChangeName={onChangeName}
                onChangeIntro={onChangeIntro}
                showAddDoctorPopup={showAddDoctorPopup}
              />
            </TabPane>
            <TabPane tab="중국어" key="3">
              <DoctorEditTranslate
                display={display}
                hospitalName={hospitalName}
                defaultName={name}
                position={position}
                defaultIntro={intro}
                representative={representative}
                treats={treats}
                images={images}
                doctorColumns={doctorColumns}
                onChangeName={onChangeName}
                onChangeIntro={onChangeIntro}
                showAddDoctorPopup={showAddDoctorPopup}
              />
            </TabPane>
          </StyledTabs>
        </form>
      </StyledLayout>
      {/* 11 팝업 */}
      {isAddDoctorVisible && (
        <DefaultPopup
          title={editIndex !== undefined ? '수정' : '추가'}
          onClickOk={onSaveIntroImage}
          isPopupVisible={isAddDoctorVisible}
          setIsPopupVisible={setIsAddDoctorVisible}
          okWithoutClose
          isWithCancel
          isWithSave={editIndex !== undefined ? false : true}
          isWithEdit={editIndex !== undefined ? true : false}
        >
          <ImageWrapper>
            <Label text="사진" isRequired isPopup />
            <AddImageButton
              url={editIndex !== undefined ? images[editIndex]?.imageUrl : undefined}
              onChange={onChangeIntroduceFile}
              isRequiredEmpty={isEmptyIntroduceFile}
            />
          </ImageWrapper>
        </DefaultPopup>
      )}
      {/* 14-2 팝업 */}
      {isAddPopupVisible && (
        <DefaultPopup
          title="추가"
          onClickOk={onSaveTreats}
          isPopupVisible={isAddPopupVisible}
          setIsPopupVisible={setIsAddPopupVisible}
          okWithoutClose
          isWithCancel
          isWithSave
        >
          <PopupSubHeading>진료 시술</PopupSubHeading>
          <TableSort columns={popupColumns} data={hospitalsTreats} scroll={{ y: 275 }} rowSelection={rowSelection} />
        </DefaultPopup>
      )}
      {isConfirmPopupVisible && (
        <ConfirmPopup
          isWithCancel={isWithCancel}
          handleOk={popupHandleOk}
          handleCancel={popupHandleCancel}
          isPopupVisible={isConfirmPopupVisible}
          setIsPopupVisible={setIsConfirmPopupVisible}
        >
          {popupContents}
        </ConfirmPopup>
      )}
    </>
  );
};

export default DoctorEdit;
