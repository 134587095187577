import React from 'react';
import { UpOutlined } from '@ant-design/icons';
import { StyledButton } from './styles';

interface UpDownButtonProps {
	isDown?: boolean;
	isDisabled?: boolean;
	onClick?: () => void;
}

const UpDownButton = ({ isDown, isDisabled, onClick }: UpDownButtonProps) => {
	return (
		<StyledButton type="button" isDown={isDown} className="up-down-button" onClick={onClick} isDisabled={isDisabled}>
			<UpOutlined style={{ fontSize: '16px' }} />
		</StyledButton>
	);
};

export default React.memo(UpDownButton);
