import styled from "styled-components";
import { flexbox } from "../../../styles/mixins";

export const FlexWrapper = styled.div`
	${flexbox("flex-start", "flex-start")};
	width: 79.5%;
	margin: 0 0 25px 0;

	.ant-select {
		flex-grow: 1;
	}
`;
