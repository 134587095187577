import styled from 'styled-components';
import { flexbox, fontStyle16, truncate } from '../../../styles/mixins';

export const PaymentDataWrapper = styled.div`
  ${fontStyle16}
  ${flexbox('space-between', 'center')}
  margin-bottom: 10px;

  strong {
    font-weight: 700;
  }
`;

export const TotalPayment = styled.div`
  ${flexbox('flex-start', 'center')}

  >strong {
    position: relative;
    margin-right: 20px;

    ::after {
      content: '';
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 70%;
      background-color: ${props => props.theme.primary};
    }
  }
`;

export const SubHeading = styled.h2`
  ${fontStyle16}
  font-weight: 700;
  color: ${props => props.theme.primary};
  margin-bottom: 10px;
`;

export const TotalNum = styled.div`
  color: ${props => props.theme.primary};
  > strong {
    margin: 0 5px;
  }
`;

export const TableEl = styled.p`
  ${truncate}
  width: 150px;
`;

export const ExcelDownloadButton = styled.button`
  padding: 10px 20px;
  border: 1px solid darkgreen;
  border-radius: 4px;
  background-color: #fff;
  color: darkgreen;
  margin-bottom: 5px;
  transition: all 250ms ease-in-out;

  &:hover {
    background-color: ${props => props.theme.menuItem_bg};
  }

  &:active {
    background-color: ${props => props.theme.menuItem_bg};
  }

  &:focus {
    background-color: ${props => props.theme.menuItem_bg};
  }
`;
