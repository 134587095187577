import styled from "styled-components";
import "antd/dist/antd.css";
import { Modal, ModalProps } from "antd";
import { buttonBase, flexbox, flexboxColumn, fontStyle22, scrollBase } from "../../../styles/mixins";
import { ReactNode } from "react";

interface CustomModalProps extends ModalProps {
	children: ReactNode | ReactNode[];
	isWide?: boolean;
}

export const StyledModal = styled(Modal)<CustomModalProps>`
	&.ant-modal {
		min-width: 830px !important;
		width: ${(props) => (props.isWide ? "1100px" : "830px")} !important;
		border-radius: 8px;
		background-color: ${(props) => props.theme.white};
	}

	.ant-modal-content {
		${scrollBase};

		border-radius: 8px;
		min-height: 640px;
		max-height: 875px;
		overflow-y: auto;

		.ant-modal-close {
			display: none;
		}
	}

	.ant-modal-body {
		${flexboxColumn("space-between", "")};
		min-height: 640px;
	}

	.ant-table {
		color: ${(props) => props.theme.primary};
	}

	h1 {
		${fontStyle22};
		font-weight: 500;
		margin: 0 0 30px 0;
	}

	input {
		width: 100%;
	}
`;

export const CloseButton = styled.button`
	${flexbox("center", "center")};
	position: absolute;
	top: 24px;
	right: 24px;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	background-color: #f6f6f6;

	:hover {
		background-color: #f5f5f5;
	}

	:active {
		background-color: #f1f1f1;
	}
`;

export const FlexWrapper = styled.div`
	${flexbox("center", "center")};
`;

export const Button = styled.button<{isBig?: boolean; isFilled?: boolean;}>`
	${buttonBase};
	width: 150px;
	height: 50px;
`;
