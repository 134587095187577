import React from 'react';
import { SwitchChangeEventHandler, SwitchProps } from "antd/lib/switch";
import { StyledToggle } from "./styles";

export interface ToggleButtonProps extends SwitchProps {
	onChange?: SwitchChangeEventHandler;
	style?: any;
}

const ToggleButton = (props: ToggleButtonProps) => {
	return <StyledToggle {...props} />;
};

export default React.memo(ToggleButton);
